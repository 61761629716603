import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCompassLocation = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g id="leading icon">
      <g id="icon">
        <path
          d="M13.1667 10C13.1667 11.4728 11.9728 12.6667 10.5 12.6667C9.02724 12.6667 7.83333 11.4728 7.83333 10C7.83333 8.52724 9.02724 7.33333 10.5 7.33333C11.9728 7.33333 13.1667 8.52724 13.1667 10Z"
          fill="#00789E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.61111 2H11.3889V3.84079C14.1139 4.23058 16.2694 6.38605 16.6592 9.11111H18.5V10.8889H16.6592C16.2694 13.6139 14.1139 15.7694 11.3889 16.1592V18H9.61111V16.1592C6.88605 15.7694 4.73058 13.6139 4.34079 10.8889H2.5V9.11111H4.34079C4.73058 6.38605 6.88605 4.23058 9.61111 3.84079V2ZM10.5 14.4444C12.9546 14.4444 14.9444 12.4546 14.9444 10C14.9444 7.5454 12.9546 5.55556 10.5 5.55556C8.0454 5.55556 6.05556 7.5454 6.05556 10C6.05556 12.4546 8.0454 14.4444 10.5 14.4444Z"
          fill="#00789E"
        />
      </g>
    </g>
  </svg>
);

export default SvgCompassLocation;
