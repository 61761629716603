import { Text } from '@sitecore-jss/sitecore-jss-react';
import LazyImage from 'src/components/Common/LazyImage';
import Link from 'src/components/Common/Link';
import track from 'src/lib/Analytics';
import { screens } from 'src/lib/Theme';
import { Item, TopTasksProps } from './types';

const TopTaskItem = ({ icon, link, title, preventOrphan, analytics }: Item) => {
  return (
    <li className={`p-8 md:p-12 md:w-1/4 lg:w-1/5 ${preventOrphan ? 'md:ml-px lg:ml-0' : ''}`}>
      <div className="relative flex items-center md:block px-48 md:px-12 py-12 md:py-24 h-full text-teal-darker bg-white rounded-md shadow hover:shadow-md transform transition-all duration-300 hover:-translate-y-4 md:will-change-transform">
        <LazyImage alt="" className="flex-shrink-0 -mt-2 md:mt-0 md:mx-auto icon-28" {...icon} />
        <Link
          {...link}
          className="link-block md:block ml-16 md:ml-0 md:mt-8 md:text-center"
          onClick={() => track.component(analytics)}
        >
          <Text field={title} tag="span" />
        </Link>
      </div>
    </li>
  );
};

const titleColorMap = {
  blue: 'text-blue-dark',
  gray: 'text-gray-darker',
  white: 'text-white',
};

const bgColor = (textColor: string) => {
  if (textColor === 'text-white') {
    return 'bg-teal-darker';
  }
  return 'bg-gray-lighter';
};

const TopTasks = ({
  items,
  title,
  subtitle,
  titleColor = 'white',
  gradientBgOverlay = '',
  ...props
}: TopTasksProps) => {
  const textColor = titleColorMap[titleColor] || titleColorMap.white;

  return (
    <section
      className={`${bgColor(textColor)} relative px-16 md:px-24 pt-32 md:pt-48 pb-48 md:pb-64`}
    >
      <picture>
        {props.mobileImage.value?.src && (
          <source srcSet={props.mobileImage.value.src} media={`(max-width: ${screens.md})`} />
        )}
        <LazyImage
          {...props.image}
          sizes="(min-width: 768px) 1024px, 800px"
          srcSet={[{ w: 800 }, { w: 1600 }, { w: 1024 }, { w: 2048 }]}
          alt=""
          className="absolute top-0 left-0 object-cover w-full h-full"
        />
      </picture>
      {!!gradientBgOverlay && (
        <div
          className={`absolute inset-0 bg-gradient-to-tr md:bg-gradient-to-r ${gradientBgOverlay} md:to-transparent opacity-80`}
          aria-hidden="true"
        />
      )}
      <Text
        field={title}
        className={`container-xl filter drop-shadow text-2xl text-center ${textColor}`}
        tag="h2"
      />
      {subtitle?.value && (
        <Text
          field={subtitle}
          tag="p"
          className="relative container-xs sm:container-xl text-center text-white mt-8 mb-12"
        />
      )}
      <div className="relative container-2xs md:container-3xl mt-24">
        <ul className="-m-8 md:-m-12 md:flex md:flex-wrap md:justify-center">
          {items &&
            items.map((data, index) => {
              return (
                <TopTaskItem
                  {...data}
                  key={index}
                  preventOrphan={items.length === 5 && index === 0}
                />
              );
            })}
        </ul>
      </div>
    </section>
  );
};

export { titleColorMap };
export default TopTasks;
