interface EmailBody {
  emailAddress: string;
}

interface EmailVerificationRequest {
  url: Parameters<typeof fetch>[0];
  body: EmailBody;
}

export const defaultURL = '/facade/api/Authentication/SendValidationEmail';

export const verifyEmail = async ({ url, body }: EmailVerificationRequest) => {
  const endpoint = url || defaultURL;
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      accept: 'text/plain',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const { ok } = response;

  const data = await response.json();

  if (!ok || data?.Status === 'Error') {
    // "E" is a global default returnCode
    throw new Error('E');
  }

  return data;
};
