import { Text } from '@sitecore-jss/sitecore-jss-react';
import Link from 'src/components/Common/Link';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';
import { RelatedLinksTypes } from './types';

const linkIcons: { [x: string]: string } = {
  external: 'ExternalLink',
  internal: 'Link',
  media: 'SingleDocument',
};

const RelatedLinks = ({ links, heading }: RelatedLinksTypes) => {
  const sendAttribution = ({ text = '', link = '' }: { text?: string; link?: string }) => {
    track.component({
      category: 'multi-column',
      label: heading?.value,
      action: [text, link].filter(Boolean).join('-|-'),
      event: 'event-click',
    });
  };

  return (
    <div>
      {heading && <Text className="mt-32 mb-12 text-xl text-blue" field={heading} tag="h2" />}
      <ul className="space-y-16">
        {links?.map((link, index) => {
          const icon = link.value?.linktype && linkIcons[link.value.linktype];
          return (
            <li key={index}>
              <Link
                className="flex text-blue no-underline hover:underline"
                onClick={() => sendAttribution({ text: link.value?.text, link: link.value.href })}
                {...link}
              >
                {icon && (
                  <SvgLoader
                    name={icon}
                    color="text-current"
                    className="flex-shrink-0 fill-current icon-18 mr-6 mt-2"
                    aria-hidden="true"
                  />
                )}
                <span className="flex-grow">{link.value?.text}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { linkIcons };
export default RelatedLinks;
