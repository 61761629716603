import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgClock = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M38,3.76859504 C19.1243636,3.76859504 3.76859504,19.1256198 3.76859504,38 C3.76859504,56.8743802 19.1243636,72.231405 38,72.231405 C56.8743802,72.231405 72.231405,56.8743802 72.231405,38 C72.231405,19.1256198 56.8743802,3.76859504 38,3.76859504 M38,76 C17.0466116,76 4.26325641e-14,58.9533884 4.26325641e-14,38 C4.26325641e-14,17.0466116 17.0466116,2.84217094e-14 38,2.84217094e-14 C58.9533884,2.84217094e-14 76,17.0466116 76,38 C76,58.9533884 58.9533884,76 38,76 M57.5827504,37.0467967 C57.0639405,37.0153917 47.1311802,36.4036231 41.3024198,36.1649455 C40.5487008,28.1767802 38.6681719,10.4053421 38.5689322,9.46821818 C38.5186843,8.98835041 38.1141884,8.62405289 37.6305521,8.62405289 C37.1469157,8.62405289 36.7424198,8.98835041 36.6909157,9.46821818 C36.5665521,10.6402512 33.6496595,38.2150612 33.6496595,39.9222347 C33.6496595,39.993838 33.6672463,40.0604165 33.6710149,40.1320198 C33.6735273,40.2601521 33.6622215,40.3857719 33.6772959,40.5151603 C33.912205,42.5213091 35.6105851,44.0337719 37.6267835,44.0337719 C37.7800397,44.0337719 37.9358083,44.0249785 38.0928331,44.0073917 C39.7799074,43.8076562 55.9358744,39.4021686 57.7724364,38.8996893 C58.2183868,38.7790942 58.5098248,38.3519868 58.4633455,37.8922182 C58.4156099,37.4324496 58.0437752,37.0744331 57.5827504,37.0467967"
    />
  </svg>
);

export default SvgClock;
