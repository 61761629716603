import CallToAction from 'src/components/Common/@electron/CallToAction';
import Card from './Card';
import { NavCardProps } from './types';

const NavCardsComponent = ({
  items,
  ordered,
  variant,
  ctaHasTitle = true,
}: NavCardProps & { ctaHasTitle?: boolean }) => {
  const ListTag = ordered ? 'ol' : 'ul';
  return (
    <div className="container-4xl">
      <ListTag className="sm:flex sm:flex-wrap sm:justify-center -m-12 sm:-m-16">
        {items?.map((item, index) => (
          <Card
            {...{ ordered, ...item, variant, index, ctaHasTitle }}
            length={items?.length}
            key={index}
          />
        ))}
      </ListTag>
    </div>
  );
};

const NavCards = ({
  items,
  ordered,
  variant = 'rectangular',
  ...rest
}: NavCardProps & Parameters<typeof CallToAction>[0]) => {
  const ctaHasTitle = !!rest?.title?.value;
  return (
    <CallToAction {...rest}>
      <NavCardsComponent {...{ items, ordered, variant, ctaHasTitle }} />
    </CallToAction>
  );
};

export { NavCardsComponent, NavCards as default };
