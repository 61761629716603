import Link from 'src/components/Common/Link';
import { FooterProps } from '../types';

const CopyrightAndLinks = ({
  copyright,
  items,
}: {
  copyright: FooterProps['copyright'];
  items: FooterProps['bottomLinks'];
}) => {
  return (
    <div className="lg:px-24 lg:py-24 text-center text-sm lg:text-xs">
      <div className="container-4xl lg:flex lg:flex-row-reverse lg:justify-center">
        <nav aria-label="Legal" className="lg:flex lg:justify-end lg:flex-grow py-12 lg:py-0">
          <ul className="lg:flex lg:space-x-24">
            {items.map(({ link, track }, index) => (
              <li key={index}>
                <Link
                  className="block lg:inline-block px-16 py-12 lg:p-0 no-underline hover:underline"
                  onClick={() => track()}
                  {...link}
                />
              </li>
            ))}
          </ul>
        </nav>
        <p className="px-16 py-24 lg:p-0 border-t border-teal-darker lg:border-t-0">
          {copyright.value}
        </p>
      </div>
    </div>
  );
};
export { CopyrightAndLinks };
