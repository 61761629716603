import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgNuclear = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="m46.418,47.538c-3.834-5.877-5.839-12.759-5.76-19.776-.017-4.025.616-8.026,1.874-11.849.394-1.178-.242-2.452-1.42-2.846-.229-.077-.469-.116-.71-.116h-16.759c-1.242.002-2.248,1.01-2.246,2.252,0,.241.039.481.116.71,1.261,3.82,1.897,7.818,1.883,11.84.075,7.022-1.936,13.908-5.778,19.785-.697,1.028-.428,2.427.6,3.124.363.246.79.381,1.229.387h25.106c1.242-.018,2.234-1.04,2.216-2.282-.006-.439-.141-.866-.387-1.229h.037Z"
    />
  </svg>
);

export default SvgNuclear;
