import Link from 'src/components/Common/Link';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';

const SearchButton = ({
  variant,
  href,
  text,
  guid,
  pathname,
}: {
  guid: string;
  pathname: string;
  variant?: 'small';
  href: string;
  text: string;
}) => {
  const handleClick = () => {
    track.component({
      action: pathname,
      category: 'top_level_navigation',
      label: 'Search',
      guid,
    });
  };

  if (variant === 'small') {
    return (
      <li>
        <Link
          href={href}
          onClick={handleClick}
          className="flex flex-col items-center justify-center px-12 text-xs sm:text-sm text-teal-darker"
        >
          <SvgLoader
            className="flex-shrink-0 fill-current icon-20"
            name="Search"
            size={20}
            aria-hidden="true"
            focusable="false"
          />
          <span className="block mt-2 -mb-6">{text}</span>
        </Link>
      </li>
    );
  }

  return (
    <li>
      <Link
        href={href}
        onClick={handleClick}
        className="flex items-center py-6 lg:py-8 text-sm whitespace-nowrap text-teal-darker no-underline hover:underline"
      >
        <SvgLoader
          className="flex-shrink-0 fill-current mr-6 h-16"
          name="Search"
          aria-hidden="true"
          focusable="false"
        />
        <span className="mt-2 capitalize">{text}</span>
      </Link>
    </li>
  );
};

export default SearchButton;
