import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSingleDocument = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m40.515,10.866c.041,2.529,2.082,4.57,4.611,4.611h5.395c1.024,0,2.034.237,2.951.692-.85-1.493-1.866-2.885-3.028-4.15l-7.685-8.207c-.932-.859-1.964-1.602-3.074-2.213.496.949.754,2.004.753,3.074l.077,6.194Zm14.801,11.819c0-2.547-2.063-4.611-4.61-4.612-.031,0-.062,0-.093,0h-6.148c-3.655-.008-6.616-2.969-6.624-6.624v-6.84c0-2.499-1.99-4.544-4.488-4.611H14.14c-3.013,0-5.456,2.443-5.456,5.456v53.103c.008,3.007,2.449,5.441,5.456,5.441h35.72c3.007,0,5.448-2.434,5.456-5.441V22.686Zm-38.425.123h10.037c.917,0,1.66.742,1.661,1.659,0,.917-.742,1.66-1.659,1.661h-10.053c-.917,0-1.66-.742-1.661-1.659,0-.917.742-1.66,1.659-1.661h.017Zm28.173,27.082h-28.188c-.917,0-1.66-.744-1.659-1.661,0-.916.743-1.659,1.659-1.659h28.188c.917,0,1.66.744,1.659,1.661,0,.916-.743,1.659-1.659,1.659Zm0-7.685h-28.188c-.917,0-1.66-.744-1.659-1.661,0-.916.743-1.659,1.659-1.659h28.188c.917,0,1.66.744,1.659,1.661,0,.916-.743,1.659-1.659,1.659Zm0-7.193h-28.188c-.917,0-1.66-.744-1.659-1.661,0-.916.743-1.659,1.659-1.659h28.188c.917,0,1.66.744,1.659,1.661,0,.916-.743,1.659-1.659,1.659Z"
    />
  </svg>
);

export default SvgSingleDocument;
