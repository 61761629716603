import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgIndividual = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M59.8 54.515c-.053-.013-.106-.046-.171-.064-6.277-2.186-11.132-4.185-11.776-6.583-.63-2.365 1.107-3.727 3.456-7.115.009-.007.009-.018.013-.027 3.335-4.723 5.392-11 5.392-17.326 0-12.783-8.376-20.929-18.714-20.929s-18.714 8.146-18.714 20.93c0 6.325 2.057 12.602 5.392 17.325.004.009.004.02.013.027 2.352 3.388 4.086 4.75 3.456 7.115-.644 2.398-5.499 4.397-11.776 6.583-.065.018-.114.051-.172.064C5.816 57.377.447 61.507.447 69.803v3.726h75.106v-3.726c0-8.296-5.367-12.426-15.752-15.288"
    />
  </svg>
);

export default SvgIndividual;
