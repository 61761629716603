import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPumpedStorageHydro = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M43.9,33.64c-2-4.54-6.22-12.29-11.9-23h0c-5.6,10.53-9.8,18.23-11.82,22.79-3.566,6.529-1.164,14.712,5.365,18.278,6.529,3.566,14.712,1.164,18.278-5.365,2.163-3.96,2.199-8.741,.097-12.733l-.02,.03Zm-4.67,1.13l-9.62,13.12c-.389,.392-1.023,.394-1.414,.004-.238-.236-.341-.576-.276-.904l3.3-8.89h-5.7c-.551-.033-.971-.507-.938-1.059,.01-.171,.065-.337,.158-.481l9.1-12.41c.388-.393,1.021-.397,1.414-.009,.243,.239,.347,.586,.276,.919l-3.07,8.17h6c.552,.023,.981,.488,.958,1.04-.007,.178-.062,.35-.158,.5h-.03Z"
    />
  </svg>
);

export default SvgPumpedStorageHydro;
