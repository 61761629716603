import React, { useEffect } from 'react';
import Logger from 'src/lib/Utils/Logger';
import { FormsMessage } from '../../../Common/@electron/FormsMessage';

export const ErrorComponent = ({
  error,
  message = 'Content Resolver Error',
}: {
  error: unknown;
  message?: string;
}) => {
  useEffect(() => {
    Logger(error, { message });
  }, [error]);

  if (error instanceof Error) {
    // eslint-disable-next-line prefer-destructuring
    message = error.message;
  }

  return (
    <FormsMessage
      variant="info"
      title="Error"
      description={
        <>
          <p>This functionality is currently unavailable.</p>
          {/* We may not want the message immediately accessible to the user, but it is still useful to have it represent in the document */}
          <span className={`text-xs ${process.env.NODE_ENV !== 'production' ? '' : 'sr-only'}`}>
            {message}
          </span>
        </>
      }
    />
  );
};

const createUseContent = <ContextType extends any>(Context: React.Context<ContextType>) => {
  return () => React.useContext(Context);
};

type ContentProviderProps = { children: React.ReactNode; content: any };

const createProvider = <ContextType extends any, ResolverFn extends (x: any) => any>(
  Context: React.Context<ContextType>,
  resolver: ResolverFn
) => {
  return function ContentProvider(props: ContentProviderProps) {
    let { content } = props;
    try {
      content = resolver(content);
    } catch (error) {
      return <ErrorComponent error={error} />;
    }
    return <Context.Provider value={content}>{props.children}</Context.Provider>;
  };
};

/** factory function that creates a Context.Provide and useContext hook
 * @param resolver this function will be try/caught so it is safe to throw an error
 */
export const createContent = <ResolverFn extends (x: any) => any>(resolver: ResolverFn) => {
  const Context = React.createContext({} as ReturnType<typeof resolver>);
  return {
    ContentProvider: createProvider(Context, resolver),
    useContent: createUseContent(Context),
  };
};
