interface InlineBannerProps {
  /** children: Text content to display */
  children: React.ReactNode;
  /** variant: Controls theme color and icon. Default is "info". */
  variant?: keyof typeof colorOptions;
}

const colorOptions = {
  success: 'text-green-dark',
  error: 'text-red',
  info: 'text-gray-darker',
};

export const InlineBanner = ({ children, variant = 'info' }: InlineBannerProps) => {
  const color = colorOptions[variant];

  return (
    <div className={`relative flex rounded-md overflow-hidden border border-current ${color}`}>
      <div className="px-16 py-12 bg-current">
        <svg
          className="icon-24"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 76 76"
          fill="currentColor"
          aria-hidden="true"
        >
          <circle fill="#fff" cx="38" cy="38" r="38"></circle>
          {variant === 'error' && (
            <path d="M38.0563267,51.288761 C41.4193999,51.288761 43.9076438,53.7710293 43.9076438,56.9990537 L43.9076438,56.9990537 L43.9076438,57.1329073 C43.9076438,60.3573463 41.4217901,62.8455902 38.0563267,62.8455902 C34.698034,62.8455902 32.2097901,60.3573463 32.2097901,57.1329073 L32.2097901,57.1329073 L32.2097901,56.9990537 C32.2097901,53.7710293 34.6956438,51.288761 38.0563267,51.288761 Z M41.347573,15.1947634 C43.170134,15.1947634 44.2409633,16.6779098 44.0437682,18.6223732 L44.0437682,18.6223732 L41.1527682,43.8956171 C40.9484023,45.7098122 39.7437194,46.8535439 38.0597926,46.8535439 C36.3770608,46.8535439 35.1699877,45.7098122 34.9656218,43.8956171 L34.9656218,43.8956171 L32.0770121,18.6223732 C31.8726462,16.6779098 32.948256,15.1947634 34.7696218,15.1947634 L34.7696218,15.1947634 Z"></path>
          )}
          {variant === 'info' && (
            <path d="M40.020368,30.0332241 C42.5917297,30.0332241 44.6764279,32.1179223 44.6764279,34.689284 L44.6764279,58.3439401 C44.6764279,60.914132 42.5917297,63 40.020368,63 L37.364776,63 C34.7934143,63 32.7087161,60.914132 32.7087161,58.3439401 L32.7087161,34.689284 C32.7087161,32.1179223 34.7934143,30.0332241 37.364776,30.0332241 L40.020368,30.0332241 Z M38.6812243,14 C41.9860909,14 44.6650802,16.6789892 44.6650802,19.9838559 L44.6650802,20.1242396 C44.6650802,23.4291062 41.9860909,26.1080955 38.6812243,26.1080955 C35.3763577,26.1080955 32.6973684,23.4291062 32.6973684,20.1242396 L32.6973684,19.9838559 C32.6973684,16.6789892 35.3763577,14 38.6812243,14 Z"></path>
          )}
          {variant === 'success' && (
            <path d="M62.3541673,16.2678821 C61.4167018,15.1540837 59.773905,14.9654746 58.6076532,15.8348623 C58.0529861,16.2500256 45.2152884,25.956142 28.9937864,48.0412677 L18.407122,40.0259373 C17.2910916,39.1811023 15.7163727,39.3094458 14.7543545,40.3261495 C13.7912203,41.3417372 13.744347,42.9186881 14.6483316,43.9889613 L28.7538399,60.7004006 C29.2794902,61.3242616 30.0517833,61.6758111 30.8519771,61.6758111 C31.0082213,61.6758111 31.1622335,61.6624188 31.3173618,61.635634 C32.2782639,61.4715776 33.0795738,60.8086555 33.4233111,59.8979747 C33.4980852,59.7004373 41.0926721,39.9846442 62.1421215,20.0311366 C63.1978863,19.0311733 63.2916328,17.3805644 62.3541673,16.2678821"></path>
          )}
        </svg>
      </div>
      <div
        className="rich-text px-16 py-12 bg-white text-gray-darker"
        aria-live="polite"
        aria-atomic="true"
        role="alert"
      >
        {children}
      </div>
    </div>
  );
};
