import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Minus = ({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M52.34,32.62h-15v-15a1.89,1.89,0,0,0-1.89-1.89h-1a1.89,1.89,0,0,0-1.89,1.89v15h-15a1.89,1.89,0,0,0-1.89,1.89v1a1.89,1.89,0,0,0,1.89,1.89h15v15a1.89,1.89,0,0,0,1.89,1.89h1a1.89,1.89,0,0,0,1.89-1.89v-15h15a1.89,1.89,0,0,0,1.89-1.89v-1A1.89,1.89,0,0,0,52.34,32.62Z"
      fill="currentColor"
    />
  </svg>
);

export default Minus;
