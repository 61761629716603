import Composition from 'src/lib/HOC/Composition';
import { getBackgroundColor, getModal } from 'src/lib/Utils/helpers';
import QuickLinks from './index';
import { QuickLinksTypes } from './types';

const { compositionFunction, component } = Composition(QuickLinks)(({ fields }) => ({
  backgroundColor: getBackgroundColor(fields),
  items: fields?.items?.reduce(
    (acc: Array<QuickLinksTypes>, curr: typeof fields.items) => [
      ...acc,
      {
        image: curr?.fields?.Icon,
        link: curr?.fields?.Link,
        title: curr?.fields?.Title,
        id: curr?.id,
        fields: curr?.fields,
        modal: getModal(curr?.fields),
        analytics: {
          category: 'Quick Links',
          label: curr?.fields?.Title?.value,
          action: curr?.fields?.Link?.value?.href,
          guid: curr?.id,
        },
      },
    ],
    []
  ),
}));

export { compositionFunction };
export default component;
