import React from 'react';
import SvgLoader from 'src/components/Common/SvgLoader';

type AuthTriggerButtonProps = React.PropsWithChildren<{
  onClick: () => void;
}>;

const AuthTriggerButton = ({ onClick, children }: AuthTriggerButtonProps) => {
  return (
    <button
      aria-haspopup={true}
      className="text-teal-darker"
      onClick={onClick}
      data-testid={'web-auth-button'}
    >
      <div className="bg-teal-darker text-white hover:bg-blue hover:shadow px-16 rounded-sm transform transition-colors shadow">
        <span className="flex items-center py-6 lg:py-8 text-sm whitespace-nowrap">
          <SvgLoader
            className="mr-6 h-16"
            name="Individual"
            color="text-current"
            aria-hidden="true"
            focusable="false"
          />
          <span className="mt-2 text-current">{children}</span>
        </span>
      </div>
    </button>
  );
};

export { AuthTriggerButton };
