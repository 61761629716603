import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDollar = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}

    <path
      fill="currentColor"
      d="M36.496,41.307c-.727,.533-1.576,.876-2.469,.997v-7.397c.988,.264,1.917,.71,2.743,1.317,.714,.588,1.132,1.461,1.143,2.386-.013,1.075-.539,2.077-1.417,2.697h0Zm-8.658-14.528c-.676-.496-1.076-1.283-1.079-2.121,.002-1.028,.485-1.996,1.307-2.615,.532-.439,1.157-.752,1.829-.914v6.565c-.732-.189-1.427-.498-2.057-.914h0Zm15.031,7.233c-.768-1.365-1.905-2.484-3.282-3.228-1.759-.905-3.632-1.569-5.568-1.975v-7.369c.253,.1,.501,.216,.741,.347,.831,.485,1.578,1.104,2.213,1.829,.341,.492,.991,.659,1.526,.393l3.657-1.326s1.079-.256,.119-1.344c-.414-.506-.859-.985-1.335-1.435-1.16-.962-2.478-1.717-3.894-2.231-.981-.358-2-.601-3.036-.722v-3.419c0-.409-.331-.741-.74-.741h-2.597c-.409,0-.741,.332-.741,.741v3.365c-2.239,.131-4.39,.923-6.18,2.277-1.893,1.456-2.988,3.719-2.953,6.107-.006,2.29,1.093,4.442,2.953,5.778,1.881,1.298,3.99,2.23,6.216,2.743v8.229c-.421-.127-.827-.294-1.215-.503-1.065-.646-1.997-1.49-2.743-2.486-.255-.407-.768-.567-1.208-.376l-4.151,1.435c-.389,.061-.655,.426-.593,.815,.026,.167,.111,.318,.238,.429,1.387,2.216,3.466,3.912,5.915,4.827,1.207,.477,2.475,.785,3.767,.914v3.384c0,.409,.331,.74,.741,.74h2.624c.409,0,.74-.331,.74-.74v-3.375c1.481-.145,2.929-.515,4.298-1.097,1.711-.693,3.166-1.896,4.168-3.447,1.751-2.549,1.872-5.879,.312-8.549l.009,.01Z"
    />
  </svg>
);

export default SvgDollar;
