import { useEffect, useRef, useState } from 'react';
import { PanelTypes } from '../types';

const Panel = ({ children, isActive, item, handleClick, triggerId, panelId }: PanelTypes) => {
  const [maxHeight, setMaxHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // get the height of the content inside the content placeholder div
    if (contentRef?.current) {
      setMaxHeight(contentRef.current.scrollHeight);
    }
  }, [isActive]);

  return (
    <div
      aria-hidden={!isActive}
      aria-labelledby={triggerId}
      className={`-mx-16 md:-mx-24 xl:mx-0 w-screen xl:w-full overflow-hidden transition-all ease-in-out duration-300 bg-gray-lighter ${
        item.classes.contentClass
      } ${isActive ? 'mt-16 visible' : 'invisible max-h-0'}`}
      data-testid="panel-content"
      id={panelId}
      ref={contentRef}
      role="region"
      style={isActive ? { maxHeight: `${maxHeight}px` } : {}}
    >
      {children}
      <div className="px-16 md:px-24 pb-24 md:pb-32 text-center">
        <button
          className="underline hover:no-underline text-teal-darker"
          onClick={handleClick}
          type="button"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Panel;
