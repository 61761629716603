import Composition from 'src/lib/HOC/Composition';
import NewsBanner from './index';

const { compositionFunction, component } = Composition(NewsBanner)(({ fields, rendering }) => {
  const link = fields?.['CTA URL'];

  return {
    analytics: {
      action: [link?.value?.text, link?.value?.href].filter(Boolean).join(' | '),
      category: 'news_banner',
      label: fields?.Title.value,
      guid: rendering?.uid || '',
    },
    image: fields?.['Icon Path'],
    rounded: fields?.Rounded?.value,
    title: fields?.Title,
    body: fields?.Body,
    bgColor: fields?.['BG Color']?.fields?.Value?.value,
    link: link?.value?.href && link,
  };
});

export { compositionFunction, component as default };
