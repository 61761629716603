import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPencil2 = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8922 5.84277C13.6733 5.06172 14.9396 5.06173 15.7206 5.84277L18.1956 8.31772C18.9766 9.09876 18.9766 10.3651 18.1956 11.1461L10.5915 18.7502C10.1797 19.162 9.68289 19.479 9.13585 19.6788L3.63033 21.6905C3.26488 21.824 2.85515 21.7334 2.58003 21.4583C2.30491 21.1832 2.21434 20.7735 2.34787 20.408L4.35951 14.9025C4.55939 14.3555 4.87631 13.8587 5.28814 13.4468L12.8922 5.84277Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1921 2.54289C16.5826 2.15237 17.2158 2.15237 17.6063 2.54289L21.4955 6.43205C21.886 6.82257 21.886 7.45574 21.4955 7.84626C21.1049 8.23679 20.4718 8.23679 20.0812 7.84626L16.1921 3.95711C15.8016 3.56658 15.8016 2.93342 16.1921 2.54289Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPencil2;
