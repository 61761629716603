import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgTransmissionLine = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="m47.234,1.887c.448-.162.725-.612.668-1.084C47.823.339,47.422,0,46.952,0h-29.889c-.533-.005-.969.424-.974.957-.003.408.249.774.632.914h0v2.095c-.409.236-.664.671-.668,1.144v4.145c.079.751.751,1.296,1.502,1.217.642-.067,1.15-.575,1.217-1.217v-4.1c-.005-.473-.259-.908-.668-1.144v-1.575l8.735,3.283-1.04,8.809h0l-.371,3.149h-10.503c-.47,0-.871.339-.951.802-.074.408.124.816.49,1.01v2.362c-.409.236-.664.671-.668,1.144v4.145c.079.751.751,1.296,1.502,1.217.642-.067,1.15-.575,1.217-1.217v-4.056c-.007-.464-.254-.892-.654-1.129v-1.842l8.913,3.372-1.188,10.146-6.046,29.191c-.116.52.211,1.037.732,1.153.385.086.783-.071,1.006-.395l12.731-17.827,12.731,17.827c.182.253.476.402.787.401.134-.002.265-.033.386-.089.422-.181.658-.635.565-1.084l-6.091-29.176-1.218-10.399,8.988-3.238v1.842c-.409.236-.664.671-.668,1.144v4.145c.079.751.751,1.296,1.502,1.217.642-.067,1.15-.575,1.217-1.217v-4.041c-.005-.473-.259-.908-.668-1.144v-2.377c.357-.195.553-.593.49-.995-.072-.464-.467-.808-.936-.817h-10.562l-.386-3.164h0l-1.04-8.809,8.72-3.283v1.575c-.409.236-.664.671-.668,1.144v4.145c.079.751.751,1.296,1.502,1.217.642-.067,1.15-.575,1.217-1.217v-4.204c-.005-.473-.259-.908-.668-1.144l.03-2.124Zm-19.683,15.524l3.357,5.095-4.843,7.309,1.486-12.404Zm1.486-12.939l1.812,2.644-2.57,3.744.758-6.388Zm2.971,4.368l3.996,5.823-3.996,6.076-3.996-6.076,3.996-5.823Zm3.744,2.035l-2.57-3.744,1.798-2.659.772,6.403Zm-3.744-5.467l-2.377-3.461h4.754l-2.377,3.461Zm0,18.852l6.239,9.478-6.239,8.661-6.239-8.72,6.239-9.418Zm1.159-1.768l3.298-5.081,1.486,12.404-4.783-7.324Zm3.699-18.718l-.208-1.783h4.962l-4.754,1.783Zm-14.484-1.783h4.962l-.193,1.783-4.769-1.783Zm-2.139,17.678h5.021l-.208,1.812-4.813-1.812Zm.134,39.07l4.709-22.595,5.734,8.007-10.443,14.588Zm12.835-14.588l5.719-7.992,4.709,22.58-10.429-14.588Zm5.734-22.833l-.223-1.827,5.066.089-4.843,1.738Z"
    />
  </svg>
);

export default SvgTransmissionLine;
