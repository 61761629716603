import track from 'src/lib/Analytics';

export const analyticsAccountsBracket = (count?: number) => {
  if (count && count > 11) {
    return '/11-plus-accounts';
  } else if (count && count >= 6 && count <= 10) {
    return '/6-10-accounts';
  } else {
    return '';
  }
};

export const sendVpv = (
  eventType: string,
  numberOfAccounts?: number | undefined,
  eventSubtype?: string | null
) => {
  const urlAccountsString = analyticsAccountsBracket(numberOfAccounts);
  const vpvUrl = `/vpv/de/page/account-selector/${eventType}/list-of-accounts${urlAccountsString}${
    eventSubtype ? `/${eventSubtype}` : ''
  }`;
  track({
    event: 'send-VPV',
    'vpv-name': vpvUrl,
  });
};

export const sendCAL = (category: string, action: string, label: string) => {
  track({
    'event-category': category,
    'event-action': action,
    'event-label': label,
    event: 'event-click',
  });
};
export const sendCalNoReturnCode = (category: string, action: string, label: string) => {
  track.component({
    action,
    category,
    label,
    event: 'event-click',
  });
};
