import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDukeLogo = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 126 40.4"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#005984"
      d="M47.46 2.24H43.9v16.62h3.5c5.77 0 9.36-3.22 9.36-8.4 0-4.99-3.65-8.22-9.3-8.22zm-1.11 2.27h.49c3.41 0 7.47 1 7.47 6s-4.06 6-7.47 6h-.49zM68 12c0 3.28-1.2 4.87-3.69 4.87s-3.7-1.58-3.7-4.87V2.24h-2.45v10.45c0 3.89 2.46 6.51 6.14 6.51s6.14-2.61 6.14-6.51V2.24H68zm9.4-2.26 7.5-7.51h-3.25l-6.56 6.71V2.23h-2.45v16.63h2.45V12l.57-.55 6 7.44H85l-7.6-9.15M95 16.58h-6.67v-5.69h6.49V8.61h-6.49v-4.1H95V2.23h-9.11v16.63H95v-2.28M43.9 40.06H53v-2.28h-6.65v-5.69h6.48v-2.27h-6.48v-4.11H53v-2.27h-9.1v16.62m27.81 0h9.13v-2.28h-6.68v-5.69h6.49v-2.27h-6.49v-4.11h6.68v-2.27h-9.13v16.62m0 0h9.13v-2.28h-6.68v-5.69h6.49v-2.27h-6.49v-4.11h6.68v-2.27h-9.13v16.62m-4.58-16.62v11.64L56.49 23.44H55v16.62h2.45V28.31L68 40.06h1.56V23.44h-2.43m25.13 4.83a4.67 4.67 0 0 0-2.25-4 8.82 8.82 0 0 0-4.38-.78H83v16.57h2.45v-6.85H86l4.76 6.85h3L88.54 33a4.6 4.6 0 0 0 3.72-4.73zm-2.32.09c0 1.9-1.26 2.75-4.08 2.75h-.43v-5.44h.32c2.86 0 4.19.86 4.19 2.68zM103 33.74h4.18c-.23 2.49-2.86 4.38-5.08 4.38a6.41 6.41 0 0 1-6.1-6.39 6.17 6.17 0 0 1 11.17-3.8l.15.2 1.68-1.66-.12-.16a8.65 8.65 0 1 0-7 14.09 7.72 7.72 0 0 0 7.88-7.55v-1.38H103zm17.07-10.3-3.85 6.7-3.84-6.7h-2.82l5.44 9.42v7.2h2.45v-7.2l5.44-9.42h-2.82m.87 14.21a2.53 2.53 0 1 1 2.54 2.52 2.51 2.51 0 0 1-2.53-2.49s-.01-.02-.01-.03zm.41 0a2.12 2.12 0 1 0 2.13-2.11 2.11 2.11 0 0 0-2.12 2.1zm3.28 1.44h-.47l-.72-1.31h-.53v1.3h-.41v-2.83h1.19c.51 0 1 .15 1 .75a.72.72 0 0 1-.66.78h-.09zm-1.72-2.43v.71h.44c.38 0 .91.06.91-.32s-.3-.38-.64-.38zM24.77 35.5a5.92 5.92 0 0 1-3.45 1.8c-2 .12-3.95-1.11-4.54-5.58L8.7 29.34C12.23 41.91 22 41.47 25.36 36c.23-.36.16-.54 0-.63s-.36-.09-.59.13zm0-33C21.52-4 10 2.42 8 19.2a1.77 1.77 0 0 0 .89 1.93 9.76 9.76 0 0 0 3.29 1.09 9.41 9.41 0 0 0 2.7.23 1.69 1.69 0 0 0 1.6-1.45C18.2 3 22.31-1.08 24.22 2.79c.22.45.78.21.54-.29z"
    />
    <path
      fill="#54b948"
      d="M22.78 8.18a2.09 2.09 0 0 0-2.07.91 7.88 7.88 0 0 0-.92 2.77 10 10 0 0 0-.19 3 1.26 1.26 0 0 0 1.2.91c3.68.23 14.48 1.77 16 4.91 1 2.16-2 2.71-4.22 3.28a1.8 1.8 0 0 0-1.45 1.45 4.18 4.18 0 0 0 .6 3.24 1 1 0 0 0 1.37.35l.13-.09a12.55 12.55 0 0 0 3-2.77c7.22-10.07-7.92-17.85-13.45-17.96z"
    />
    <path
      fill="#26bcd7"
      d="M26.49 24.67c-18.11 2-32.4-6.28-21.58-8.29 0 0 1.38-.32 1.64-1.1s.34-1 .64-2.15c.19-.71.36-1.11.54-1.66.29-.85-.25-1.36-1.45-.77-9.89 4.83-7 13.85 1.07 18.37A25 25 0 0 0 24.87 32a3.57 3.57 0 0 0 2.39-1.66 6.2 6.2 0 0 0 1-4.15 1.48 1.48 0 0 0-1.77-1.5z"
    />
  </svg>
);

export default SvgDukeLogo;
