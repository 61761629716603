import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCalendarCurl = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 56"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}

    <path
      fill="currentColor"
      d="m45.571,43.129c-1.489.024-2.69,1.225-2.714,2.714v3.176c0,.603-.14,1.197-.407,1.737.905-.504,1.749-1.112,2.515-1.81l4.795-4.578c.475-.512.891-1.077,1.24-1.683-.558.292-1.179.444-1.81.443h-3.619Zm-22.23-22.736c.785,0,1.42-.636,1.42-1.42,0-.003,0-.006,0-.009v-4.334c0-.785-.635-1.421-1.419-1.421-.785,0-1.421.635-1.421,1.419v4.336c-.005.784.627,1.424,1.411,1.429.003,0,.006,0,.009,0Zm17.371,0c.785,0,1.42-.636,1.42-1.42v-4.343c0-.785-.635-1.421-1.419-1.421-.785,0-1.421.635-1.421,1.419v4.336c-.005.784.627,1.424,1.411,1.429.003,0,.006,0,.009,0Zm10.287-2.334c.015-1.279-1.01-2.328-2.289-2.343-.012,0-.024,0-.036,0h-4.994v2.904c0,1.654-1.342,2.994-2.996,2.993-1.653,0-2.992-1.34-2.993-2.993v-2.904h-11.382v2.904c0,1.654-1.342,2.994-2.996,2.993-1.653,0-2.992-1.34-2.993-2.993v-2.904h-4.994c-1.279-.005-2.32,1.028-2.325,2.307,0,.012,0,.024,0,.036v30.418c0,1.279,1.037,2.316,2.316,2.316h25.478l.308-.624c.17-.346.256-.727.253-1.113v-.986h-8.143v-6.035h8.921v-8.07h5.863v7.745h1.095c.415.002.825-.084,1.203-.253l.679-.308h0l.027-23.089Zm-28.608,30.011h-6.388v-6.035h6.388v6.035Zm0-7.926h-6.388v-6.179h6.388v6.179Zm0-8.07h-6.388v-5.935h6.388v5.935Zm8.921,15.996h-7.03v-6.035h7.03v6.035Zm0-7.926h-7.03v-6.179h7.03v6.179Zm0-8.07h-7.03v-5.935h7.03v5.935Zm8.921,8.07h-7.03v-6.179h7.03v6.179Zm0-8.07h-7.03v-5.935h7.03v5.935Zm7.754,0h-5.863v-5.935h5.863v5.935Z"
    />
  </svg>
);

export default SvgCalendarCurl;
