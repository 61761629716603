interface SpinnerButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isSpinning: boolean;
  buttonText: string;
  screenReaderText?: string;
}
const SpinnerButton = ({
  isSpinning = false,
  buttonText,
  screenReaderText = '',
  className,
  ...props
}: SpinnerButtonType) => {
  return (
    <button
      className={`${isSpinning ? 'btn-progress' : ''} btn btn-primary ${className}`}
      type="submit"
      aria-disabled={isSpinning}
      aria-busy={isSpinning}
      {...props}
    >
      <span
        className={`${
          isSpinning ? 'opacity-100 scale-100' : 'opacity-0 scale-50'
        } absolute inset-0 flex justify-center items-center transition-all duration-300 delay-200`}
      >
        <svg className="icon-24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <defs>
            <linearGradient
              id="gradient"
              x1="17.664"
              y1="30.336"
              x2="28.545"
              y2="19.455"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#000"></stop>
              <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <mask id="mask" x="0" y="0" width="64" height="32" maskUnits="userSpaceOnUse">
              <rect x="0" width="64" height="32" fill="url(#gradient)"></rect>
            </mask>
          </defs>
          <g mask="url(#mask)">
            <path
              d="m60.483,32c2.093,0,3.743-1.813,3.492-3.877C62.049,12.278,48.468,0,32,0,15.533,0,1.952,12.279.025,28.123c-.251,2.064,1.399,3.877,3.492,3.877h0c1.766,0,3.259-1.306,3.472-3.048,1.513-12.387,12.133-21.985,25.011-21.985,12.877,0,23.498,9.597,25.011,21.985.213,1.742,1.706,3.048,3.472,3.048h0Z"
              opacity=".9"
            ></path>
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 32 32"
              to="360 32 32"
              dur="1.2s"
              repeatCount="indefinite"
            ></animateTransform>
          </g>
        </svg>
      </span>
      <span
        aria-hidden={!!screenReaderText || isSpinning}
        className={`${
          isSpinning ? 'opacity-0 scale-50' : 'opacity-100 scale-100'
        } relative transition-all duration-300 !text-center`}
      >
        {buttonText}
      </span>
      <span className="sr-only">{screenReaderText}</span>
    </button>
  );
};

export default SpinnerButton;
