import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCapacity = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M20.172,12.915h-7.21v7.212c0,1.05-.851,1.902-1.902,1.902s-1.902-.851-1.902-1.902v-7.212H1.946C.898,12.915,.046,12.063,.046,11.013s.851-1.902,1.9-1.902h7.213V1.902c0-1.05,.851-1.902,1.902-1.902s1.902,.851,1.902,1.902v7.21h7.21c1.05,0,1.902,.851,1.902,1.902s-.851,1.902-1.902,1.902ZM62.052,52.196c1.05,0,1.902,.851,1.902,1.902s-.851,1.902-1.902,1.902h-18.226c-1.049,0-1.9-.851-1.9-1.902s.851-1.902,1.9-1.902h18.226Zm-10.619-25.447c.434,.839,.354,1.851-.203,2.612L26.562,62.984c-.48,.659-1.233,1.016-2.002,1.016-.399,0-.804-.097-1.175-.296-1.092-.588-1.581-1.891-1.152-3.052l8.454-22.788h-14.595c-.934,0-1.792-.527-2.215-1.362-.423-.836-.34-1.839,.216-2.592L37.409,2.127c.731-1.002,2.088-1.304,3.18-.717,1.091,.588,1.581,1.895,1.147,3.055l-7.849,20.943,15.297,.002c1.028,.022,1.815,.506,2.249,1.34Z"
    />
  </svg>
);

export default SvgCapacity;
