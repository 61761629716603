import { useStep } from 'src/components/Common/Jurisdiction/context';
import { StepProps } from 'src/components/Common/Jurisdiction/types';
import Link from 'src/components/Common/Link';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';
import { Header } from './index';

const List = ({ states, id, ...rest }: StepProps) => {
  const { set } = useStep();

  return (
    <div className="container-xl flex flex-col gap-24">
      <Header {...rest} />
      <ul className="flex flex-wrap md:justify-between gap-y-32 divide-x-4 divide-transparent">
        {states.map((state, index) => (
          <li key={index} className="w-1/3 md:w-auto">
            <button
              className="flex flex-col gap-12 items-center text-center justify-center w-full text-teal-darker group"
              onClick={() => {
                track.component({
                  action: state.name,
                  category: 'jurisdiction_intercept',
                  guid: id,
                  label: rest.headline?.value,
                });

                set(Array.isArray(state.code) ? 'Map' : 'Loading', state);
              }}
            >
              <div className="bg-blue rounded-full icon-36 md:icon-76 p-6 md:p-12 group-hover:bg-teal-darker transition-colors duration-150">
                <SvgLoader
                  name={state.name.split(' ').join('')}
                  size={14}
                  color="text-white"
                  className="h-full w-full transition-transform transform group-hover:scale-95"
                  aria-hidden="true"
                  focusable={false}
                />
              </div>
              <span>
                <span className="pb-2 border-b border-white group-hover:border-current">
                  {state.name}
                </span>
                <span className="whitespace-nowrap">
                  &zwnj;
                  <SvgLoader
                    name="Arrow"
                    className="inline ml-2"
                    size={10}
                    aria-hidden="true"
                    focusable={false}
                  />
                </span>
              </span>
            </button>
          </li>
        ))}
      </ul>
      <div className="flex justify-center">
        <Link
          href="/home"
          className="text-teal-darker transition-all duration-150 border-b border-current hover:border-white"
        >
          Go to home page
        </Link>
      </div>
    </div>
  );
};

export default List;
