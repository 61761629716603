import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { useEffect, useState } from 'react';
import { useSitecoreContext } from 'src/lib/Hooks/SitecoreContextFactory';

const useExperienceEditor = () => {
  const [isEEActive, setIsEEActive] = useState(false);
  const { context: sitecoreContext } = useSitecoreContext();

  useEffect(() => {
    setIsEEActive(isEditorActive());
  }, [sitecoreContext.pageEditing]);

  return { isEEActive };
};

export { useExperienceEditor };
