import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDam = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle fill="#014464" opacity="1" cx="32" cy="32" r="32" />
    <path
      fill="#ffff"
      d="m59.439,44.622c-1.778,0-3.22-1.441-3.22-3.22h0c0-.369-.299-.668-.668-.668h-.101c-.369,0-.668.299-.668.668-.189,1.768-1.776,3.048-3.544,2.859-1.507-.161-2.697-1.352-2.859-2.859,0-.369-.299-.668-.668-.668h-.165c-.369,0-.668.299-.668.668-.189,1.768-1.776,3.048-3.544,2.859-1.507-.161-2.697-1.352-2.859-2.859,0-.369-.299-.668-.668-.668h-.101c-.369,0-.668.299-.668.668.002,1.773-1.433,3.212-3.206,3.215-.223,0-.445-.023-.663-.068l-5.488-31.299c-.177-.998-1.044-1.727-2.058-1.729h-.466c-1.035-.025-1.932.71-2.113,1.729v6.256c-.42,1.723-2.157,2.778-3.88,2.358-1.444-.352-2.457-1.649-2.45-3.136,0-.369-.299-.668-.668-.668h-.128c-.369,0-.668.299-.668.668.189,1.768-1.091,3.355-2.859,3.544-1.768.189-3.355-1.091-3.544-2.859-.024-.228-.024-.458,0-.685,0-.369-.299-.668-.668-.668h-.137c-.369,0-.668.299-.668.668-.005,1.767-1.434,3.2-3.201,3.21-1.793-.009-3.257-1.436-3.311-3.229-.302.668-.585,1.335-.851,2.031.828,1.53,2.414,2.497,4.153,2.534,1.62.001,3.119-.855,3.942-2.25,1.28,2.177,4.083,2.904,6.26,1.624.671-.394,1.23-.953,1.624-1.624.821,1.399,2.32,2.258,3.942,2.259,1.122.009,2.217-.348,3.119-1.015v27.622c-.131,1.342.85,2.537,2.193,2.668.077.008.154.011.231.012h6.558c1.155-.005,2.09-.94,2.095-2.095,0-.796-.777-4.509-.777-4.509h.402c1.62-.006,3.116-.869,3.933-2.268,1.28,2.177,4.083,2.904,6.26,1.624.671-.394,1.23-.953,1.624-1.624,1.28,2.177,4.083,2.904,6.26,1.624.671-.394,1.23-.953,1.624-1.624.827,1.41,2.344,2.271,3.979,2.259.509,0,1.016-.081,1.5-.238.338-.713.649-1.445.915-2.186-.62.681-1.494,1.075-2.415,1.088Z"
    />
  </svg>
);

export default SvgDam;
