import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCalendar = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.676 14.77a2.844 2.844 0 0 1-2.844-2.845V3.25A2.846 2.846 0 0 1 20.676.404 2.847 2.847 0 0 1 23.52 3.25v8.674a2.846 2.846 0 0 1-2.845 2.845Zm34.743 0a2.844 2.844 0 0 1-2.843-2.845V3.25a2.846 2.846 0 1 1 5.69 0v8.674a2.847 2.847 0 0 1-2.847 2.845Zm2.84 55.376v-12.07h11.739v12.07H58.26Zm-17.849 0v-12.07h14.066v12.07H40.41Zm-17.847-32v-11.88h14.066v11.88H22.563Zm0 16.147V41.93h14.066v12.364H22.563Zm0 15.853v-12.07h14.066v12.07H22.563ZM40.41 54.293V41.93h14.066v12.364H40.41Zm0-16.147v-11.88h14.066v11.88H40.41Zm17.85 0v-11.88h11.738v11.88H58.26Zm0 16.147V41.93h11.738v12.364H58.26ZM5.993 38.146v-11.88H18.78v11.88H5.993Zm0 16.147V41.93H18.78v12.364H5.993Zm0 15.853v-12.07H18.78v12.07H5.993Zm70.002.816V10.114a4.635 4.635 0 0 0-4.632-4.635H61.358v5.814a5.99 5.99 0 1 1-11.979 0V5.479H26.616v5.814a5.99 5.99 0 0 1-11.981 0V5.479H4.634A4.635 4.635 0 0 0 0 10.114v60.848a4.633 4.633 0 0 0 4.634 4.634h66.729c4.978-.072 4.632-4.634 4.632-4.634Z"
    />
  </svg>
);

export default SvgCalendar;
