import { useLocation } from 'react-router-dom';
import { useNavContext } from 'src/components/Common/NavWrapper/context';
import { Arrow } from 'src/components/Common/Svgs';
import track from 'src/lib/Analytics';
import Transition from 'src/lib/Transition';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import Link from '../../Link';
import Header from './Header';
import { displayBehavior } from './displayBehavior';
import { ItemProps, NavTreeProps, TreeItemProps, TreeProps } from './types';

const Item = ({ className, ...rest }: ItemProps) => (
  <span
    className={`relative flex text-left items-center w-full border-gray border-b py-10 mx-16 ${className}`}
    {...rest}
  />
);

const TreeItem = ({ className = '', children, hasMarker, type, ...rest }: TreeItemProps) => {
  const style = 'flex w-full text-teal-darker hover:bg-teal-light';
  const content = (
    <Item className={`justify-between ${className}`}>
      {hasMarker && (
        <span
          data-testid="marker"
          className="absolute top-0 bottom-0 left-0 w-6 bg-teal-dark -ml-16"
        />
      )}
      {children}
    </Item>
  );

  switch (type) {
    case 'div':
      return <div className="flex w-full text-gray-dark">{content}</div>;
    case 'link':
      return (
        <Link href={rest.href ?? ''} onClick={rest.onClick} className={style}>
          {content}
        </Link>
      );
    default:
      return (
        <button onClick={rest.onClick} className={style}>
          {content}
        </button>
      );
  }
};

const Tree = ({
  callback,
  className,
  items,
  route,
}: Omit<NavTreeProps, 'navAriaLabel'> & TreeProps) => {
  const {
    status: { isAuthenticated },
  } = useAppContext();

  return (
    <>
      {items.map((item, index) => {
        const isAuthRoute = item.isAuthenticated;
        const isPublicRoute = item.isPublic;

        const { hasMarker, expandedSubtree, shouldHaveArrow, type } = displayBehavior(item, route);
        const props = {
          className,
          hasMarker,
          href: item?.page?.value?.href,
          type,
        };
        const analytics = item.page?.analytics;

        if ((!isAuthenticated && isAuthRoute) || (isAuthenticated && isPublicRoute)) return null;

        return (
          <li key={index}>
            {item?.page?.value?.text && (
              <TreeItem
                {...props}
                onClick={
                  type === 'link'
                    ? () => track.component(analytics)
                    : () => callback(item.position, item.page)
                }
              >
                {item.page.value.text}
                {shouldHaveArrow && <Arrow className="inline icon-12 fill-current" />}
              </TreeItem>
            )}
            {expandedSubtree && (
              <ul className="flex flex-col">
                <Tree items={item.subpages} callback={callback} route={route} className="ml-32" />
              </ul>
            )}
          </li>
        );
      })}
    </>
  );
};

/** Smoosh all position indices into a reference to the current items.
 * e.g. position [1] gets us items[1].subpages, etc
 * (Each iteration tacks on another [curr].subpages)
 */
const getItems = (items: NavTreeProps['items'], position: number[] = []) => {
  return position.reduce((acc, curr) => {
    return acc[curr].subpages;
  }, items);
};

const NavTree = ({ items = [], navAriaLabel }: NavTreeProps) => {
  const { state, dispatch } = useNavContext();
  const { pathname: route } = useLocation();

  return (
    <Transition.Slide
      className="h-full w-full"
      from={state.direction}
      key={state.parent?.value?.href}
    >
      <nav
        aria-label={navAriaLabel}
        className="flex flex-col items-start text-teal-darker text-md pb-24 md:pb-0"
        id="hamburger-nav"
      >
        <Header />
        {state.parent && (
          <TreeItem
            hasMarker={state.parent.value?.href === route}
            href={state.parent.value?.href}
            type={state.parent.value?.href === route ? 'div' : 'link'}
            className="js-parent"
          >
            {state.parent.value?.text}
          </TreeItem>
        )}
        <ul className="w-full">
          <Tree
            items={getItems(items, state.currentItems)}
            callback={(currentItems, parent) => dispatch('next', { currentItems, parent })}
            route={route}
            className={state.parent ? 'ml-32' : ''}
          />
        </ul>
      </nav>
    </Transition.Slide>
  );
};

export default NavTree;
export { Tree, TreeItem };
