import Composition from 'src/lib/HOC/Composition';
import data from './data';
import GlobalAlerts from './index';

const { compositionFunction, component } = Composition(GlobalAlerts)<{
  fields: typeof data.fields;
}>(({ fields }) => {
  return {
    items: fields.alerts
      // Remove "JavaScript enabled" warning; this warning unwaveringly comes through.
      // The other warnings are determined by actual business logic, however, so we keep those.
      .filter(({ categoryId }) => categoryId !== 'COOKIE-JAVA')
      .map(alert => ({
        image: alert.categoryIcon,
        text: alert.alertText || '',
        title: alert.categoryName,
        priority: alert.alertPriority,
        // Not used in component but name might be useful at some point
        name: alert.name,
        id: alert.id,
      })),
  };
});

export { compositionFunction, component as default };
