import { useEffect } from 'react';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import track from 'src/lib/Analytics';
import { useSignInContent, useSignInMachine } from '.';
import { ButtonGroup } from '../../Common/@electron/ButtonGroup';
import { FormsMessage } from '../../Common/@electron/FormsMessage';
import SpinnerButton from '../../Common/@electron/SpinnerButton';
import { MediumContainer, PageSpacing, SmallContainer } from '../_components/layout';
import { useErrorAnalytics } from './_lib/useErrorAnalytics';
import {
  RegisterButton,
  SignInForgotButtonGroup,
  StandaloneSignInButtonGroup,
} from './ButtonGroups';
import { Error } from './Error';
import ForgotUsernamePasswordLinks from './ForgottenLinks';
import { SignInInputs } from './Inputs';
import { OrDivider } from './Register';

/** Conditionally renders "Disabled" message INSTEAD of children if content specifies the functionality this component wraps should be "toggled" off */
export const ToggleContainer = ({ children }: React.PropsWithChildren<{}>) => {
  const { content } = useSignInContent();
  const { maintenanceWindowFallsWithinTimeSpan, disableOnMaintenance, variant, loginDownMessages } =
    content;

  const isDrawerVariant = variant === 'Drawer';

  const isDisabled = isDrawerVariant
    ? maintenanceWindowFallsWithinTimeSpan
    : maintenanceWindowFallsWithinTimeSpan && disableOnMaintenance;

  const { pathname } = useLocation();

  const sendLogonBoxErrorAnalyticEvents = () => {
    let eventAction = '';
    switch (loginDownMessages.Title.value) {
      case "We're updating our website.":
        eventAction = 'logon-multi-day-outage';
        break;

      case 'Website is undergoing maintenance.':
        eventAction = 'logon-maintenance-short-outage';
        break;

      case 'Sign in is temporarily unavailable.':
        eventAction =
          loginDownMessages.Description.value ===
          'Access to your account is currently unavailable due to technical issues. Please try again later. We apologize for any inconvenience.'
            ? 'logon-major-incident'
            : 'logon-maintenance-multi-day';
        break;

      default:
        eventAction = 'na';
        break;
    }

    // VPV
    track({
      event: 'send-VPV',
      'vpv-name': `/vpv/de/load/site-error/error/${eventAction}/na/na`,
    });

    // CAL
    track({
      'event-category': 'site-error',
      'event-action': `na-|-${eventAction}-|-na`,
      'event-label': `/${pathname ? pathname.split('/').pop() : ''}`,
      event: 'event-click',
    });
  };

  useEffect(() => {
    isDisabled && sendLogonBoxErrorAnalyticEvents();
  }, [isDisabled]);

  if (isDisabled) {
    return (
      <SmallContainer>
        <PageSpacing>
          <FormsMessage
            variant={loginDownMessages.Variant.value}
            title={loginDownMessages.Title.value}
            description={loginDownMessages.Description.value}
          >
            {loginDownMessages['Button CTA Link'].value.href &&
              loginDownMessages['Button CTA Title'].value && (
                <Link
                  className="btn btn-primary"
                  to={loginDownMessages['Button CTA Link'].value.href}
                >
                  {loginDownMessages['Button CTA Title'].value}
                </Link>
              )}
          </FormsMessage>
        </PageSpacing>
      </SmallContainer>
    );
  }

  return <>{children}</>;
};

interface SignInFormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  variant: string;
}

const SignInForm = ({ variant, onSubmit, children }: React.PropsWithChildren<SignInFormProps>) => {
  const { content } = useSignInContent();
  const { headline } = content;

  if (variant === 'Split Sign In') {
    return (
      <div className="w-full container-sm my-48">
        {!!headline && <h3 className="sm:text-center text-xl my-16 sm:my-24">{headline}</h3>}
        <form className="flex flex-col gap-24 mb-24" onSubmit={onSubmit} noValidate>
          {children}
          <SignInForgotButtonGroup />
        </form>
        <OrDivider />
        <RegisterButton />
      </div>
    );
  }

  if (variant === 'Single Sign In') {
    // Standalone Sign In
    return (
      <div className="w-full container-sm mb-48">
        {!!headline && <h3 className="sm:text-center text-xl my-16 sm:my-24">{headline}</h3>}
        <form className="flex flex-col gap-24 mb-24" onSubmit={onSubmit} noValidate>
          {children}
          <StandaloneSignInButtonGroup />
        </form>
        <div className="border-t border-gray text-center pt-24">
          <ForgotUsernamePasswordLinks />
        </div>
      </div>
    );
  }

  return (
    <div>
      <form className="flex flex-col gap-24" onSubmit={onSubmit} noValidate>
        {children}
        <SignInForgotButtonGroup />
      </form>
      <OrDivider />
      <RegisterButton />
    </div>
  );
};

export type FormHooks = UseFormReturn<FormInputs, any>;

type FormInputs = {
  usernameInput: string;
  passwordInput: string;
};

const EmailVerificationForm = () => {
  const { getError } = useSignInContent();
  const { state, send } = useSignInMachine();

  const error = getError(state.context.errorCode);

  if (!error) {
    return null;
  }
  return (
    <MediumContainer>
      <div role="alert" aria-live="polite" aria-atomic="true">
        <FormsMessage
          variant={error?.Variant?.value}
          title={error?.Title?.value}
          description={error?.Message?.value}
        >
          <form
            className="flex flex-col gap-24 mb-24"
            noValidate
            onSubmit={e => {
              e.preventDefault();
              send({
                type: 'SENDVERIFICATION',
                data: {
                  endpoint: error.Endpoint?.fields?.Phrase?.value,
                },
              });
            }}
          >
            <ButtonGroup
              primary={
                <SpinnerButton
                  aria-busy={state.matches('pendingVerify')}
                  buttonText={error?.['Primary CTA Label']?.value}
                  screenReaderText={
                    state.matches('pendingVerify')
                      ? 'Processing'
                      : error?.['Primary CTA Label']?.value
                  }
                  className="min-w-max"
                  isSpinning={state.matches('pendingVerify')}
                  type="submit"
                />
              }
            />
          </form>
        </FormsMessage>
      </div>
    </MediumContainer>
  );
};

export const SignInRenderer = () => {
  const { content, getError } = useSignInContent();
  const { state, send } = useSignInMachine();

  const error = getError(state.context.errorCode);

  const formHooks = useForm<FormInputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useErrorAnalytics(state.context.errorCode);

  const { handleSubmit, getValues } = formHooks;
  const { variant } = content;

  const xstateSubmit: SubmitHandler<FormInputs> = () => {
    const loginIdentity = getValues('usernameInput')?.trim();
    const password = getValues('passwordInput');

    send({
      type: 'SIGNIN',
      data: { password, loginIdentity },
    });
  };

  if (state.hasTag('verifyForm')) {
    return <EmailVerificationForm />;
  }

  if (state.matches('errorPage')) {
    const errorProp = error || getError('E');

    if (errorProp) {
      return <Error error={errorProp} variant={variant} />;
    }
  }

  return (
    <SignInForm variant={variant} onSubmit={handleSubmit(xstateSubmit)}>
      <SignInInputs formHooks={formHooks} id={variant.split(' ').join('-')} />
    </SignInForm>
  );
};
