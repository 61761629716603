// the tailwind classes for the pdp item components
// 'fourPanels' is for instances of exactly four children
// 'otherPanels' are for all other scenarios with a max of 6 children
const styles = {
  fourPanels: {
    button: ['', 'border-l border-gray', '', 'border-l border-gray'],
    content: ['order-2', 'order-2', 'order-4', 'order-4'],
    items: ['order-1 sm:ml-px', 'order-1', 'order-3', 'order-3'],
  },
  otherPanels: {
    button: [
      '',
      'border-l border-gray',
      'sm:border-l sm:border-gray',
      'border-l sm:border-l-0',
      'sm:border-l sm:border-gray',
      'border-l border-gray',
    ],
    content: [
      'order-2',
      'order-2',
      'order-4 sm:order-2',
      'order-4',
      'order-6 sm:order-4',
      'order-6 sm:order-4',
    ],
    items: [
      'order-1',
      'order-1',
      'order-3 sm:order-1',
      'order-3',
      'order-5 sm:order-3',
      'order-5 sm:order-3',
    ],
  },
};

export { styles };
