import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgHamburger = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 14"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19 12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2ZM19 6a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2ZM19 0a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2Z" />
  </svg>
);

export default SvgHamburger;
