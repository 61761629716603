import React, { useReducer, useRef } from 'react';
import Link from 'src/components/Common/Link';
import SvgLoader from 'src/components/Common/SvgLoader';
import useMediaQuery from 'src/lib/Hooks/useMediaQuery';
import { toKebabCase } from 'src/lib/Utils/helpers';
import { assertElement } from 'src/lib/Utils/helpers/assert';
import { FooterProps } from '../types';

const useHeightTransition = (ref: React.RefObject<HTMLElement>) => {
  const [isOpen, toggleOpen] = useReducer(open => !open, false);
  const toggle = () => {
    toggleOpen();
    assertElement(ref.current);
    if (isOpen) {
      // We already have 'max-h-0' in the className, so we just clear out maxHeight - this way we don't accidentally
      // maintain the `maxHeight = 0` declaration when we switch to a screen size without accordion functionality.
      ref.current.style.maxHeight = '';
    } else {
      ref.current.style.maxHeight = `${ref.current.scrollHeight}px`;
    }
  };

  return [isOpen, toggle] as const;
};

const Column = ({ items }: { items: NonNullable<FooterProps['columns']>[number] }) => {
  const isLg = useMediaQuery('lg');
  const ref = useRef(null);
  const [isOpen, toggle] = useHeightTransition(ref);

  const [headline, ...rest] = items;

  const kebabHeadline = toKebabCase(headline.link.value.text);
  const titleId = `footer-${kebabHeadline}-title`;
  const linksId = `footer-${kebabHeadline}-links`;

  // Chris wanted to remove this title attribute
  delete headline.link.value.title;

  return (
    <div className="lg:w-1/5 lg:p-12">
      <h2>
        <Link
          className="hidden lg:inline-block text-md font-bold no-underline hover:underline"
          onClick={headline.track}
          {...headline.link}
        />
        <button
          aria-expanded={isOpen}
          aria-controls={linksId}
          className="block lg:hidden w-full px-16 py-12 text-lg text-left"
          id={titleId}
          onClick={toggle}
          type="button"
        >
          <span className="container-sm flex items-center justify-between">
            {headline.link.value.text}
            <SvgLoader
              className={`fill-current ml-16 w-16 flex-shrink-0 transform duration-200 ${
                isOpen ? 'scale-x-flip' : ''
              } rotate-90`}
              color="text-current"
              name="Arrow"
            />
          </span>
        </button>
      </h2>
      <ul
        aria-labelledby={titleId}
        aria-hidden={isLg ? false : !isOpen}
        className={`lg:visible mt-0 lg:mt-12 lg:space-y-4 divide-y lg:divide-none divide-gray max-h-0 lg:max-h-none overflow-hidden lg:overflow-visible text-md lg:text-sm bg-white lg:bg-transparent text-teal-darker lg:text-current transition-all lg:transition-none duration-300 ${
          isOpen ? 'visible' : 'invisible'
        } `}
        id={linksId}
        ref={ref}
      >
        {rest.map(({ link, track }, index) => {
          // Chris G pointed out that there are empty li tags in the footer here, looks like missing hrefs in content
          if (link.value.href && link.value.text) {
            return (
              <li key={index}>
                <Link
                  className="block lg:inline-block px-16 lg:px-0 py-12 lg:py-2 no-underline hover:underline"
                  onClick={track}
                  {...link}
                >
                  <span className="block container-sm">{link.value.text}</span>
                </Link>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};

const SiteMap = ({
  columns,
  flatColumn,
}: {
  columns: FooterProps['columns'];
  flatColumn: FooterProps['flatColumn'];
}) => {
  return (
    <div className="lg:px-24 lg:py-32 border-b border-teal-darker" data-testid="SiteMap">
      <div className="container-4xl">
        <nav
          className="lg:flex lg:justify-center lg:flex-wrap lg:-m-12 divide-y lg:divide-none divide-teal-darker"
          aria-label="Site"
        >
          {columns?.map((column, index) => (
            <Column key={index} items={column} />
          ))}
          <div className="lg:w-1/5 lg:p-12">
            <ul className="lg:space-y-12 divide-y lg:divide-none divide-teal-darker">
              {flatColumn?.map(({ link, track }, index) => (
                <li key={index}>
                  <Link
                    className="block lg:inline-block px-16 py-12 lg:p-0 text-lg lg:text-md lg:font-bold no-underline hover:underline"
                    onClick={track}
                    {...link}
                  >
                    <span className="container-sm flex items-center justify-between w-full">
                      <span className="flex-grow">{link.value?.text}</span>
                      <SvgLoader
                        name="Arrow"
                        color="text-current"
                        className="flex-shrink-0 fill-current ml-24 h-16 lg:hidden"
                      />
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export { SiteMap };
