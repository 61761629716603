import React from 'react';
import { AutocompleteString } from 'src/types/util';
import Link from '../../Link';
import { SitecoreFormsMessageType } from './data';

/* eslint-disable complexity */
const colors = {
  success: { text: 'text-green-dark', icon: 'text-green-dark' },
  error: { text: 'text-red', icon: 'text-red' },
  warning: { text: 'text-gray-darker', icon: 'text-yellow-dark' },
  info: { text: 'text-gray-darker', icon: 'text-gray-darker' },
  failure: { text: 'text-gray-darker', icon: 'text-gray-darker' },
  critical: { text: 'text-gray-darker', icon: 'text-blue-dark' },
  maintenance: { text: 'text-gray-darker', icon: 'text-gray-darker' },
};

interface FormsMessageProps {
  /** title: The primary headline text */
  title?: string;
  /** description: Secondary supporting text */
  description?: string | JSX.Element;
  /** variant: Controls theme color and icon */
  variant: AutocompleteString<keyof typeof colors>;
  /** < ButtonGroup /> or other content to include */
  children?: React.ReactNode;
  /** size: compact (small) or full (default) */
  size?: 'compact' | 'full';
}

export const FormsMessage = ({
  children,
  description,
  title,
  variant = 'info',
  size = 'full',
}: FormsMessageProps) => {
  let descriptionElement: JSX.Element | undefined;
  const isCompact = size === 'compact';

  if (typeof description === 'string') {
    descriptionElement = (
      <p
        className={`rich-text text-md ${isCompact ? 'text-pretty mt-6' : 'md:text-lg mt-12'}`}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    );
  }

  if (React.isValidElement(description)) {
    descriptionElement = (
      <div className={`rich-text text-md ${isCompact ? 'text-pretty mt-6' : 'md:text-lg mt-12'}`}>
        {description}
      </div>
    );
  }

  if (!(variant in colors)) {
    variant = 'info';
  }

  const colorVariant = colors[variant as keyof typeof colors];

  return (
    <div
      role="alert"
      aria-live="polite"
      className={`container-xl ${
        isCompact ? '' : 'p-24 md:p-32 border border-gray-light rounded-md w-full'
      }`}
    >
      <div className="container-md text-center">
        <svg
          className={`icon-32 ${isCompact ? '' : 'md:icon-48'} mx-auto ${colorVariant.icon}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          fill="currentcolor"
          aria-hidden="true"
        >
          {variant === 'success' && (
            <path d="M32,0C14.327,0,0,14.327,0,32s14.327,32,32,32,32-14.327,32-32S49.673,0,32,0Zm20.33,16.868c-17.725,16.803-24.12,33.405-24.184,33.574-.289,.768-.964,1.325-1.773,1.464-.129,.022-.26,.033-.392,.034-.682,0-1.329-.301-1.768-.822l-11.877-14.074c-.824-.977-.7-2.437,.276-3.261,.827-.698,2.027-.729,2.889-.076l8.915,6.749c13.66-18.598,24.471-26.771,24.938-27.121,1.018-.772,2.47-.573,3.242,.445,.718,.946,.603,2.282-.266,3.092v-.003Z"></path>
          )}
          {variant === 'error' && (
            <path d="M32,0C14.327,0,0,14.327,0,32s14.327,32,32,32,32-14.327,32-32S49.673,0,32,0Zm4.312,45.291h0c0,2.38-1.929,4.308-4.308,4.308-2.379,0-4.307-1.929-4.307-4.308v-.301c.08-2.296,2.005-4.092,4.301-4.013,.068-.003,.136-.003,.208,.003,2.296,.03,4.134,1.914,4.106,4.211v.101Zm.099-28.365l-2.13,18.602c-.07,1.151-.988,2.069-2.139,2.139-1.258,.077-2.34-.881-2.416-2.139l-2.131-18.602c-.152-1.432,.64-2.526,1.979-2.526h4.842c1.347,0,2.131,1.095,1.996,2.526Z"></path>
          )}
          {variant === 'warning' && (
            <path d="M63.259,52.175h0S36.796,6.294,36.796,6.294c-1.528-2.648-4.912-3.558-7.561-2.03-.842,.487-1.542,1.187-2.03,2.03L.742,52.174c-1.528,2.646-.622,6.033,2.026,7.561,.842,.485,1.797,.742,2.769,.742H58.463c3.056,.002,5.535-2.476,5.537-5.534,0-.973-.256-1.927-.741-2.769Zm-26.034,0c-.156,2.882-2.616,5.093-5.496,4.939-2.667-.142-4.797-2.272-4.939-4.939v-.119c.152-2.882,2.614-5.093,5.496-4.941,2.665,.143,4.795,2.274,4.939,4.941v.119Zm.119-34.351h0s0-.005,0-.007v-.008l-2.59,22.556c-.113,1.524-1.438,2.669-2.963,2.557-1.369-.101-2.458-1.188-2.557-2.557l-2.575-22.541c-.32-1.336,.504-2.677,1.84-2.997,.179-.041,.36-.065,.543-.067h5.92c1.372,.019,2.47,1.149,2.451,2.522-.002,.18-.027,.359-.067,.535v.007Z"></path>
          )}
          {variant === 'info' && (
            <path d="M32,0C14.327,0,0,14.327,0,32s14.327,32,32,32,32-14.327,32-32S49.673,0,32,0Zm-4.681,17.472c0-2.583,2.094-4.677,4.677-4.677s4.677,2.094,4.677,4.677v.11c0,2.583-2.094,4.677-4.677,4.677s-4.677-2.094-4.677-4.677v-.11Zm9.362,29.979c0,2.01-1.629,3.639-3.639,3.639h-2.075c-2.01,0-3.639-1.629-3.639-3.639V28.965c0-2.01,1.629-3.639,3.639-3.639h2.075c2.01,0,3.639,1.629,3.639,3.639v18.487Z"></path>
          )}
          {variant === 'failure' && (
            <path d="M32,0C14.327,0,0,14.327,0,32s14.327,32,32,32,32-14.327,32-32S49.673,0,32,0Zm14.554,45.852l-.731,.731c-.771,.769-2.018,.769-2.789,0l-11.063-11.063-11.063,11.063c-.771,.769-2.018,.769-2.789,0l-.731-.731c-.769-.771-.769-2.018,0-2.789l11.063-11.063-11.063-11.063c-.769-.771-.769-2.018,0-2.789l.731-.731c.771-.769,2.018-.769,2.789,0l11.063,11.063,11.063-11.063c.771-.769,2.018-.769,2.789,0l.731,.731c.769,.771,.769,2.018,0,2.789l-11.063,11.063,11.063,11.063c.041-.013,.086,0,.111,.035,.008,.009,.016,.017,.024,.026,.716,.791,.655,2.012-.135,2.727Z"></path>
          )}
          {variant === 'critical' && (
            <path d="M32 0C14.327 0 0 14.327 0 32s14.327 32 32 32 32-14.327 32-32S49.673 0 32 0m11.157 29.753L28.016 50.462a1.2 1.2 0 1 1-2.121-1.125l5.367-14.482h-9.463a1.197 1.197 0 0 1-.96-1.902l14.345-19.575a1.198 1.198 0 0 1 2.084 1.124l-4.992 13.349h9.902c.451.005.863.253 1.079.649.208.403.169.888-.101 1.253Z"></path>
          )}
          {variant === 'maintenance' && (
            <path d="M32 0C14.327 0 0 14.327 0 32s14.327 32 32 32 32-14.327 32-32S49.673 0 32 0ZM19.064 50.825c-.905.889-2.332-.147-3.245-.795l-1.509-1.107c-.896-.649-1.92-1.737-1.005-2.652l12.644-11.666 4.023 4.791-10.907 11.428Zm26.807-4.051-2.852-2.386a1.358 1.358 0 0 0-1.07-.147s-2.651.548-3.236 2.304c0 0-.421.713.631 1.38l3.063 2.377s.732.54 0 .723c-.475.119-8.786 1.011-7.771-6.528a3.357 3.357 0 0 0-.741-2.743L21.012 26.787a8.138 8.138 0 0 0-3.063-.448s-4.983-.049-5.671-5.129c0 0-.137-1.709 1.299-.842l3.016 2.19c.334.173.725.2 1.08.073 0 0 2.605-.741 3.063-2.526 0 0 .375-.74-.732-1.335l-3.209-2.157s-.768-.494-.084-.713c.466-.156 8.695-1.637 8.228 5.961a3.358 3.358 0 0 0 .92 2.746l13.065 15.264a8.166 8.166 0 0 0 3.016.668s4.968.403 5.294 5.485c0 0 0 1.719-1.363.75Zm3.186-12.196c.393-6.455-4.516-7.872-4.516-7.872a9.764 9.764 0 0 1-4.389.74l-2.655 2.542c.475 1.472-2.021 3.556-2.021 3.556l-3.904-4.379a5.21 5.21 0 0 1 3.159-1.555l2.88-2.679a4.693 4.693 0 0 1-.055-2.569c.256-1.719-1.828-2.313-1.828-2.313-1.171.685-1.829.128-1.829.128l-2.067-1.516s-.768-.997 1.701-3.904c2.176-2.569 3.529-1.829 3.529-1.829s1.389.988 2.377 1.719c.053.037.102.078.148.122l.003.003c.431.424.437 1.117.013 1.548a4.138 4.138 0 0 0 2.084 1.628s1.801-.878 6.456 3.958c7.488 7.788.914 12.672.914 12.672Z"></path>
          )}
        </svg>
        {title && (
          <h2
            className={`text-2xl ${isCompact ? 'sm:text-xl-fixed' : ''} mt-12 ${colorVariant.text}`}
          >
            {title}
          </h2>
        )}
        {description && descriptionElement}
        {children && <div className={`mt-16 ${isCompact ? '' : 'md:mt-24'}`}>{children}</div>}
      </div>
    </div>
  );
};

const resolver = (fields: SitecoreFormsMessageType['fields']) => {
  const buttonLink = {
    ...fields?.ButtonLink?.value,
    text: fields?.ButtonText?.value || fields?.ButtonLink?.value?.text,
  };
  return {
    title: fields?.Headline?.value || '',
    description: fields?.Message?.value || '',
    variant: fields?.Variant?.value,
    buttonLink,
  };
};

const SitecoreFormsMessage = (props: SitecoreFormsMessageType) => {
  // don't show FormsMessage if "Disabled" value is not defined (not a boolean) or if "Disabled" is true
  if (typeof props.fields?.Disabled?.value !== 'boolean' || props.fields?.Disabled?.value) {
    return null;
  }

  const { buttonLink, ...formsMessageProps } = resolver(props.fields);
  const hasButton = buttonLink.text && buttonLink.href;

  return (
    <div className="py-48 px-16">
      <FormsMessage {...formsMessageProps}>
        {hasButton && <Link value={buttonLink} className="btn btn-primary" />}
      </FormsMessage>
    </div>
  );
};

export default SitecoreFormsMessage;
