import { BackButton, Header } from 'src/components/Common/Jurisdiction';
import { useStep } from 'src/components/Common/Jurisdiction/context';
import { StepProps } from 'src/components/Common/Jurisdiction/types';
import ZipForm from '../Form';

const Zip = ({ headline, id, backToStatesText, ...rest }: StepProps) => {
  const { set } = useStep();

  return (
    <>
      <BackButton onClick={() => set('List')}>{backToStatesText}</BackButton>
      <Header {...{ headline, ...rest }} />
      <div className="mt-16 w-full">
        <ZipForm headline={headline} id={id} sidebar={true} />
      </div>
    </>
  );
};

export default Zip;
