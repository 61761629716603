import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import RichText from 'src/components/Common/RichText_Old';
import SvgLoader from 'src/components/Common/SvgLoader';
import Transition from 'src/lib/Transition';
import { ErrorView, List, Loading, Map, Zip } from './Steps';
import { StepProvider, useStep } from './context';
import { JurisdictionProps, ProviderProps, StepProps } from './types';

const Item = ({ className = '', ...rest }: React.PropsWithChildren<{ className?: string }>) => (
  <span
    className={`relative flex items-center w-full border-gray border-b py-10 mx-16 ${className}`}
    {...rest}
  />
);

const BackButton = ({
  onClick,
  children = 'Back',
  className,
}: React.PropsWithChildren<{ onClick: () => void; className?: string }>) => (
  <div className={`px-16 pb-16 font-bold text-left capitalize ${className}`}>
    <button
      onClick={onClick}
      className="flex items-center text-teal-darker"
      data-testid="back-button"
    >
      <SvgLoader name="Arrow" className="inline mr-8" size={12} rotate={180} />
      <span className="border-b border-white hover:border-current">{children}</span>
    </button>
  </div>
);

const headlineId = 'jurisdiction-selector-headline';
const Header = ({ headline, subhead, body }: Omit<StepProps, 'id' | 'states'>) => {
  const { whichState } = useStep();

  return (
    <div className="mx-16 text-teal-darker">
      {headline && (
        <Text
          className="text-xl text-current"
          field={{
            ...headline,
            value: headline.value?.replace(/\W[{0}]\W/, whichState?.name || ''),
          }}
          tag="h2"
          id={headlineId}
        />
      )}
      {subhead && <Text className="pt-16 text-gray-dark" field={subhead} tag="h3" />}
      {body && <RichText className="pt-16 text-gray-darker" tag="p" field={body} />}
    </div>
  );
};

const compMap = {
  // TODO Intro is an extra step, anticipate it can be used for JurisdictionIntercept
  Intro: List,
  Map,
  Zip,
  Loading,
  Error: ErrorView,
  List,
};

const assert: { <T>(value: T | null | undefined, message: string): asserts value is T } = (
  value: unknown,
  message
) => {
  if (!value) {
    throw new Error(message);
  }
};

const Main = ({
  initialDirection = 0,
  states,
  steps,
  useZipText,
  backToStatesText,
}: JurisdictionProps & { useZipText?: string }) => {
  const { direction, whichStep } = useStep();
  const StepComponent = compMap[whichStep];
  const selectedStep = steps.find(({ name }) => name === whichStep);

  assert(selectedStep, `selectedStep, ${whichStep}, was not found in props`);

  return (
    <Transition.Slide from={direction || initialDirection} key={whichStep} data-testid={whichStep}>
      <div className="py-12">
        <StepComponent
          states={states}
          {...selectedStep}
          useZipText={useZipText}
          backToStatesText={backToStatesText}
        >
          {whichStep === 'Error' && (
            <Map {...(steps.find(({ name }) => name === 'Map') as StepProps)} hasZip={false} />
          )}
        </StepComponent>
      </div>
    </Transition.Slide>
  );
};

const Jurisdiction = ({ initialStep, ...rest }: JurisdictionProps & ProviderProps) => (
  <StepProvider {...{ initialStep }}>
    <Main {...rest} />
  </StepProvider>
);

export default Jurisdiction;
export { BackButton, Header, Item, assert, headlineId };
