import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgLocation = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M38.414 31.768c-5.973.001-10.815-4.84-10.816-10.814-.001-5.972 4.84-10.815 10.813-10.816 5.974 0 10.815 4.842 10.815 10.814v.002c0 5.973-4.841 10.814-10.814 10.814h.002Zm0-31.768C26.586.001 16.999 9.589 17 21.416c0 4.695 1.544 9.26 4.393 12.992 0 0 13.721 20.963 15.235 38.916 0 0 .081 2.676 1.784 2.676 1.704 0 1.785-2.676 1.785-2.676 1.515-17.953 15.238-38.916 15.238-38.916 7.177-9.4 5.372-22.839-4.029-30.015A21.412 21.412 0 0 0 38.414 0Z"
    />
  </svg>
);

export default SvgLocation;
