import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const End = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="19px"
    height="18px"
    viewBox="0 0 76 76"
    version="1.1"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <defs>
      <path
        d="M60.6380494,0 L58.1483933,0 C55.5240899,0 53.4014138,2.13587675 53.4014138,4.76810063 L53.4014138,34.5515686 L14.4117626,0.675876944 C13.5616361,-0.118806494 12.4448551,-0.219131978 11.5208046,0.414502657 C11.2673507,0.588752182 11.0376582,0.810524304 10.8396473,1.06925845 C10.3142586,1.75041568 10.0080019,2.6929472 10,3.6935619 L10,72.244909 C9.99216102,73.6283446 10.5624322,74.9035343 11.4785623,75.5582901 C12.3973325,76.2104058 13.5114734,76.1364817 14.3748006,75.3629195 L53.4014138,41.4898679 L53.4014138,71.2337337 C53.4014138,73.8659576 55.5240899,75.9991942 58.1483933,75.9991942 L60.6380494,75.9991942 C63.2597127,75.9991942 65.3850289,73.8659576 65.3850289,71.2337337 L65.3850289,4.76810063 C65.3850289,2.13587675 63.2597127,0 60.6380494,0"
        id="path-1"
      ></path>
    </defs>
    <g
      id="Icons/base/actions-for-electron/skip-forward"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group">
        <mask id="mask-2" fill="white">
          <use href="#path-1"></use>
        </mask>
        <use id="Mask" fill="currentColor" href="#path-1"></use>
        <g mask="url(#mask-2)" fill="currentColor" id="Icon-Color">
          <g>
            <rect id="Rectangle" x="0" y="0" width="76" height="76"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default End;
