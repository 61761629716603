import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDesktopMonitor = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 60 60"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m48.466,16.4H15.552c-1.338,0-2.423,1.085-2.423,2.423v22.501c-.01,1.338,1.066,2.431,2.404,2.441.006,0,.012,0,.018,0h32.914c1.338-.01,2.415-1.103,2.405-2.441h0v-22.51c-.005-1.327-1.077-2.404-2.405-2.414Zm-32.347,24.411v-21.431h31.781l.018,21.431h-31.799Zm23.333,7.881h-2.432v-3.081h-10.057v3.081h-2.395c-1.602.005-2.898,1.305-2.898,2.907h20.672c0-1.599-1.291-2.897-2.889-2.907Z"
    />
  </svg>
);

export default SvgDesktopMonitor;
