import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgLayers = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.98888889,17.3466177 L1.8,11.3371788 L0,12.6586261 L10,20 L20,12.6586261 L18.1888889,11.3266911 L9.98888889,17.3466177 Z M10,14.6827478 L18.1777778,8.67330886 L20,7.34137389 L10,0 L0,7.34137389 L1.81111111,8.67330886 L10,14.6827478 Z"
    ></path>
  </svg>
);

export default SvgLayers;
