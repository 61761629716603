import LazyImage from 'src/components/Common/LazyImage';
import Link from 'src/components/Common/Link';
import { FooterProps } from '../types';

const SocialLinks = ({ items }: { items: FooterProps['socialLinks'] }) => {
  return (
    <nav aria-label="Social">
      <ul className="flex justify-center flex-wrap align-middle space-x-8 -m-12">
        {items?.map(({ link, image, track }, index) => (
          <li key={index}>
            <Link
              aria-label={link.value?.text}
              className="block p-12 transform hover:scale-125 transition-transform duration-300"
              onClick={() => track()}
              {...link}
              rel="noopener noreferrer"
            >
              <LazyImage
                alt=""
                className="icon-20"
                loadingClass="animate-pulse flex bg-teal-darker"
                {...image}
              />
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const AppBadges = ({ items }: { items: FooterProps['badges'] }) => {
  return (
    <nav aria-label="App Download" className="mt-24 md:mt-32">
      <ul className="flex justify-center flex-wrap gap-x-24 gap-y-16">
        {items?.map(({ link, image, track }, index) => (
          <li key={index}>
            <Link
              aria-label={link.value?.text}
              className="block transform hover:scale-105 transition-transform duration-300"
              onClick={() => track()}
              {...link}
              rel="noopener noreferrer"
            >
              <LazyImage
                alt=""
                className="h-44 w-auto"
                loadingClass="animate-pulse flex bg-teal-darker"
                {...image}
              />
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const SocialLinksAndAppBadges = ({
  socialLinks,
  badges,
}: {
  socialLinks: FooterProps['socialLinks'];
  badges: FooterProps['badges'];
}) => {
  return (
    <div
      className="px-16 md:px-24 py-24 md:py-32 border-b border-teal-darker"
      data-testid="SocialLinksAndAppBadges"
    >
      <div className="container-4xl">
        <SocialLinks items={socialLinks} />
        <AppBadges items={badges} />
      </div>
    </div>
  );
};

export { SocialLinksAndAppBadges };
