import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSitecoreContext } from './SitecoreContextFactory';

const useRestoreScroll = () => {
  const prevRoute = useRef<string | undefined>();
  const location = useLocation();
  const { context } = useSitecoreContext();
  const route = context.itemPath;
  useEffect(() => {
    // Check that route has changed, changing jurisdiction eg mutate('NC01') is not
    // a different route.
    const isDifferentRoute = route !== prevRoute.current;
    // Do not scroll if we have a hash link, Accordion uses hash links to scroll the page itself
    if (isDifferentRoute && !location?.hash) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    prevRoute.current = route;
  }, [route]);
};

export default useRestoreScroll;
