import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import track from 'src/lib/Analytics';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import { getSectionSegment } from './helpers';

const useTrackRouteChange = () => {
  const location = useLocation();
  const { status } = useAppContext();

  const jurisdiction = status.jurisdiction?.jurisdictionCode;
  useEffect(() => {
    const currentPage = document.location.pathname + document.location.search;
    if ((window as any).currentPage !== currentPage) {
      (window as any).prevPage = (window as any).currentPage ?? '';
      (window as any).currentPage = currentPage;
    }

    track.navigation({
      event: 'send-page',
      page: location.pathname,
      segment: getSectionSegment(),
      jurisdiction,
    });
  }, [location.pathname]);
};

const GoogleTagManager = () => {
  useEffect(() => {
    if (globalConfig?.GoogleTagManagerId) {
      TagManager.initialize({ gtmId: globalConfig.GoogleTagManagerId });
    }
  }, []);

  useTrackRouteChange();
  // Return a fragment so that we can use GoogleTagManager as a JSX element, even tho it has no visual interface
  // it responds to route data like more typical, UI components.
  return <></>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const gtag = function (...args: any[]) {
  TagManager.dataLayer({ dataLayer: arguments });
};

const getClientId = async (): Promise<string> => {
  return new Promise(resolve => {
    gtag('get', 'G-HB58MJRNTY', 'client_id', resolve);
  });
};

export const useClientId = () => {
  const [clientId, setClientId] = useState('');

  useEffect(() => {
    getClientId().then(id => setClientId(id));
  }, []);

  return clientId;
};

export default GoogleTagManager;
