const dukeSubDomain = '.duke-energy.com';

/** Formats ".duke-enrgy.com" urls as relative for analytics usage */
export const generateRelativePathName = (url: string) => {
  if (url.indexOf(dukeSubDomain) > 0) {
    const [subDomain, pathname] = url.split(dukeSubDomain);
    // if subdomain is www, p-auth, or -cd, convert the url, otherwise, include domain in the url
    if (['www', 'p-auth', '-cd', 'sc10'].some(x => subDomain.includes(x))) {
      return pathname;
    } else if (subDomain.includes('p-micro') || subDomain.includes('her')) {
      return url.replace('https://', '');
    } else return url;
  }
  return url;
};
