import { useMemo } from 'react';
import * as Svgs from 'src/components/Common/Svgs';
import ErrorBoundary from 'src/lib/HOC/ErrorBoundary';
import { SvgLoaderTypes } from './types';

// README - docs/SvgLoader.md
type SvgComponents = { [k: string]: (typeof Svgs)['AlertSolid'] };
const load = (name: string) => (Svgs as SvgComponents)?.[name];

/**
 * @deprecated please use the svg markup directly as a JSX.Element
 */
const SvgLoader = ({
  animate,
  className = '',
  color = 'text-teal-darker',
  name,
  rotate,
  size = 16,
  sizeConstraints = true,
  style,
  titleId,
  ...rest
}: SvgLoaderTypes) => {
  const SvgComponent = useMemo(() => load(name), [name]);
  const classes = `${color} ${className}`.trim();
  const { sizing, styleSizing } = sizeConstraints
    ? { sizing: { height: size, width: size }, styleSizing: { minHeight: size, minWidth: size } }
    : { sizing: {}, styleSizing: {} };

  const props = {
    ...rest,
    className: classes,
    style: {
      fill: 'currentColor',
      ...styleSizing,
      transform: rotate ? `rotate(${rotate}deg)` : '',
      transition: animate ? 'transform 0.3s ease-in-out' : '',
      ...style,
    },
    ...sizing,
  };

  if (!name || !SvgComponent) return null;

  return <SvgComponent {...{ 'data-testid': 'svg', titleId, ...props }} />;
};

const SafeSvgLoader = ({ titleId, ...props }: SvgLoaderTypes) => {
  return (
    <ErrorBoundary fallback={<svg {...props} />}>
      <SvgLoader {...{ ...props, titleId }} />
    </ErrorBoundary>
  );
};

export default SafeSvgLoader;
