import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { useEffect, useState } from 'react';
import LazyImage from 'src/components/Common/LazyImage';
import Link from 'src/components/Common/Link';
import SvgLoader from 'src/components/Common/SvgLoader';
import { AppBannerProps, BannerProps } from './types';

const appBannerCookie = 'appBanner=true';

const isAndroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
  return /android/i.test(userAgent);
};

const noCookie = () => document.cookie.indexOf(appBannerCookie) === -1;

const isClient = () => typeof window !== 'undefined';

const setCookie = (expiration: `${number}` | string) => {
  const date = new Date();
  date.setDate(date.getDate() + Number(expiration));
  document.cookie = `${appBannerCookie}; expires=${date.toUTCString()}; path=/; domain=.duke-energy.com;`;
};

const useBannerCookie = () => {
  const [shouldShow, setShow] = useState(false);

  useEffect(() => {
    const shouldShow = () => {
      if (isClient()) {
        if (isEditorActive()) {
          return true;
        }
        if (isAndroid() && noCookie()) {
          return true;
        }
      }
      return false;
    };

    setShow(shouldShow());
  }, []);

  return [shouldShow, setShow] as const;
};

const BannerComponent = ({ title, subtitle, body, link, ctaText, image, onClose }: BannerProps) => (
  <div
    className={`bg-gray-lighter flex items-center p-12 gap-12 ${
      isEditorActive() ? '' : 'md:hidden'
    }`}
    data-testid="BannerComponent"
  >
    <button onClick={onClose}>
      <SvgLoader name="X" color="text-gray-dark" className="icon-14" size={14} />
      <span className="sr-only">Close</span>
    </button>
    <LazyImage {...image} className="rounded-lg icon-76" />
    <div className="flex flex-col flex-1 text-gray-darker">
      <Text tag="p" field={title} className="font-bold text-md" />
      <Text tag="p" field={subtitle} className="text-xs" />
      <Text tag="p" field={body} className="text-xs" />
    </div>
    <Link {...link} className="text-teal-darker" onClick={onClose}>
      <Text field={ctaText} />
    </Link>
  </div>
);

const AppBanner = ({ expiration, ...props }: AppBannerProps) => {
  const [shouldShow, setShow] = useBannerCookie();

  if (!shouldShow) {
    return null;
  }

  return (
    <BannerComponent
      onClose={() => {
        setCookie(expiration);
        setShow(false);
      }}
      {...props}
    />
  );
};

export { BannerComponent, appBannerCookie, AppBanner as default };
