import Link from 'src/components/Common/Link';
import track from 'src/lib/Analytics';
import { useSignInContent, useSignInMachine } from '..';
import { ButtonGroup } from '../../../Common/@electron/ButtonGroup';
import SpinnerButton from '../../../Common/@electron/SpinnerButton';
import ForgotUsernamePasswordLinks from '../ForgottenLinks';

export const SignInForgotButtonGroup = () => {
  const { state } = useSignInMachine();
  const { content } = useSignInContent();
  const buttonText = content.signInCallToAction;

  return (
    <ButtonGroup
      primary={
        <SpinnerButton
          aria-busy={state.matches('pending')}
          disabled={state.matches('pending')}
          buttonText={buttonText}
          screenReaderText={state.matches('pending') ? 'Processing' : buttonText}
          className="btn-md btn-full"
          isSpinning={state.matches('pending')}
          type="submit"
        />
      }
      tertiary={<ForgotUsernamePasswordLinks />}
    />
  );
};

// need to use ButtonGroup to ensure matching width of buttons
export const RegisterButton = () => {
  const { content } = useSignInContent();
  const { subheadline, analyticsVariant, registerAccountCallToAction, registrationLink } = content;

  const handleRegisterClick = () => {
    track({
      event: 'send-VPV',
      'vpv-name': `/vpv/de/page/sign-in/clickthru/sign-in/${subheadline}/na/${analyticsVariant}/register-now`,
    });
  };

  return (
    <ButtonGroup
      primary={
        <Link
          onClick={handleRegisterClick}
          href={registrationLink}
          className="btn btn-secondary btn-md btn-full"
        >
          {registerAccountCallToAction}
        </Link>
      }
    />
  );
};

export const StandaloneSignInButtonGroup = () => {
  const { state } = useSignInMachine();
  const { content, secondaryButton, tertiaryButton } = useSignInContent();
  const {
    signInCallToAction,
    previousButtonText,
    previousButtonLink,
    cancelButtonLink,
    cancelButtonText,
  } = content;

  const hasSecondaryButton = !!secondaryButton || !!previousButtonLink?.href;

  const hasTertiaryButton = !!tertiaryButton || !!cancelButtonLink?.href;

  return (
    <ButtonGroup
      primary={
        <SpinnerButton
          aria-busy={state.matches('pending')}
          buttonText={signInCallToAction}
          screenReaderText={state.matches('pending') ? 'Processing' : signInCallToAction}
          className="btn-md btn-full"
          isSpinning={state.matches('pending')}
          type="submit"
        />
      }
      secondary={
        hasSecondaryButton
          ? secondaryButton || (
              <Link
                className="btn btn-secondary btn-md btn-full"
                value={{ ...previousButtonLink, text: previousButtonText }}
              />
            )
          : undefined
      }
      tertiary={
        hasTertiaryButton
          ? tertiaryButton || (
              <Link
                className="border-b border-teal-darker text-teal-darker mt-4"
                value={{ ...cancelButtonLink, text: cancelButtonText }}
              />
            )
          : undefined
      }
    />
  );
};
