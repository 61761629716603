import React, { useRef } from 'react';
import Overlay from 'src/components/Common/@electron/Overlay';
import CloseX from 'src/components/Common/Svgs/X';
import { useFocusTrap } from 'src/lib/Hooks/useFocusTrap';
import useIntersection from 'src/lib/Hooks/useIntersection';

type PanelContainerProps = React.PropsWithChildren<{
  isOpen?: boolean;
  panelRef: React.RefObject<HTMLDivElement>;
  name: string;
  variant: 'left' | 'right';
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}>;

type LogoType = {
  src: string;
  altText: string;
  href: string;
};

type DrawerProps = React.PropsWithChildren<{
  variant: 'left' | 'right';
  isOpen?: boolean;
  onClose: () => void;
  name: string;
  header?: string;
  logo?: LogoType;
  // adding this because some drawers are already built out with padding factored in. Also keeps drawer flexible
  useDefaultPadding?: boolean;
  footer?: JSX.Element;
}>;

const PanelContainer = ({
  isOpen,
  panelRef,
  variant,
  name,
  children,
  ...rest
}: PanelContainerProps) => {
  const variantMap = {
    left: {
      position: 'justify-start pr-24',
      border: 'rounded-r-md sm:rounded-r-lg',
      direction: '-translate-x-full',
    },
    right: {
      position: 'justify-end pl-24',
      border: 'rounded-l-md sm:rounded-l-lg',
      direction: 'translate-x-full',
    },
  } as const;
  const style = variantMap[variant];

  return (
    <div className={`fixed inset-0 flex z-0 isolate ${style.position}`}>
      <div
        aria-hidden={!isOpen}
        aria-label={name}
        aria-modal="true"
        role="dialog"
        style={{ outlineOffset: '-4px' }}
        tabIndex={-1}
        className={`relative flex flex-col w-full max-w-xs h-full shadow-md bg-white transition-all transform focus:border-none ${
          style.border
        } ${
          isOpen
            ? 'ease-out duration-300 delay-100 translate-x-0'
            : ` ease-in duration-100 ${style.direction}`
        }`}
        ref={panelRef}
        {...rest}
        {...(isOpen ? {} : { inert: 'true' })}
      >
        {/* isOpen condition in original <Drawer /> removes element before it can finish closing animation */}
        {children}
      </div>
    </div>
  );
};

const HeaderCloseButton = ({
  header = '',
  onClose,
  logo,
  isScrolled = false,
}: Pick<DrawerProps, 'header' | 'onClose' | 'logo'> & { isScrolled?: boolean }) => {
  return (
    <header
      className={`${
        isScrolled ? 'shadow-md' : ''
      } flex-shrink-0 p-16 pb-12 sm:px-24 sm:pt-24 sm:pb-16 border-b border-gray z-20`}
    >
      <div className="flex justify-between items-start gap-16 sm:gap-24">
        <div>
          {logo && (
            <a href={logo.href} onClick={onClose}>
              <img src={logo?.src} alt={logo?.altText} className="h-24"></img>
            </a>
          )}
          <h2 className="text-xl leading-sm text-gray-darker">{header}</h2>
        </div>
        <div>
          <button
            aria-label="Close"
            type="button"
            className="-m-12 btn btn-tertiary btn-xs btn-icon-only"
            onClick={onClose}
          >
            <CloseX
              name="X"
              className="text-teal-darker icon-16"
              aria-hidden="true"
              focusable="false"
            />
          </button>
        </div>
      </div>
    </header>
  );
};

const Drawer = ({
  variant,
  isOpen,
  onClose,
  children,
  name,
  header,
  logo,
  footer,
  useDefaultPadding = false,
}: DrawerProps) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const { isIntersecting, ref } = useIntersection<HTMLDivElement>({ threshold: 0 }, false);

  useFocusTrap({
    shouldTrap: isOpen,
    container: panelRef.current,
    onExit: onClose,
    onEnter: () => document.hasFocus() && panelRef.current?.focus(),
  });

  const close = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target instanceof Element && event.target.contains(panelRef.current)) {
      onClose();
    }
  };

  return (
    <Overlay isOpen={!!isOpen} onClick={onClose}>
      <PanelContainer
        isOpen={isOpen}
        panelRef={panelRef}
        variant={variant}
        onClick={close}
        name={name}
      >
        <HeaderCloseButton
          onClose={onClose}
          header={header}
          logo={logo}
          isScrolled={!isIntersecting}
        />
        <div
          className={`flex-grow ${
            useDefaultPadding ? 'p-16 sm:p-24' : ''
          } h-full overflow-y-scroll z-10`}
        >
          {/* div for checking scrolled drawer for drop shadow on header */}
          <div ref={ref} className="w-0 h-0" />
          {children}
        </div>
        {typeof footer !== 'undefined' && (
          <footer
            className={`flex-shrink-0 ${
              useDefaultPadding ? 'p-16 pt-12 sm:px-24 sm:pt-16 sm:pb-24' : ''
            } border-t border-gray z-20`}
          >
            {footer}
          </footer>
        )}
      </PanelContainer>
    </Overlay>
  );
};

export { Drawer, Overlay };
