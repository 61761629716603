import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import { Hamburger, JurisdictionButton, NavTitle, SpanishToggle, SwitchPortal } from './components';
import AccountButton from './components/AccountButton';
import { DefaultLogo, Logo } from './components/Logo';
import SearchButton from './components/SearchButton';
import StickyNav from './components/StickyNav';
import { PrimayNavProps } from './types';

const SimpleNav = ({ logo, title }: { logo: PrimayNavProps['logo']; title?: string }) => {
  return (
    <div className="js-site-header-main px-16 md:px-24 py-12 sm:py-16 xl:py-24 bg-white border-b border-gray">
      <div className="container-full flex sm:justify-center items-center h-full">
        <div className="flex items-center gap-16 xl:gap-24 z-10">
          <DefaultLogo {...logo} />

          {title && (
            <div className="flex items-center sm:logo-de-sm lg:logo-de pl-16 xl:pl-24 max-w-3xs text-lg sm:text-xl text-blue border-l border-gray">
              <h1 className="mt-4 line-clamp-1">{title}</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MinimalNav = ({ logo }: { logo: PrimayNavProps['logo'] }) => {
  return (
    <div className="js-site-header-main px-16 md:px-24 py-12 sm:py-16 xl:py-24 bg-white border-b border-gray">
      <div className="container-full flex justify-center items-center h-full">
        <div className="flex items-center gap-16 xl:gap-24 z-10">
          <DefaultLogo {...logo} />
        </div>
      </div>
    </div>
  );
};

const PrimaryNav = ({
  accountLink,
  accountTxt,
  auth,
  guid,
  hamburgerAriaLabel,
  hideTitle,
  juris,
  logo,
  defaultLogo,
  navTitle,
  profileLinks,
  search,
  selectLocationText,
  signIn,
  stateName,
  switchPortal,
  shouldShowSpanishToggle,
  shouldShowSwitchPortal,
  variant,
}: PrimayNavProps) => {
  const location = useLocation();
  const { pathname } = location;

  const data = useAppContext();

  const headlineData = data.status?.page?.navItems?.headline;
  // default behavior is to use the section title, so if OverrideSectionTitle is false or undefined, we use the section title
  const shouldUseSectionTitle = !data?.status?.route?.fields?.OverrideSectionTitle?.value;
  // display as a text when the page is an Anchor, as a link otherwise
  const isAnchorPage = data?.status?.route?.fields['Is Anchor Page'].value;

  if (headlineData && shouldUseSectionTitle) {
    navTitle = {
      href: headlineData.route,
      text: { value: headlineData.name },
    };
  }

  const headerStyle = data.status?.route?.fields?.['Header Type']?.fields?.Phrase?.value;
  if (headerStyle === 'Simple') {
    return (
      <StickyNav>
        <SimpleNav logo={defaultLogo} title={navTitle.text.value} />
      </StickyNav>
    );
  }

  if (variant === 'minimal') {
    return (
      <StickyNav>
        <MinimalNav logo={defaultLogo} />
      </StickyNav>
    );
  }

  return (
    <StickyNav>
      <div className="js-site-header-main px-16 md:px-24 py-12 sm:py-16 xl:py-24 bg-white border-b border-gray">
        <div className="container-full flex justify-between items-center h-full">
          <div className="flex items-center gap-16 xl:gap-24 z-10">
            <Hamburger hamburgerAriaLabel={hamburgerAriaLabel} />
            <Logo {...logo} />
            {!hideTitle && <NavTitle {...{ ...navTitle, guid, isAnchorPage }} />}
          </div>
          <nav aria-label="Utility" className="ml-24 xl:ml-32">
            <ul className="flex lg:hidden -mr-12 -my-12 divide-x divide-gray">
              {!isAnchorPage && <SearchButton variant="small" {...{ guid, pathname, ...search }} />}
              <AccountButton
                {...{
                  signIn,
                  auth,
                  accountLink,
                  accountTxt,
                  guid,
                  pathname,
                  profileLinks,
                }}
                variant="small"
              />
            </ul>
            <ul className="hidden lg:flex lg:gap-24 xl:gap-32">
              {shouldShowSwitchPortal && <SwitchPortal {...switchPortal} />}
              {/* Not display SpanishToggle whenever we are on the Map Page  */}
              {shouldShowSpanishToggle && !isAnchorPage && (
                <SpanishToggle {...{ guid, pathname }} />
              )}
              <JurisdictionButton {...{ guid, pathname, juris }}>
                {juris ? (
                  <>
                    <span className="sr-only">change location from </span>
                    <Text field={stateName} />
                  </>
                ) : (
                  selectLocationText
                )}
              </JurisdictionButton>
              {/* Not display Search buttom whenever we are on the Map Page  */}
              {!isAnchorPage && <SearchButton {...{ guid, pathname, ...search }} />}
              <AccountButton
                {...{
                  signIn,
                  auth,
                  accountLink,
                  accountTxt,
                  guid,
                  pathname,
                  profileLinks,
                }}
              />
            </ul>
          </nav>
        </div>
      </div>
    </StickyNav>
  );
};

export default PrimaryNav;
