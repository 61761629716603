import track from 'src/lib/Analytics';

interface SignOutProps {
  profileLinks: { href?: string; text?: string }[];
  guid?: string;
}

const SignOut = ({ profileLinks, guid }: SignOutProps) => {
  const handleClick = (item: typeof profileLinks[0]) => {
    const signOutClicked = item.text === 'Sign Out';
    track({
      event: 'send-VPV',
      'vpv-name': `/vpv/de/page/sign-in/clickthru/my-profile/na/sign-out-drawer/${
        signOutClicked ? 'sign-out' : 'dashboard'
      }`,
    });
    track.component({
      event: 'event-click',
      category: 'top_level_navigation',
      action: signOutClicked ? '/home' : item.href,
      label: `profile-|-${item.text}`,
      guid,
    });
  };

  return (
    <div className="max-w-2xs px-16 py-24 flex flex-col gap-16">
      <ul className="flex flex-col gap-16">
        {profileLinks?.map((item, key) => (
          <li key={key}>
            {item.href && (
              <a
                onClick={() => handleClick(item)}
                className="text-teal-darker hover:cursor-pointer text-lg"
                href={item.href}
              >
                {item?.text}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export { SignOut };

export default SignOut;
