import usePreventBodyScroll from 'src/lib/Hooks/usePreventBodyScroll';
import { OverlayProps } from './types';

const Overlay = ({ isOpen, onClick = () => {}, children, ...rest }: OverlayProps) => {
  usePreventBodyScroll(isOpen);

  return (
    <div className={`fixed inset-0 z-overlay ${isOpen ? 'visible' : 'invisible'}`} {...rest}>
      <div
        aria-hidden="true"
        className={`absolute inset-0 bg-gray-darker transition-opacity ${
          isOpen ? 'bg-opacity-80 ease-out duration-100' : 'bg-opacity-0 ease-in duration-150'
        }`}
        onClick={onClick}
      />

      {children}
    </div>
  );
};

export default Overlay;
