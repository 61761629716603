import logo from 'src/assets/svgs/duke-logo.svg';
import { DukeTagline } from 'src/components/Common/Svgs';

const footerProps = {
  copyright: { value: '© Duke Energy Corporation. All Rights Reserved.' },
  bottomLinks: [
    {
      href: '/legal/privacy',
      text: 'Privacy',
    },

    {
      href: '/legal/terms-of-use',
      text: 'Terms of Use',
    },

    {
      href: '/customer-service/accessibility',
      text: 'Accessibility',
    },
  ],
};

const Tagline = () => (
  <div className="px-16 md:px-24 py-24 md:py-32 border-b border-teal-darker">
    <div className="container-4xl">
      <DukeTagline
        className="mx-auto h-16 w-auto text-white fill-current"
        aria-label="Building a Smarter Energy Future"
      />
    </div>
  </div>
);

const BottomLinks = () => (
  <nav aria-label="Legal" className="lg:flex lg:justify-end lg:flex-grow py-12 lg:py-0">
    <ul className="lg:flex lg:space-x-24">
      {footerProps.bottomLinks.map(({ text, ...link }, index) => (
        <li key={index}>
          <a
            className="block lg:inline-block px-16 py-12 lg:p-0 no-underline hover:underline"
            {...link}
          >
            {text}
          </a>
        </li>
      ))}
    </ul>
  </nav>
);

const Copyright = () => (
  <p className="px-16 py-24 lg:p-0 border-t border-teal-darker lg:border-t-0">
    {footerProps.copyright.value}
  </p>
);

const Footer = () => (
  <footer>
    <div className="bg-blue text-white">
      <Tagline />
      <div className="lg:px-24 lg:py-24 text-center text-sm lg:text-xs">
        <div className="container-4xl lg:flex lg:flex-row-reverse lg:justify-center">
          <BottomLinks />
          <Copyright />
        </div>
      </div>
    </div>
  </footer>
);

const Header = () => (
  <header className="z-header sticky md:relative top-0">
    <div className="js-site-header-main px-16 md:px-24 py-12 sm:py-16 xl:py-24 bg-white border-b border-gray">
      <div className="container-full flex justify-center items-center h-full">
        <div className="flex items-center gap-16 xl:gap-24 z-10">
          <a href="/home" className="flex items-center">
            <img className="logo-de-sm lg:logo-de" src={logo} width={250} height={110} alt="" />
          </a>
        </div>
      </div>
    </div>
  </header>
);

const ErrorComponent = ({ error }: { error: Error }) => {
  return (
    <div className="p-16">
      <div className="container-4xl p-24 md:p-32 rounded-md w-full flex justify-center">
        <div className="container-md text-center">
          <svg
            className="icon-32 md:icon-48 mx-auto text-gray-darker"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            fill="currentcolor"
            aria-hidden="true"
          >
            <path d="M32,0C14.327,0,0,14.327,0,32s14.327,32,32,32,32-14.327,32-32S49.673,0,32,0Zm-4.681,17.472c0-2.583,2.094-4.677,4.677-4.677s4.677,2.094,4.677,4.677v.11c0,2.583-2.094,4.677-4.677,4.677s-4.677-2.094-4.677-4.677v-.11Zm9.362,29.979c0,2.01-1.629,3.639-3.639,3.639h-2.075c-2.01,0-3.639-1.629-3.639-3.639V28.965c0-2.01,1.629-3.639,3.639-3.639h2.075c2.01,0,3.639,1.629,3.639,3.639v18.487Z"></path>
          </svg>
          <h2 className="text-2xl mt-12 text-gray-darker">Error</h2>
          <div className="rich-text text-md md:text-lg mt-12">
            <p>This functionality is currently unavailable.</p>
            {/* We may not want the message immediately accessible to the user, but it is still useful to have it represent in the document */}
            <span className={`text-xs ${process.env.NODE_ENV !== 'production' ? '' : 'sr-only'}`}>
              {error.message}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RenderingError = ({ error }: { error: Error }) => {
  return (
    <div className="flex-grow flex flex-col">
      <a
        className="btn btn-secondary fixed left-1/2 top-0 text-teal-darker bg-white transform -translate-x-1/2 -translate-y-full focus:translate-y-16 focus:z-modal"
        href="#main-content"
      >
        Skip to Content
      </a>
      <Header />
      <main id="main-content" className="flex-grow flex flex-col">
        <ErrorComponent error={error} />
      </main>
      <Footer />
    </div>
  );
};
