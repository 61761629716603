import { useRef, useState } from 'react';
import componentFactory from 'src/componentFactory';
import CallToAction from '../CallToAction';
import Panel from './components/Panel';
import Trigger from './components/Trigger';
import { ItemTypes, JSSPushDownPanelTypes } from './types';

const JSSPushDownPanel = ({ items, ...rest }: JSSPushDownPanelTypes) => {
  const [activePanel, setActivePanel] = useState('');
  const togglePanel = (id: number) =>
    setActivePanel(id.toString() === activePanel ? '' : id.toString());

  return (
    <CallToAction {...rest}>
      <section className="px-16 md:px-24 py-48 md:py-64 bg-white">
        <div className="container-max">
          <div className="flex flex-wrap justify-center -my-16 sm:-my-24">
            {items.map((item, index) => (
              <Item key={index} activePanel={activePanel} togglePanel={togglePanel} {...item} />
            ))}
          </div>
        </div>
      </section>
    </CallToAction>
  );
};

const Item = ({ activePanel, togglePanel, ...item }: ItemTypes) => {
  const { id } = item;
  const isActive = id === parseInt(activePanel);

  const containerRef = useRef<HTMLHeadingElement>(null);
  const triggerId = `pushdown-trigger-${id}`;
  const panelId = `pushdown-panel-${id}`;

  const handleClick = () => {
    togglePanel(id);

    if (!isActive) {
      setTimeout(
        () =>
          containerRef.current &&
          // scroll up the window by the height of the pdp item
          window.scrollBy({
            top: containerRef.current.getBoundingClientRect().top - 0,
            behavior: 'smooth',
          }),
        300
      );
    }
  };

  return (
    <>
      <Trigger
        isActive={isActive}
        handleClick={handleClick}
        item={item}
        containerRef={containerRef}
        panelId={panelId}
        triggerId={panelId}
      />
      <Panel
        isActive={isActive}
        handleClick={handleClick}
        item={item}
        panelId={panelId}
        triggerId={triggerId}
      >
        {item.components.map((element, index) => {
          const { type } = element;
          const Component = componentFactory(type);

          return <Component key={index} fields={element.component[0].fields} />;
        })}
      </Panel>
    </>
  );
};

export default JSSPushDownPanel;
