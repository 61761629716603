import { useEffect } from 'react';
import CallToAction from 'src/components/Common/@electron/CallToAction';
import track from 'src/lib/Analytics';
import Composition from 'src/lib/HOC/Composition';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';

type MismatchProps = {
  title: JSS.TextField;
  subtitle: JSS.TextField;
  link: JSS.LinkField;
  id?: string;
};

const Mismatch = (props: MismatchProps) => {
  const { status } = useAppContext();
  const selectedJurisdiction = status.jurisdiction?.jurisdictionCode;
  useEffect(() => {
    track.component({
      action: window.location.pathname,
      category: 'mismatch_alert',
      guid: props.id || '',
      label: props.title.value + '-|-' + selectedJurisdiction,
    });
  }, []);

  return <CallToAction {...props} />;
};

export default Composition(Mismatch)(({ fields, id }) => {
  return {
    title: fields?.Headline,
    subtitle: fields?.Subhead,
    link: fields?.CTA,
    id,
  };
}).component;
