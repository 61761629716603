import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgBentoBox = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M75.612 61.71v10.445c0 2.12-1.78 3.856-3.953 3.856H60.952c-2.174 0-3.952-1.735-3.952-3.856V61.71c0-2.123 1.778-3.856 3.952-3.856h10.707c2.173 0 3.953 1.733 3.953 3.856Zm-28 0v10.445c0 2.12-1.78 3.856-3.952 3.856H32.954C30.78 76.01 29 74.276 29 72.155V61.71c0-2.123 1.78-3.856 3.954-3.856H43.66c2.172 0 3.952 1.733 3.952 3.856Zm-29 0v10.445c0 2.12-1.78 3.856-3.953 3.856H3.951C1.78 76.01 0 74.276 0 72.155V61.71c0-2.123 1.78-3.856 3.952-3.856h10.707c2.173 0 3.953 1.733 3.953 3.856Zm57-27.999v10.444c0 2.12-1.78 3.857-3.953 3.857H60.952c-2.174 0-3.952-1.736-3.952-3.857V33.711c0-2.122 1.778-3.857 3.952-3.857h10.707c2.173 0 3.953 1.735 3.953 3.857Zm-28 0v10.444c0 2.12-1.78 3.857-3.952 3.857H32.954c-2.174 0-3.954-1.736-3.954-3.857V33.711c0-2.122 1.78-3.857 3.954-3.857H43.66c2.172 0 3.952 1.735 3.952 3.857Zm-29-1v10.444c0 2.12-1.78 3.857-3.953 3.857H3.951C1.78 47.012 0 45.276 0 43.155V32.711c0-2.122 1.78-3.857 3.952-3.857h10.707c2.173 0 3.953 1.735 3.953 3.857ZM71.66 18.012H60.952c-2.174 0-3.952-1.736-3.952-3.857V3.71c0-2.12 1.778-3.856 3.952-3.856h10.707c2.175 0 3.953 1.735 3.953 3.856v10.445c0 2.12-1.778 3.857-3.953 3.857Zm-27.999 0H32.954c-2.174 0-3.954-1.736-3.954-3.857V3.71c0-2.12 1.78-3.856 3.954-3.856H43.66c2.174 0 3.952 1.735 3.952 3.856v10.445c0 2.12-1.778 3.857-3.952 3.857Zm-29.001 0H3.951C1.78 18.012 0 16.276 0 14.155V3.71C0 1.59 1.78-.146 3.952-.146h10.707c2.175 0 3.953 1.735 3.953 3.856v10.445c0 2.12-1.778 3.857-3.953 3.857Z"
    />
  </svg>
);

export default SvgBentoBox;
