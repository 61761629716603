import dayjs from 'dayjs';
import LazyImage from 'src/components/Common/LazyImage';
import Link from 'src/components/Common/Link';
import SvgLoader from 'src/components/Common/SvgLoader';
import { StockTickerProps } from './types';

export const getProps = (data: any) => {
  const stockTickerFeed = data?.fields?.stockTickerFeed;

  if (!stockTickerFeed) {
    return null;
  }

  const stockDetails = [
    {
      name: 'Previous Close',
      value: stockTickerFeed?.previousClose,
    },
    {
      name: '52 Week Low',
      value: stockTickerFeed?.['52WeekLow'],
    },
    {
      name: '52 Week High',
      value: stockTickerFeed?.['52WeekHigh'],
    },
    {
      name: 'Market Cap',
      value: stockTickerFeed?.marketCap,
    },
  ];

  const stockInfo = {
    dir: stockTickerFeed?.changePercent >= 0 ? 'up' : 'down',
    prefix: stockTickerFeed?.changePercent > 0 ? '+' : '',
    symbol: stockTickerFeed?.symbol,
    price: stockTickerFeed?.lastTrade,
    percent: stockTickerFeed?.changePercent,
    date: stockTickerFeed?.date,
  };

  return { stockDetails, stockInfo };
};

const arrowStyleMap: { [key: string]: any } = {
  up: { rotate: 180, style: { color: '#54b948' } },
  down: {
    rotate: 0,
    style: { color: '#9e1b32' },
  },
};

const StockTicker = ({ image, link, stockDetails, stockInfo }: StockTickerProps) => {
  const arrowStyle = arrowStyleMap[stockInfo.dir];
  return (
    <div
      className="text-center text-white block z-dropdown py-32 px-24"
      style={{ backgroundColor: '#193542' }}
    >
      <section>
        <div>
          <p>
            <sup
              className="uppercase text-md relative -top-12 left-2"
              style={{ verticalAlign: 'super' }}
            >
              {stockInfo.symbol}
              <SvgLoader
                {...arrowStyle}
                className="absolute right-12 top-16"
                name="SmallTriangle"
                size={12}
              />
            </sup>
            <span className="text-2xl"> {stockInfo.price}</span>
          </p>
          <p className="text-md percentage">
            {stockInfo.prefix}
            {stockInfo.percent}({stockInfo.prefix}
            {stockInfo.percent}%)
            <SvgLoader {...arrowStyle} name="SmallTriangle" className="inline-block ml-8" />
          </p>
          <p className="text-xs text-gray">as of {dayjs(stockInfo.date).format('M/D/YY h:mm A')}</p>
          <div className="overflow-hidden relative" style={{ width: '225px', height: '107px' }}>
            {image && (
              <LazyImage
                {...image}
                className="absolute w-full h-full max-w-none -left-2 -top-2"
                style={{ width: '230px', height: '111px' }}
                loadingClass="animate-pulse flex bg-teal-darker"
              />
            )}
          </div>
        </div>
      </section>
      <section className="pt-4">
        <table className="invert w-full">
          <tbody>
            {stockDetails.map((item, index) => (
              <tr key={index} className="text-md">
                <th className="font-normal text-left pt-6">{item.name}</th>
                <td className="text-right">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <section>
        <div>
          <div className="pt-16">
            {link && (
              <Link className="uppercase underline font-bold text-lg" {...link}>
                Shareholder Sign In
              </Link>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default StockTicker;
