import Button from 'src/components/Common/@electron/Button';
import { useStep } from 'src/components/Common/Jurisdiction/context';
import { useJurisdictionSelectorData } from 'src/components/Common/JurisdictionGuard/useJurisdictionSelectorData';
import Link from 'src/components/Common/Link';
import { Header } from './index';

// TODO: DNT-1672 This step will be used with geolocation functionality
const Intro = () => {
  const { set } = useStep();
  const { interceptProps } = useJurisdictionSelectorData();
  const { fields } = interceptProps;

  const geolocationStateName = 'TODO';

  return (
    <div className="container-xl flex flex-col gap-24">
      <Header
        name="Intro"
        headline={fields['Single Jurisdiction Headline']}
        subhead={fields.Subhead}
        stateName={geolocationStateName}
      />
      <div className="flex justify-center gap-24">
        <Button variant="secondary" {...fields['No Button Text']} onClick={() => set('List')} />
        <Button variant="primary" {...fields['Yes Button Text']} />
      </div>
      <div className="flex justify-center">
        <Link
          href="/home"
          className="text-teal-darker transition-all duration-150 border-b border-current hover:border-white"
        >
          Go to home page
        </Link>
      </div>
    </div>
  );
};

export default Intro;
