import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgIndividualSolidDualTone = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 40 40"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx="20" cy="20.4004" r="20" fill="#E0F6FB" />
    <path
      d="M30 29.8873H10V28.8935C10 26.6498 11.4 25.5779 14.1937 24.756H14.2375C15.8938 24.1742 17.1938 23.6248 17.3625 22.9998C17.5313 22.3748 17.0625 22.031 16.4375 21.1248C15.5031 19.7629 15.0019 18.151 15 16.4998C15 13.0873 17.2375 10.9123 20 10.9123C20.5925 10.9073 21.1806 11.0085 21.7369 11.2123C22.3756 11.4498 22.9556 11.8229 23.4369 12.306C24.4175 13.341 24.975 14.7054 25 16.131V16.4992C24.995 16.9454 24.9531 17.3904 24.875 17.8304C24.7775 18.4079 24.6206 18.9735 24.4062 19.5185C24.1662 20.136 23.8481 20.7198 23.4587 21.2554C22.88 22.0679 22.44 22.3929 22.6213 23.0304C22.8025 23.6679 24.0425 24.3623 25.7463 24.7742C28.3875 25.4004 30 26.6504 30 28.8942V29.8873Z"
      fill="#00789E"
    />
  </svg>
);

export default SvgIndividualSolidDualTone;
