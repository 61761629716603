import React from 'react';

const MagnifyingGlass = () => {
  return (
    <svg
      className="icon-76 md:icon-122"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 76 76"
      aria-hidden="true"
      focusable="false"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '@media (prefers-reduced-motion: no-preference) {.magnifing-glass {animation: move 4s infinite ease-in-out, rotate 8s infinite ease-in-out;animation-direction: alternate;} @keyframes move {25% {transform: translate(10%, -20%);}75% {transform: translate(60%, -40%);}} @keyframes rotate {50% {transform: rotateZ(120deg) scale(.9);transform-origin: center center;}}}',
          }}
        />
        <clipPath id="mask-magnifying">
          <circle className="magnifing-glass" cx={38} cy={38} r="18.329" />
        </clipPath>
        <clipPath id="mask-outside">
          <circle cx={38} cy={38} r={38} />
        </clipPath>
      </defs>
      <circle cx={38} cy={38} r={38} fill="#a7e1ea" />
      <g clipPath="url(#mask-outside)">
        <path
          className="magnifing-glass"
          d="M23.787,49.587a18.346,18.346,0,1,1,14.227,6.745,18.237,18.237,0,0,1-14.227-6.745M64.4,58.939a3.278,3.278,0,0,0-4.546-.517l-.76.6L54.811,54a23.324,23.324,0,1,0-4.491,3.663l4.233,4.958-.51.4a3.238,3.238,0,0,0-.52,4.548L76.18,96.19a3.235,3.235,0,0,0,3.858.939,3.317,3.317,0,0,0,.689-.422l5.817-4.6a3.241,3.241,0,0,0,.515-4.546Z"
          fill="#00789e"
          fillRule="evenodd"
        />
      </g>
      <g clipPath="url(#mask-magnifying)">
        <path
          d="M44.453,35.568A11.858,11.858,0,0,1,41.9,38.4a11.083,11.083,0,0,0-1.486,1.452,2.837,2.837,0,0,0-.447.936,8.7,8.7,0,0,0-.183,1.951.648.648,0,0,1-.653.63H36.75a.649.649,0,0,1-.653-.623c-.009-.333-.014-.581-.014-.747a7.357,7.357,0,0,1,.457-2.7,6.3,6.3,0,0,1,1.082-1.756,18.6,18.6,0,0,1,1.85-1.767,7.5,7.5,0,0,0,1.535-1.612,2.188,2.188,0,0,0,.3-1.127A2.711,2.711,0,0,0,40.377,31a3.186,3.186,0,0,0-2.308-.892,3.081,3.081,0,0,0-2.192.8,4.5,4.5,0,0,0-1.19,2.668.664.664,0,0,1-.721.537l-2.542-.3a.651.651,0,0,1-.437-.241.641.641,0,0,1-.136-.475,7.055,7.055,0,0,1,2.258-4.642,7.27,7.27,0,0,1,4.919-1.632,7.46,7.46,0,0,1,5.154,1.747,5.592,5.592,0,0,1,1.971,4.3,5.421,5.421,0,0,1-.7,2.7ZM39.788,48.092a.637.637,0,0,1-.637.637H36.4a.637.637,0,0,1-.637-.637h0v-2.75a.638.638,0,0,1,.637-.636h2.749a.638.638,0,0,1,.637.636Z"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export { MagnifyingGlass };
