import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgBoatRamp = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width="70" height="70" rx="6" ry="6" fill="currentColor" />
    <path
      d="M37.34,43.3a5.26,5.26,0,1,0-2.43-.63L10.85,35.83V53.77A5.6,5.6,0,0,0,15.68,51a5.6,5.6,0,0,0,9.68,0A5.6,5.6,0,0,0,35,51a5.6,5.6,0,0,0,9.67,0,5.6,5.6,0,0,0,9.68,0c1,1.66,2.82,2.32,4.83,2.77V49.43L37.09,43.27Zm0-7.46a2.27,2.27,0,1,1-2.27,2.27A2.27,2.27,0,0,1,37.34,35.84Z"
      fill="#fff"
    />
    <path
      d="M58,28l-28.1-7.32,5.36-4-1.78-.52-5.42,4-9.81-2.56c-1,8,8.3,10.79,8.3,10.79l29.33,7.67a1.91,1.91,0,0,0,2.32-1.36l1.15-4.42A1.91,1.91,0,0,0,58,28Z"
      fill="#fff"
    />
    <path d="M33,32.94l-22.5-6.12.59,2,20.56,5.6A6.75,6.75,0,0,1,33,32.94Z" fill="#fff" />
    <path d="M57,39.47,43.66,35.83a6.69,6.69,0,0,1,.39,2l12.52,3.41Z" fill="#fff" />
  </svg>
);

export default SvgBoatRamp;
