import { InputClassProps, InputWrapperClassProps, LabelClassProps } from '../types';

// for the root text input fields
const inputClasses = ({ hasError, hasIcon, type }: InputClassProps) => {
  const baseClass =
    'relative bg-transparent border-b text-gray-dark w-full outline-none rounded-none text-md-fixed z-10';
  const searchClass = type === 'searchInput' ? 'focus:outline-none placeholder-gray-dark' : '';
  const errorClass = hasError ? 'border-red' : '';
  const iconClass = hasIcon ? 'pl-24' : '';

  return `${baseClass} ${searchClass} ${errorClass} ${iconClass}`.trim();
};

// for the wrapping tag of InputText
const inputWrapperClasses = ({ type, isDisabled }: InputWrapperClassProps) => {
  const paddingClass = type === 'searchInput' ? 'pl-0' : 'pl-0 pb-48';
  const disabledClass = isDisabled ? 'opacity-40' : '';
  return `${paddingClass} ${disabledClass}`;
};

// for the text input labels
const labelClasses = ({ hasError, hasIcon, isAbove, isFocused }: LabelClassProps) => {
  const baseClass =
    'absolute left-0 bottom-0 transform -translate-y-4 transition-all duration-150 ease-in-out';
  const iconClass = () => (hasIcon && !isAbove ? 'pl-24' : '');
  const aboveBaseClass = () => (isAbove || isFocused ? 'top-0 -mt-16 text-xs' : 'text-md');
  const aboveColorClass = () => (isFocused ? 'text-teal-darker' : 'text-gray-dark');
  const errorClass = () => (hasError && isAbove ? 'text-red' : '');

  return `${baseClass} ${aboveBaseClass()} ${aboveColorClass()} ${errorClass()} ${iconClass()}`.trim();
};

export { inputClasses, inputWrapperClasses, labelClasses };
