import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgInfoSolid = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M38 0a38 38 0 1 0 38 38A38 38 0 0 0 38 0Zm-5.3 19.984a5.984 5.984 0 0 1 11.968 0v.14a5.984 5.984 0 0 1-11.968 0Zm11.979 38.36A4.657 4.657 0 0 1 40.02 63h-2.655a4.657 4.657 0 0 1-4.656-4.656V34.689a4.656 4.656 0 0 1 4.656-4.656h2.655a4.656 4.656 0 0 1 4.656 4.656Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgInfoSolid;
