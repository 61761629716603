import { useMemo } from 'react';
import { compositionFunction } from 'src/components/Common/Jurisdiction/composition';
import { useSitecoreContext } from 'src/lib/Hooks/SitecoreContextFactory';
import { intercept, mismatch } from './data';

const useJurisdictionSelectorData = () => {
  const { context } = useSitecoreContext();
  return useMemo(() => {
    const header = context?.route?.placeholders?.['jss-public-header'];
    const navWrapper = header?.find(({ componentName }) => componentName === 'NavWrapper');

    const getProps = (componentName: string) =>
      navWrapper?.fields?.items?.find(({ name }: { name: string }) => name === componentName);

    const jurisdictionSelector = getProps('Jurisdiction Selector');
    const jurisdictionIntercept: typeof intercept = getProps('Jurisdiction Intercept');
    const jurisdictionMismatch: typeof mismatch = getProps('Jurisdiction Mismatch');

    return {
      selectorProps: compositionFunction(jurisdictionSelector),
      interceptProps: jurisdictionIntercept,
      mismatchProps: jurisdictionMismatch,
    };
  }, [context.language]);
};

export { useJurisdictionSelectorData };
