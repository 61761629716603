import { useEffect } from 'react';
import track from 'src/lib/Analytics';
import { toKebabCase } from 'src/lib/Utils/helpers';
import { defaultURL } from '../_api/signIn';
import { useSignInContent, useSignInMachine } from '../index';

export const useErrorAnalytics = (errorCode?: string) => {
  const { content, getError } = useSignInContent();
  const { state } = useSignInMachine();
  // error analytics
  useEffect(() => {
    if (errorCode) {
      let { subheadline, analyticsVariant } = content;
      let errorData = getError(errorCode);
      let text = '';
      let vpvSubstring = '';

      const formatText = (s?: string) => {
        // get text before first period, then using regex to remove anything between < and > (html tags)
        return toKebabCase(s?.split('.')[0] || 'na').replace(/<.*>/, '');
      };

      if (state.matches('errorPage')) {
        errorData = errorData || getError('E');
        text = formatText(errorData?.Title?.value);
        if (analyticsVariant !== 'drawer') {
          subheadline = 'na';
        }
        vpvSubstring = `${subheadline}/na/${analyticsVariant}/${text}`;
      } else {
        // inline type error
        text = formatText(errorData?.Message?.value);
        vpvSubstring = `${text}/na/${errorCode}/${analyticsVariant}`;
      }

      track.component({
        category: 'sign-in_error',
        action: `${errorCode}-|-${text}-|-${defaultURL}`,
        label: 'sign-in',
        event: 'event-click',
      });
      track({
        event: 'send-VPV',
        'vpv-name': `/vpv/de/page/sign-in/error/sign-in/${vpvSubstring}`,
      });
    }
  }, [errorCode]);

  // page load analytics
  useEffect(() => {
    if (content.analyticsVariant !== 'drawer') {
      track({
        event: 'send-VPV',
        'vpv-name': `/vpv/de/page/sign-in/load/sign-in/${content.subheadline}/na/${content.analyticsVariant}`,
      });
    }
  }, []);
};
