import { compositionFunction as CallToActionComposition } from 'src/components/Common/@electron/CallToAction/composition';
import Composition, { sitecoreMap } from 'src/lib/HOC/Composition';
import { getModal } from 'src/lib/Utils/helpers';
import NavCard from './index';

const { compositionFunction, component } = Composition(NavCard)(({ fields, params, rendering }) => {
  const variant = sitecoreMap[params?.['Render Selector']] || 'rectangular';
  const category = `nav-card_${variant}`;

  // TODO: DNT-1677 Sitecore sends the Background as a param, it would be better if
  // fields.Background.fields.Setting.value already existed
  const color = sitecoreMap[params?.['Background Color']];
  const Background = { fields: { Setting: { value: color } } };

  // CTA for navcards needs the category derived from the variant, rather than default componentName
  const compRendering = { ...rendering, componentName: category };
  const CTA = CallToActionComposition({
    fields: { ...fields, Background },
    rendering: compRendering,
  });

  // field.items will be undefined if no navcards have been created yet in Sitecore
  const items =
    fields?.items &&
    fields.items.reduce((acc: Parameters<typeof NavCard>[0]['items'], curr: typeof fields) => {
      // Only include link with truthy href, if href is falsey (eg an empty string) we want to leave it undefined
      const link = curr.fields?.['Pod Item Link']?.value?.href && curr.fields['Pod Item Link'];
      const mobileLink = curr.fields?.['Mobile Pod Item Link'];
      const cta = {
        href: link?.value?.href,
        text: curr.fields?.['CTA Text'],
      };
      const title = curr.fields?.Headline || { value: link?.value?.text };

      return [
        ...acc,
        {
          analytics: {
            category,
            label: CTA?.title?.value,
            action: [title?.value, cta?.href].filter(Boolean).join(' | '),
            guid: curr.id || '',
            event: 'event-click',
          },
          cta,
          image: curr.fields?.Image,
          text: curr.fields?.Text,
          title,
          link,
          mobileLink: mobileLink?.value?.href ? mobileLink : link,
          modal: getModal(curr.fields),
        },
      ];
    }, []);

  return {
    ...CTA,
    items: fields?.items ? items : [],
    ordered: sitecoreMap[params?.['Ordering Type']] === 'ordered',
    variant,
  };
});

export { compositionFunction };
export default component;
