import Composition from 'src/lib/HOC/Composition';
import HeroSmall from './index';

const { compositionFunction, component } = Composition(HeroSmall)(({ fields }) => {
  const subFields = fields?.Slides[0].fields;
  const guid = fields?.Slides[0].id;

  const ctaLink = subFields.CTA;
  const headline = subFields.Headline;

  return {
    analytics: {
      category: 'hero_small',
      label: headline?.value || '',
      action: [ctaLink?.value?.text, ctaLink?.value?.href].filter(Boolean).join(' | '),
      guid,
      event: 'event-click',
    },
    desktopImage: subFields['Desktop Image'],
    mobileImage: subFields['Mobile Image'],
    headline,
    subhead: subFields.Subhead,
    mobileHeadline: subFields['Mobile Headline'],
    ctaLink,
    isButton: subFields['CTA as Button'].value,
  };
});

export { compositionFunction, component as default };
