import React from 'react';
import { LoadingOverlayTypes } from './types';

const LoadingDots = (props: { className?: string }) => (
  <svg height="24" width="128" {...props}>
    {['text-blue', 'text-teal-dark', 'text-green'].map((color, i) => (
      <circle
        key={i}
        r="12"
        cx="12"
        cy="12"
        fill="currentColor"
        className={`animate-dotSlide ${color}`}
        style={{ transformOrigin: '0 12px', animationDelay: `-${i * 0.4}s` }}
      />
    ))}
  </svg>
);

const LoadingOverlay = ({
  isLoading,
  noDelay,
  overlayColor,
  overlayText = 'Your page is loading',
}: LoadingOverlayTypes) => {
  return (
    <section
      className={`fixed top-0 left-0 h-screen w-screen flex justify-center items-center p-12 md:p-16 z-modal ${
        isLoading ? 'delay-3000 visible' : noDelay ? 'visible' : 'invisible'
      }`}
      role="status"
      aria-live="polite"
      aria-busy={isLoading}
      aria-label={overlayText}
    >
      <div
        className={`absolute inset-0 flex justify-center items-center transition-all duration-150 ease-in bg-opacity-60 ${
          overlayColor === 'black' ? 'bg-black' : 'bg-white'
        }`}
        aria-hidden="true"
      >
        <div className="py-24 px-32 md:py-32 md:px-48 bg-white inline-flex flex-col relative filter shadow-lg">
          <LoadingDots />
        </div>
      </div>
    </section>
  );
};

export { LoadingDots };
export default LoadingOverlay;
