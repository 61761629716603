import { BackButton, Header, headlineId } from 'src/components/Common/Jurisdiction';
import { useStep } from 'src/components/Common/Jurisdiction/context';
import { JurisdictionProps, StepProps } from 'src/components/Common/Jurisdiction/types';
import LazyImage from 'src/components/Common/LazyImage';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';

const Map = ({
  hasZip = true,
  useZipText = 'Use my ZIP code to set my location',
  backToStatesText,
  headline,
  id,
  ...rest
}: StepProps & { hasZip?: boolean; useZipText?: string }) => {
  const { set, whichState } = useStep();

  const handleClick = ({ code, name }: JurisdictionProps['states'][0]) => {
    if (whichState) {
      const analyticsCategory = name.split(' ').pop();
      const analyticsLabel = headline?.value?.replace(/\W[{0}]\W/, whichState?.name || '');

      set('Loading', { code, name: `${whichState.name} - ${name}` });

      track.component({
        action: 'map',
        category: `jurisdiction_change-${analyticsCategory}`,
        guid: id,
        label: analyticsLabel,
      });
    }
  };

  return (
    <>
      {hasZip && <BackButton onClick={() => set('List')}>{backToStatesText}</BackButton>}
      <Header {...{ headline, ...rest }} />
      {Array.isArray(whichState?.code) && (
        <div className="pt-16 text-teal-darker">
          <ul aria-labelledby={headlineId}>
            {whichState?.code.map(({ code, name, image }, index) => (
              <li key={index} className="px-16 mb-16 group">
                <button className="w-full" onClick={() => handleClick({ code, name })}>
                  <LazyImage
                    {...image}
                    alt=""
                    className="w-full bg-teal-light bg-opacity-20 group-hover:bg-opacity-100"
                  />
                  <div className="pt-8 text-left">
                    <span className="pb-2 border-b border-white group-hover:border-current">
                      {name}
                    </span>
                    <SvgLoader
                      name="Arrow"
                      className="inline ml-8"
                      size={12}
                      aria-hidden={true}
                      focusable={false}
                    />
                  </div>
                </button>
              </li>
            ))}
          </ul>
          {hasZip && (
            <button onClick={() => set('Zip')} className="pb-8 mt-8 group">
              <span className="relative flex items-center justify-start w-auto pb-2 mx-16 transition-all duration-150 border-b border-current group-hover:pb-px group-hover:border-white">
                {useZipText}
              </span>
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Map;
