import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from 'react';
import Button from 'src/components/Common/@electron/Button';
import { useBodyContext } from 'src/components/Common/ContentWrapper/context';
import LazyImage from 'src/components/Common/LazyImage';
import Link from 'src/components/Common/Link';
import RichTextRoutable from 'src/components/Common/RichText_Old';
import track from 'src/lib/Analytics';
import { pxPush } from 'src/lib/Analytics/px';
import { useExperienceEditor } from 'src/lib/Hooks/useExperienceEditor';
import useMediaQuery from 'src/lib/Hooks/useMediaQuery';
import Logger from 'src/lib/Utils/Logger';
import { toSnakeCase } from 'src/lib/Utils/helpers';
import { CECAuthDataType, CECErrorType, getCECAuthToken, sendCECErrorAnalytics } from '../cecAuth';
import { CallToActionProps, FooterProps, HeaderProps } from './types';

const Header = ({ image, title, subtitle }: HeaderProps) => (
  <header className="mx-auto max-w-xs text-center mb-16 md:mb-24 md:max-w-sm lg:max-w-md">
    {image?.value && <LazyImage className="mx-auto mb-12 icon-48 md:icon-76" {...image} />}
    {title && <RichText className="text-2xl mb-12" tag="h2" field={title} />}
    {subtitle && <RichTextRoutable className="js-subtitle text-lg" field={subtitle} />}
  </header>
);

const Footer = ({
  analytics,
  onClick,
  ctaText,
  isCtaAButton = true,
  link,
  mobileLink = link,
  modal,
  isCecCTA,
  cecData,
  cecErrorMessage,
}: FooterProps) => {
  const { dispatch } = useBodyContext();
  const isMobile = !useMediaQuery('md');
  const CtaWrapper = (isCtaAButton ? Button : Link) as React.ElementType;
  const compLink = isMobile ? mobileLink : link;

  const handleClick = (event: React.MouseEvent) => {
    if (modal?.id) {
      event.preventDefault();
      dispatch({ type: 'modalOpen', payload: { id: modal.id } });
    }

    const { text, href } = compLink!.value;

    analytics &&
      track.component({
        ...analytics,
        category: toSnakeCase(analytics?.category?.toLowerCase()),
        action: [text, href].filter(Boolean).join(' | '),
      });
    pxPush({
      eventName: analytics?.category || 'call-to-action',
      clickText: text,
    });

    if (isCecCTA && cecErrorMessage) {
      sendCECErrorAnalytics(cecErrorMessage);
    }
    onClick?.();
  };

  return (
    <footer className="flex flex-col items-center mx-auto max-w-xs md:max-w-sm lg:max-w-md">
      {compLink && (
        <CtaWrapper
          {...compLink}
          value={{
            ...compLink.value,
            href:
              isCecCTA && cecData?.access_token
                ? `${compLink.value.href}&auth_token=${cecData?.access_token}&cecAuth=true`
                : compLink.value.href,
            target: isCecCTA ? '_self' : compLink.value.target,
          }}
          className={`${isCtaAButton ? '' : 'text-teal-darker underline hover:no-underline'} mb-16`}
          onClick={handleClick}
        />
      )}
      {ctaText && <RichTextRoutable className="text-md text-center" tag="div" field={ctaText} />}
    </footer>
  );
};

const CallToAction = ({
  backgroundColor,
  children,
  ctaText,
  fullWidth,
  link,
  subtitle,
  title,
  onClick,
  isCecCTA,
  ...rest
}: React.PropsWithChildren<CallToActionProps>) => {
  const { isEEActive } = useExperienceEditor();
  const bgColorClass = backgroundColor?.toLowerCase() === 'gray' ? 'bg-gray-lighter' : '';
  const hasHeader = () => isEEActive || Boolean(title?.value || subtitle?.value);
  const hasFooter = () => isEEActive || Boolean(link?.value || ctaText?.value);
  const [cecData, setCecData] = useState<CECAuthDataType>();
  const [cecErrorMessage, setCecErrorMessage] = useState('');

  useEffect(() => {
    if (isCecCTA) {
      try {
        const getCecToken = async () => {
          // hardcoding segment id of 2 now as that is the only one supported. This can be updated when more
          // segment id's become viable
          const cecAuthData = await getCECAuthToken(2);
          if (!cecErrorMessage && (cecAuthData as CECErrorType).Status === 'Error') {
            setCecErrorMessage((cecAuthData as CECErrorType).MessageText);
          } else {
            setCecData(cecAuthData as CECAuthDataType);
          }
        };
        getCecToken();
      } catch (ex) {
        const message = 'CEC SSO Error';
        setCecErrorMessage(message);
        Logger(ex, { message });
      }
    }
  }, []);

  return (
    <section
      className={`flex flex-col py-32 xl:py-48 ${bgColorClass} ${
        fullWidth ? '' : 'px-16 xl:px-24'
      }`}
    >
      {hasHeader() && <Header {...{ subtitle, title, ...rest }} />}
      {children && <div className="mb-16 md:mb-24">{children}</div>}
      {hasFooter() && (
        <Footer {...{ ctaText, link, onClick, isCecCTA, cecData, cecErrorMessage, ...rest }} />
      )}
    </section>
  );
};

export { Footer, Header };
export default CallToAction;
