import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgFuelOil = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="m43.284,25.916h-22.573c-.923,0-1.762-.755-1.762-1.678v-.084c0-.923.755-1.762,1.678-1.762h22.657c.923,0,1.762.755,1.762,1.678v.084c0,.923-.755,1.762-1.678,1.762,0,0-.084,0-.084,0Zm0,15.441h-22.573c-.923,0-1.762-.755-1.762-1.678v-.084c0-.923.755-1.762,1.678-1.762h22.657c.923,0,1.762.755,1.762,1.678v.084c.084.923-.671,1.678-1.594,1.762,0,0-.168,0-.168,0Zm-22.322-14.685v10.406h22.154v-10.406h-22.154Zm8.979,5.455c.336-.839,1.091-2.182,2.098-4.112,1.007,1.93,1.762,3.357,2.098,4.112.671,1.175.252,2.601-.923,3.273-1.175.671-2.601.252-3.273-.923-.42-.755-.42-1.594,0-2.35Zm11.329-18.126h-18.545c-1.007,0-1.846.839-1.846,1.846v5.79h22.154v-5.79c.084-.923-.587-1.762-1.51-1.846,0,0-.252,0-.252,0Zm-20.308,28.196v5.958c0,1.007.839,1.846,1.846,1.846h18.545c1.007,0,1.846-.839,1.846-1.846v-5.874h-22.07q-.084-.084-.168-.084Z"
    />
  </svg>
);

export default SvgFuelOil;
