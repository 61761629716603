import React from 'react';
import Logger from 'src/lib/Utils/Logger';

/** render via render props or static fallback */
type RenderStrategy = { render: (error: Error) => JSX.Element } | { fallback: JSX.Element };

type Props = { onCatch?: (error: Error) => void; children: React.ReactNode } & RenderStrategy;

interface State {
  error: Error | null;
}

class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error) {
    if (this.props.onCatch) {
      this.props.onCatch(error);
    } else {
      // the onCatch callback is generally for logging at the error site
      // so we only invoke this built in logging if theres no onCatch
      Logger(error);
    }
  }

  render() {
    if (this.state.error) {
      // pass the error back to the fallback component for it to display
      if ('render' in this.props) {
        return this.props.render(this.state.error);
      }
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
