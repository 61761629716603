interface ButtonGroupProps {
  primary?: JSX.Element;
  secondary?: JSX.Element;
  tertiary?: JSX.Element;
  helperText?: JSX.Element;
  screenReaderText?: string;
}

export const ButtonGroup = ({
  primary,
  secondary,
  tertiary,
  helperText,
  screenReaderText,
}: ButtonGroupProps) => {
  return (
    <div
      className="w-full max-w-xs mx-auto"
      {...(screenReaderText && { 'aria-labelledby': 'button-group' })}
    >
      {screenReaderText && (
        <div className="sr-only" id="button-group">
          {screenReaderText}
        </div>
      )}
      <div className="flex flex-row-reverse justify-center gap-12">
        {primary && <div className="w-full max-w-1/2">{primary}</div>}
        {secondary && <div className="w-full max-w-1/2">{secondary}</div>}
      </div>
      {helperText && <div className="mt-16 -mb-12 text-center">{helperText}</div>}
      {tertiary && <div className="flex justify-center mt-12">{tertiary}</div>}
    </div>
  );
};
