import React, { forwardRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isInternal } from 'src/components/Common/Link';
import { formatModalId } from 'src/lib/Utils/helpers/index';

// Reference https://github.com/Sitecore/jss/blob/master/packages/sitecore-jss-react/src/components/RichText.tsx

const defaultElement = 'div';

// Polymorphic component with fowardRef,
// https://gist.github.com/kripod/4434e7cecfdecee160026aee49ea6ee8

type OwnProps<T extends React.ElementType = React.ElementType> = {
  /** classNames joined with the "rich text" class */
  className?: string;
  /** html string */
  value?: string;
  /** html tag of the element, default is "div" */
  tag?: T;
  /** callback function that applies the onClick event if the target is an anchor tag */
  onAnchorClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onModalTriggerClick?: (modalInfo: { id: string }) => void;
};

type RichTextTag = keyof JSX.IntrinsicElements;

type PropsOf<T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>> =
  JSX.LibraryManagedAttributes<T, React.ComponentPropsWithRef<T>>;

type Props<T extends RichTextTag> = OwnProps<T> & Omit<PropsOf<T>, keyof OwnProps>;

/** Renders a string of html as html */
const RichText = forwardRef<React.Ref<Element>, OwnProps>(function RichTextComponent(props, ref) {
  const history = useHistory();

  const {
    value,
    className = '',
    tag: Tag = defaultElement,
    onAnchorClick,
    onModalTriggerClick,
    ...rest
  } = props;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if ('onClick' in rest && typeof rest.onClick === 'function') {
      rest.onClick(event);
    }
    // If it's not an Element or an Anchor, do less 😤
    if (
      // Not an Element, return.
      !(event.target instanceof Element) ||
      // It is an Element, so is it (or its parent*) an anchor tag? If neither, return.
      // *We need to check the parentElement to catch scenarios like <a href="foo"><span>text</span></a>
      // where the event.target is clocked as a span.
      [
        event.target.tagName.toLowerCase() !== 'a',
        event.target.parentElement?.tagName.toLowerCase() !== 'a',
      ].every(Boolean)
    ) {
      return;
    }

    // We checked that either target or parent is an achor tag above
    // so now we can just get whichever one it is
    const anchorTag = event.target.closest('a');

    // Content-authors can trigger a modal from an <a> tag in the rich text html,
    // The tag should have data-toggle and data-target attributes
    const modalId = anchorTag?.attributes.getNamedItem('data-target')?.nodeValue;
    const isModalTrigger = anchorTag?.attributes.getNamedItem('data-toggle')?.nodeValue === 'modal';

    if (onModalTriggerClick && isModalTrigger && modalId) {
      event.preventDefault();
      onModalTriggerClick({ id: formatModalId(modalId) });
    }

    const href = anchorTag?.attributes.getNamedItem('href')?.nodeValue;

    if (href && onAnchorClick) {
      onAnchorClick(event);
    }

    // If it's an internal link, use react-router
    if (isInternal(href)) {
      event.preventDefault();
      history.push(href);
    }
  }, []);

  const html = value;

  if (!html) return null;

  // Watch out for invalid html eg <RichText tag="p" field={{ value: '<div>hello</div>' }} />
  // This is NOT valid and in SSR when the server sends that invalid html the browser won't like it
  // and it will NOT reliably render.
  // However, since it is valid for div tags to hold any other tag, we can do a broad check for ANY wrapping element and if
  // true, swap out the specified tag with the default div. Not perfect, but it exists so that we can still write semantic
  // html, but not get fricked over if a content author sends rich text structure that conflicts with the tag defined in the app.
  const SafeTag = html?.charAt(0) === '<' ? defaultElement : Tag;
  return (
    <SafeTag
      {...{ ...rest, className: `rich-text ${className}` }}
      data-testid="jss-rich-text"
      dangerouslySetInnerHTML={{ __html: html }}
      ref={ref}
      onClick={handleClick}
    />
  );
}) as <T extends RichTextTag = typeof defaultElement>(props: Props<T>) => JSX.Element;

export { RichText };
