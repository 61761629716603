import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useStep } from 'src/components/Common/Jurisdiction/context';
import { StepProps } from 'src/components/Common/Jurisdiction/types';
import RichText from 'src/components/Common/RichText_Old';
import SvgLoader from 'src/components/Common/SvgLoader';
import ErrorView from './ErrorView';
import Intro from './Intro';
import List from './List';
import Loading from './Loading';
import Map from './Map';
import Zip from './Zip';

const headlineId = 'jurisdiction-intercept-headline';
const descriptionId = 'jurisdiction-intercept-description';

const Header = ({
  headline,
  subhead,
  body,
  stateName,
}: Omit<StepProps, 'id' | 'states'> & { stateName?: string }) => {
  const { whichState, whichStep } = useStep();
  const replaceWith = stateName || whichState?.name || '';

  return (
    <div className="flex flex-col gap-16 md:gap-24">
      {headline && (
        <Text
          className="text-2xl text-gray-darker"
          field={{
            ...headline,
            value: headline.value?.replace(/\W[{0}]\W/, replaceWith),
          }}
          tag="h2"
          id={headlineId}
        />
      )}
      {subhead && (
        <RichText className="text-gray-dark" field={subhead} tag="p" id={descriptionId} />
      )}
      {whichStep === 'Zip' ? (
        <RichText className="text-blue text-xl" tag="h3" field={body} />
      ) : (
        <RichText className="text-gray-darker container-sm" tag="p" field={body} />
      )}
    </div>
  );
};

const BackButton = ({
  onClick,
  children = 'Back',
  className,
}: React.PropsWithChildren<{ onClick: () => void; className?: string }>) => (
  <div className={`pb-16 font-bold text-left capitalize ${className}`}>
    <button onClick={onClick} className="js-back flex items-center text-teal-darker">
      <SvgLoader
        name="Arrow"
        className="inline mr-8"
        size={12}
        rotate={180}
        aria-hidden="true"
        focusable={false}
      />
      <span className="border-b border-white hover:border-current">{children}</span>
    </button>
  </div>
);

export { BackButton, ErrorView, Header, Intro, List, Loading, Map, Zip, descriptionId, headlineId };
