/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
import { assign, createMachine } from 'xstate';
import { Status } from './index';

export interface Context {
  verifyEmail: { emailAddress: string };
  userCredentials: {
    loginIdentity: string;
    password: string;
  };
  successfulResponse?: Status;
  guid: string;
  errorCode: string;
}

export const signInMachine = createMachine(
  {
    id: 'signIn',
    tsTypes: {} as import('./machine.typegen').Typegen0,
    schema: {
      events: {} as { type: 'SIGNIN' | 'SENDVERIFICATION'; data: any },
      context: {} as Context,
      services: {} as { submitForm: { data: Status }; submitEmailVerification: { data: Status } },
    },
    predictableActionArguments: true,
    context: {
      verifyEmail: { emailAddress: '' },
      userCredentials: { loginIdentity: '', password: '' },
      successfulResponse: undefined,
      guid: '',
      errorCode: '',
    },
    initial: 'init',
    states: {
      init: {
        always: [{ target: 'error', cond: 'hasReturnCodeQueryParam' }, { target: 'signIn' }],
      },
      signIn: {
        on: {
          SIGNIN: {
            target: 'pending',
            actions: ['saveUserCredentials'],
          },
        },
      },
      pending: {
        entry: ['clearError'],
        invoke: {
          src: 'submitForm',
          onDone: {
            target: 'resolved',
            actions: ['saveResponse'],
          },
          onError: {
            target: 'error',
            actions: ['saveError'],
          },
        },
      },
      pendingVerify: {
        tags: ['verifyForm'],
        invoke: {
          src: 'submitEmailVerification',
          onDone: {
            target: 'resolved',
            actions: ['clearError', 'saveResponse'],
          },
          onError: {
            target: 'error',
            actions: ['saveError'],
          },
        },
      },
      resolved: {
        always: [
          { target: 'success', cond: 'isSuccess', actions: ['clearPreviousAnswers'] },
          { target: 'error' },
        ],
      },
      success: {
        entry: ['onSuccess'],
      },
      error: {
        always: [
          { target: 'signIn', cond: 'isInlineError' },
          { target: 'emailVerificationPage', cond: 'hasReturnCodeEndpoint' },
          { target: 'errorPage' },
        ],
      },
      errorPage: {},
      emailVerificationPage: {
        tags: ['verifyForm'],
        on: {
          SENDVERIFICATION: {
            target: 'pendingVerify',
            actions: ['saveVerification'],
          },
        },
      },
    },
  },
  {
    actions: {
      saveVerification: assign({
        verifyEmail: (context, { data }) => data,
      }),
      saveUserCredentials: assign({
        userCredentials: (context, { data }) => data,
      }),
      saveResponse: assign((context, { data }) => {
        return { successfulResponse: data };
      }),
      saveError: assign((context: any, event: any) => {
        const message = event.data?.message;
        const errorCode = message || 'E';

        return { errorCode };
      }),
      clearError: assign((context: any, event: any) => {
        return { errorCode: '' };
      }),
      clearPreviousAnswers: assign((context: any, event: any) => ({
        userCredentials: { loginIdentity: '', password: '' },
      })),
    },
  }
);
