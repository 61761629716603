import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPhoneSolidDualTone = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 40 40"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx="20" cy="20.4004" r="20" fill="#E0F6FB" />
    <path
      d="M19.2706 15.727C20.0975 15.7113 20.755 15.0282 20.7394 14.202L20.6594 10.0651C20.6437 9.23822 19.9606 8.58072 19.1344 8.59635H18.7287L18.8719 15.7276H19.2719L19.2706 15.727ZM17.825 21.8182C15.0937 16.2413 17.825 15.6813 17.825 15.6813L17.7281 8.55572C17.7281 8.55572 8.16248 8.14447 14.2712 23.727C21.2881 36.8238 27.7056 30.9157 27.7056 30.9157L22.46 26.087C22.46 26.087 20.1562 27.567 17.825 21.8182ZM25.4881 24.8982C24.8844 24.3332 23.9369 24.3645 23.3719 24.9682C23.3706 24.9695 23.3694 24.9707 23.3681 24.9726L23.0994 25.2695L28.345 30.1438L28.6137 29.847C29.1787 29.2432 29.1475 28.2957 28.5437 27.7307C28.5425 27.7295 28.5412 27.7282 28.5394 27.727L25.4881 24.8982Z"
      fill="#00789E"
    />
  </svg>
);

export default SvgPhoneSolidDualTone;
