import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgHashSolidDualTone = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 40 40"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx="20" cy="20.4004" r="20" fill="#E0F6FB" />
    <path
      d="M27.9265 18.8396C28.4221 18.8396 28.8234 18.4377 28.8234 17.9427V16.3084C28.8234 15.8127 28.4215 15.4115 27.9265 15.4115H26.4177L27.3146 11.0859C27.4159 10.6009 27.104 10.1259 26.619 10.0246C26.5584 10.0121 26.4965 10.0059 26.4346 10.0059H24.8059C24.3802 10.0052 24.0127 10.3034 23.9259 10.7202L22.949 15.4115H19.1777L20.0809 11.0915C20.1821 10.6065 19.8702 10.1315 19.3852 10.0302C19.3246 10.0177 19.2627 10.0115 19.2009 10.0115H17.5721C17.1465 10.0109 16.779 10.309 16.6921 10.7259L15.709 15.4171H12.0746C11.579 15.4171 11.1777 15.819 11.1777 16.314V17.9427C11.1777 18.4384 11.5796 18.8396 12.0746 18.8396H14.989L14.3259 21.994H12.0746C11.579 21.994 11.1777 22.3959 11.1777 22.8909V24.5309C11.1777 25.0265 11.5796 25.4277 12.0746 25.4277H13.6002L12.7034 29.7077C12.599 30.1921 12.9077 30.669 13.3921 30.7734C13.4552 30.7871 13.519 30.7934 13.5834 30.7934H15.2402C15.6659 30.794 16.0334 30.4959 16.1202 30.079L17.0915 25.4277H20.8571L19.9659 29.7077C19.8615 30.1921 20.1702 30.669 20.6546 30.7734C20.7177 30.7871 20.7815 30.7934 20.8459 30.7934H22.5027C22.9284 30.794 23.2959 30.4959 23.3827 30.079L24.3484 25.4334H27.9371C28.4327 25.4334 28.834 25.0315 28.834 24.5365V22.8909C28.834 22.3952 28.4321 21.994 27.9371 21.994H25.0802L25.7146 18.8396H27.9265ZM17.7896 21.9652L18.4584 18.8396H22.2296L21.5784 21.9652H17.7896Z"
      fill="#00789E"
    />
  </svg>
);

export default SvgHashSolidDualTone;
