import { Text } from '@sitecore-jss/sitecore-jss-react';
import { PrimayNavProps } from 'src/components/Common/@electron/PrimaryNav/types';
import { useNavContext } from 'src/components/Common/NavWrapper/context';
import { AuthTriggerButton } from './AuthTriggerButton';
import { SmallButton } from './SmallButton';

type AuthButtonProps = Pick<PrimayNavProps, 'profileLinks' | 'variant' | 'guid'> & {
  text: JSS.TextField;
};

/** Button displayed when user is signed-in */
const AuthButton = ({ text, variant }: AuthButtonProps) => {
  const { dispatch } = useNavContext();

  const onClick = () => {
    dispatch('open', { panel: 'right', component: 'SignOut' });
  };

  if (variant === 'small') {
    return <SmallButton onClick={onClick} text={text} />;
  }

  return (
    <li className="relative">
      <AuthTriggerButton onClick={onClick}>
        <Text field={text} />
      </AuthTriggerButton>
    </li>
  );
};

export { AuthButton };
