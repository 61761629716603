import { BackButton, Header, Item, headlineId } from 'src/components/Common/Jurisdiction';
import { useStep } from 'src/components/Common/Jurisdiction/context';
import { StepProps } from 'src/components/Common/Jurisdiction/types';
import { useNavContext } from 'src/components/Common/NavWrapper/context';
import SvgLoader from 'src/components/Common/SvgLoader';

const List = ({ states, ...rest }: StepProps) => {
  const NavContext = useNavContext();
  const { set } = useStep();

  return (
    <>
      {NavContext.state.panel === 'left' && (
        <BackButton onClick={() => NavContext.dispatch('prev')}>Back</BackButton>
      )}
      <Header {...rest} />
      <ul aria-labelledby={headlineId} className="pt-24">
        {states.map((state, index) => (
          <li key={index}>
            <button
              className="flex w-full text-teal-darker hover:bg-teal-light"
              onClick={() => {
                set(Array.isArray(state.code) ? 'Map' : 'Loading', state);
              }}
            >
              <Item className="justify-between">
                <span>
                  <SvgLoader
                    name={state.name.split(' ').join('')}
                    className="inline mr-8"
                    size={14}
                    focusable="false"
                    aria-hidden="true"
                  />
                  {state.name}
                </span>
                <SvgLoader name="Arrow" className="inline mr-8" size={12} />
              </Item>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default List;
