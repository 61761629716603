import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgNoTransmissionLine = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m47.5,23.483v3.679c0,.753.611,1.362,1.363,1.362.752,0,1.361-.61,1.362-1.362v-4.152c-.005-.471-.257-.904-.664-1.141v-.149l-2.061,1.762Zm-9.25,10.262l-6.241,8.721-2.562-3.578-1.486,1.263,2.85,3.982-10.446,14.592,3.068-14.711-2.409,2.055-3.496,16.761c-.108.526.231,1.039.757,1.147.375.077.759-.073.983-.383l12.74-17.795,12.739,17.795c.183.255.477.406.791.406.134,0,.266-.028.388-.082.42-.184.654-.637.56-1.086l-6.092-29.176-.446-3.731-2.701,2.305,1.003,1.516Zm5.395,24.977l-10.447-14.59,5.727-8,4.721,22.59Zm-11.636-34.457l1.278,1.942,1.486-1.269-1.605-2.437,3.355-5.095.646,5.487,1.774-1.514-.226-1.921,2.432.04,2.113-1.802h-4.731l-.392-3.162h0l-1.04-8.816,8.728-3.287v1.575c-.407.237-.659.67-.664,1.141v4.152c.025.752.655,1.342,1.407,1.317.717-.024,1.293-.6,1.317-1.317v-4.152c-.005-.471-.257-.904-.664-1.141V1.881c.455-.156.74-.607.685-1.085C47.825.335,47.423-.001,46.953,0h-29.895c-.536,0-.97.435-.97.971,0,.404.25.765.628.907l.07.027v2.101c-.407.237-.659.67-.664,1.141v4.152c.025.752.655,1.342,1.407,1.317.717-.024,1.293-.6,1.317-1.317v-4.152c-.005-.471-.257-.904-.664-1.141v-1.575l8.731,3.285-1.04,8.813v.012l-.369,3.144h-10.583c-.469,0-.871.335-.954.796-.072.411.129.821.498,1.015v2.369c-.412.236-.667.673-.672,1.147v4.152c.025.752.655,1.342,1.407,1.317.717-.024,1.293-.6,1.317-1.317v-4.153c-.005-.467-.254-.897-.655-1.135v-1.828l8.969,3.376-1.189,10.146-.221,1.056,4.033-3.44,4.556-6.921ZM41.628,1.942l-4.755,1.783-.211-1.783h4.966Zm-16.564,19.495l-4.811-1.811h5.024l-.212,1.811Zm10.699-10.575l-2.571-3.745,1.819-2.648.752,6.392Zm-1.372-8.916l-2.382,3.456-2.377-3.461,4.759.004Zm-5.37,2.526l1.819,2.649-2.571,3.745.752-6.394Zm2.988,4.36l4,5.828-4,6.076-4-6.076,4-5.828ZM22.391,1.942h4.967l-.211,1.783-4.756-1.783Zm5.101,15.462l3.355,5.097-4.816,7.315,1.461-12.412ZM62.193,6.248c-.616-.722-1.701-.808-2.424-.192L2.018,55.33c-.744.589-.87,1.671-.281,2.415.589.744,1.671.87,2.415.281.033-.026.064-.053.095-.081L62.001,8.672c.722-.616.808-1.701.192-2.424Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgNoTransmissionLine;
