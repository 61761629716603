import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgList = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m22.93,31.538c0-.973.789-1.762,1.762-1.762h21.547c.973,0,1.762.789,1.762,1.762v.924c0,.973-.789,1.762-1.762,1.762h-21.547c-.973,0-1.762-.789-1.762-1.762v-.924Zm0-9.202c0,.973.789,1.762,1.762,1.762h21.547c.973,0,1.762-.789,1.762-1.762v-.924c0-.973-.789-1.762-1.762-1.762h-21.547c-.973,0-1.762.789-1.762,1.762v.924Zm0,20.253c0,.973.789,1.762,1.762,1.762h21.547c.973,0,1.762-.789,1.762-1.762v-.924c0-.973-.789-1.762-1.762-1.762h-21.547c-.973,0-1.762.789-1.762,1.762v.924Zm-6.93-10.126c0,.973.789,1.762,1.762,1.762h.836c.973,0,1.762-.789,1.762-1.762v-.924c0-.973-.789-1.762-1.762-1.762h-.836c-.973,0-1.762.789-1.762,1.762v.924Zm0-10.126c0,.973.789,1.762,1.762,1.762h.836c.973,0,1.762-.789,1.762-1.762v-.924c0-.973-.789-1.762-1.762-1.762h-.836c-.973,0-1.762.789-1.762,1.762v.924Zm0,20.253c0,.973.789,1.762,1.762,1.762h.836c.973,0,1.762-.789,1.762-1.762v-.924c0-.973-.789-1.762-1.762-1.762h-.836c-.973,0-1.762.789-1.762,1.762v.924Z"
    />
  </svg>
);

export default SvgList;
