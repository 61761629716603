import React, { useEffect } from 'react';
import { BackButton, Header } from 'src/components/Common/Jurisdiction';
import { useStep } from 'src/components/Common/Jurisdiction/context';
import { StepProps } from 'src/components/Common/Jurisdiction/types';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';

const ErrorView = ({
  children,
  id,
  backToStatesText,
  ...rest
}: React.PropsWithChildren<StepProps>) => {
  const { set, whichState } = useStep();

  useEffect(() => {
    track.component({
      action: whichState?.name || '',
      category: 'jurisdiction_change_error',
      guid: id,
      label: 'could-not-set-location',
    });
  }, []);

  return (
    <>
      <BackButton onClick={() => set('List')}>{backToStatesText}</BackButton>
      <div className="flex flex-col items-center px-16 py-24 mb-16 text-center bg-gray-lighter">
        <SvgLoader
          name="AlertSolid"
          color="text-current"
          className="text-gray-darker"
          size={24}
          aria-hidden={true}
          focusable={false}
        />
        <Header {...rest} />
      </div>
      {children}
    </>
  );
};

export default ErrorView;
