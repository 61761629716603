import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useSitecoreContext } from 'src/lib/Hooks/SitecoreContextFactory';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import GoogleTagManager from 'src/lib/Utils/GoogleTagManager';
import { getSectionSegment } from 'src/lib/Utils/helpers';
import Logger from 'src/lib/Utils/Logger';
import {
  AcousticEmailScriptBusiness,
  AcousticEmailScriptResidential,
  QualtricsScript,
} from './scripts';
import { SiteSettingsProps } from './types';

export const blockCookieCategoryClass = 'optanon-category-C0000-C0004';

const useScriptSetting = (fields: SiteSettingsProps['fields']) => {
  const {
    context: { route },
  } = useSitecoreContext();
  const keys: (keyof SiteSettingsProps['fields'])[] = [
    'GTM Script Enable',
    'Tealeaf Script Enable',
    'Qualtrics Script Enable',
    'Acoustic Email Campaign Tracking Enable',
    'Global Injection Enable',
  ];

  return keys.map(key => {
    // "global" = sitewide setting, turn off/on script everywhere
    const isEnabledGlobal = route?.fields?.[key]?.value;
    // "local" = page specific setting, so some pages can opt out of including script
    const isEnabledLocal = fields?.[key]?.value;
    // Must have both global and local settings true
    const isEnabled = Boolean(isEnabledGlobal && isEnabledLocal);

    return isEnabled;
  }) as [
    gtmScriptEnable: boolean,
    tealeafScriptEnable: boolean,
    qualtricsScriptEnable: boolean,
    acousticEmailScriptEnable: boolean,
    globalInjectionEnable: boolean
  ];
};

export const createScript = (element: HTMLElement) => {
  const tag = document.createElement('script');
  const scriptText = document.createTextNode(element.innerHTML);
  tag.appendChild(scriptText);

  for (const attr of [...element.attributes]) {
    tag.setAttribute(attr.name, attr.value);
  }

  return tag;
};

export const createStyle = (element: HTMLElement) => {
  const tag = document.createElement('style');
  tag.innerHTML = element.innerHTML;

  for (const attr of [...element.attributes]) {
    tag.setAttribute(attr.name, attr.value);
  }

  return tag;
};

const convertNamedNodeMapToObject = (attributes: NamedNodeMap): { [key: string]: string } => {
  const attributesList = [...attributes];
  const attributesObject = attributesList.reduce(
    (obj, item) => ({ ...obj, [item.name]: item.value }),
    {}
  );
  return attributesObject;
};

/** converts HTML string to React.Elements (see comments on "useBodyInjection" for description of nuances involved with rendering scripts inside React) */
export const convertHtmlStringToReactElements = (
  scriptString?: string,
  addCookieAttributes?: boolean
) => {
  if (typeof window === 'undefined' || !scriptString) {
    return null;
  }

  const tags: React.DetailedReactHTMLElement<React.HTMLAttributes<HTMLElement>, HTMLElement>[] = [];

  const parsedDOM = new DOMParser().parseFromString(scriptString, 'text/html');
  const scripts = Array.from(parsedDOM.getElementsByTagName('script'));
  const styles = Array.from(parsedDOM.getElementsByTagName('style'));
  const elements = [...scripts, ...styles];

  for (let index = 0; index < elements.length; index++) {
    const element = elements[index];

    switch (element.nodeName) {
      case 'SCRIPT': {
        const tag = createScript(element);

        // add Onetrust cookie blocking functionality to all scripts except original OneTrust scripts
        if (addCookieAttributes) {
          tag.type = 'text/plain';
          tag.classList.add(blockCookieCategoryClass);
        }

        const attributes = convertNamedNodeMapToObject(tag.attributes);

        tags.push(React.createElement('script', { ...attributes, key: index }, tag.innerHTML));
        break;
      }
      case 'STYLE': {
        const tag = createStyle(element);

        const attributes = convertNamedNodeMapToObject(tag.attributes);

        tags.push(React.createElement('style', { ...attributes, key: index }, tag.innerHTML));
        break;
      }
      default: {
        // We limit the addition to script or style tags (for our sanity?)
        Logger(
          `Received disallowed Injection element, ${element.nodeName}. Only script and style tags may be injected.`,
          {
            componentName: 'SiteSettings',
          }
        );
      }
    }
  }

  return tags;
};

/** Append "arbitrary" script and style tags to document body */
export const useBodyInjection = (bodyInjection?: string) => {
  const { status } = useAppContext();
  const isAuthenticated = status?.isAuthenticated;

  // # The reason we need to step outside React and tack these tags onto the document:
  // When React creates elements it uses the .innerHTML property to set the content of the element.
  // This works for everything except script tags because while the content will be inserted into the tag
  // the browser WILL NOT execute the code. e.g. <script>console.log('hello');</script> will be place in the DOM,
  // but the console.log("hello") will not execute.
  // # Note: The "convertHtmlStringToReactElements" function looks similar, but is actually different - it works because the script is placed inside the
  // <Helmet> component which does a transform under the hood.
  useEffect(() => {
    const tags: (HTMLScriptElement | HTMLStyleElement)[] = [];

    if (bodyInjection) {
      const parsedDOM = new DOMParser().parseFromString(bodyInjection, 'text/html');
      const scripts = Array.from(parsedDOM.getElementsByTagName('script'));
      const styles = Array.from(parsedDOM.getElementsByTagName('style'));
      const elements = [...scripts, ...styles];

      for (let index = 0; index < elements.length; index++) {
        const element = elements[index];

        switch (element.nodeName) {
          case 'SCRIPT': {
            const tag = createScript(element);

            // set auth data attr to tell script it should initialize
            if (tag.dataset.deAuth) {
              tag.dataset.deAuth = String(!!isAuthenticated);
            }

            tags.push(tag);

            document.body.appendChild(tag);
            break;
          }
          case 'STYLE': {
            const tag = createStyle(element);

            tags.push(tag);

            document.body.appendChild(tag);
            break;
          }
          default: {
            // We limit the addition to script or style tags (for our sanity?)
            Logger(
              `Received disallowed BodyInjection element, ${element.nodeName}. Only script and style tags may be injected.`,
              {
                componentName: 'SiteSettings',
              }
            );
          }
        }
      }
    }

    return () => {
      // If some tags we added, clean them up
      tags?.forEach(tag => document.body?.removeChild(tag));
    };
  }, [bodyInjection, isAuthenticated]);
};

const SiteSettings = ({ fields }: SiteSettingsProps) => {
  const [
    gtmScriptEnable,
    tealeafScriptEnable,
    qualtricsScriptEnable,
    acousticEmailScriptEnable,
    globalInjectionEnable,
  ] = useScriptSetting(fields);

  const bodyInjection = fields.BodyInjection?.value;
  useBodyInjection(bodyInjection);

  const tealeafScriptSrc = fields?.['Tealeaf Script']?.value?.href;
  const injection = fields?.Injection?.value;
  const cookieConsentScript = fields?.['Cookie Consent Script']?.value;
  const cookieConsentEnable = fields?.['Cookie Consent Enable']?.value;

  const cookieScriptAttributes = cookieConsentEnable
    ? { type: 'text/plain', className: blockCookieCategoryClass }
    : {};

  return (
    <>
      <Helmet>
        {cookieConsentEnable && convertHtmlStringToReactElements(cookieConsentScript, false)}
        {globalInjectionEnable && convertHtmlStringToReactElements(injection, cookieConsentEnable)}
        {tealeafScriptEnable && tealeafScriptSrc && (
          <script async defer src={tealeafScriptSrc}></script>
        )}
        {qualtricsScriptEnable && (
          <script async defer {...cookieScriptAttributes}>
            {QualtricsScript}
          </script>
        )}
      </Helmet>
      {acousticEmailScriptEnable && (
        <Helmet>
          <meta
            name="com.silverpop.brandeddomains"
            content="www.pages02.net,me.duke-energy.com,www.duke-energy.com,www.progress-energy.com,efficiencycontact.efficiency-services.com"
          ></meta>
          <script
            async
            defer
            src={
              getSectionSegment() === 'RES'
                ? AcousticEmailScriptResidential
                : AcousticEmailScriptBusiness
            }
            {...cookieScriptAttributes}
          ></script>
        </Helmet>
      )}
      {gtmScriptEnable && <GoogleTagManager />}
    </>
  );
};

export default SiteSettings;
