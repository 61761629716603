import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPhone = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M33.581 23.009h-1.283L31.84.13h1.303a4.803 4.803 0 0 1 4.893 4.712l.257 13.272v.002a4.802 4.802 0 0 1-4.712 4.893ZM28.943 42.55c7.48 18.444 14.867 13.696 14.867 13.696l16.83 15.489s-20.586 18.957-43.1-23.062C-2.057-1.318 28.631.003 28.631.003l.312 22.859s-8.763 1.796 0 19.688Zm24.584 9.882 9.789 9.073a4.807 4.807 0 0 1 .238 6.803l-.861.953-16.83-15.639.862-.951c.004-.006.01-.01.013-.015a4.801 4.801 0 0 1 6.789-.224Z" />
  </svg>
);

export default SvgPhone;
