import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgArrowSort = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m71.82 37.022-1.125 1.124a3.028 3.028 0 0 1-4.282 0L41.785 13.593l.062 59.115a3.027 3.027 0 0 1-3.028 3.028h-1.587a3.026 3.026 0 0 1-3.028-3.028l-.06-59.045L9.587 38.146a3.028 3.028 0 0 1-4.282 0l-1.124-1.124a3.028 3.028 0 0 1 0-4.283L34.662 2.353c.023-.024.04-.054.066-.08L35.85 1.15A3.02 3.02 0 0 1 38 .264a3.018 3.018 0 0 1 2.15.886l1.124 1.124c.023.025.04.055.066.08l30.48 30.385a3.028 3.028 0 0 1 0 4.283"
    />
  </svg>
);

export default SvgArrowSort;
