import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgChevron = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 16 32"
    aria-labelledby={titleId}
    aria-hidden="true"
    focusable="false"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.555,16.334,15.535,3.259A1.993,1.993,0,0,0,15.309.47,1.944,1.944,0,0,0,12.549.7L.465,15.087a2,2,0,0,0,.034,2.6L12.582,31.34a1.943,1.943,0,0,0,2.765.156A1.993,1.993,0,0,0,15.5,28.7Z"
    />{' '}
  </svg>
);

export default SvgChevron;
