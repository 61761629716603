import Composition from 'src/lib/HOC/Composition';
import RelatedLinks from './index';

const { compositionFunction, component } = Composition(RelatedLinks)(({ fields }) => ({
  links: fields?.items?.reduce(
    (acc: Parameters<typeof RelatedLinks>, curr: typeof fields) => [
      ...acc,
      {
        value: { ...curr?.fields?.Link?.value, text: curr?.fields?.['Link Text']?.value },
        id: curr?.id,
      },
    ],
    []
  ),
  heading: fields?.Heading,
  id: fields?.Id,
}));

export { compositionFunction, component as default };
