import React from 'react';
import { IndicatorDotsType } from '../types';

const executeScroll = (index: number, ref: any) => {
  const refChildren = ref.current.children;
  const cards = [];
  // filtering out role="presentation" <li>s
  for (let i = 0; i < refChildren.length; i++) {
    const child = refChildren[i];
    if (child.getAttribute('role') !== 'presentation') {
      cards.push(child);
    }
  }
  cards[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
};

const IndicatorDots = ({ activeIndex, autoScrollRef, total }: IndicatorDotsType) => (
  <ol aria-hidden="true" className="relative flex justify-center mt-24 space-x-16 md:hidden">
    {Array.from(Array(total)).map((_, index) => (
      <li key={index}>
        <button
          className={`block icon-14 rounded-full border-2 ${
            index === activeIndex ? 'bg-blue border-blue' : 'bg-transparent border-gray-dark'
          }`}
          type="button"
          onClick={() => {
            if (autoScrollRef) {
              executeScroll(index, autoScrollRef);
            }
          }}
        >
          <span className="sr-only">Card {index + 1}</span>
        </button>
      </li>
    ))}
  </ol>
);

export default IndicatorDots;
