/* eslint-disable max-lines */
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useRef, useState } from 'react';
import CallToAction from 'src/components/Common/@electron/CallToAction';
import IndicatorDots from 'src/components/Common/@electron/ProductCards/components';
import { useBodyContext } from 'src/components/Common/ContentWrapper/context';
import LazyImage from 'src/components/Common/LazyImage';
import Link from 'src/components/Common/Link';
import track from 'src/lib/Analytics';
import { useExperienceEditor } from 'src/lib/Hooks/useExperienceEditor';
import useIntersection from 'src/lib/Hooks/useIntersection';
import useMediaQuery from 'src/lib/Hooks/useMediaQuery';
import { FlipboardComponentType, FlipboardItemsType, FlipboardType, OverlayType } from './types';

const FlipboardItem = ({
  analytics,
  image,
  title,
  text,
  link,
  linkMobile,
  index = 0,
  isWrapped,
  isZoomed,
  setActiveIndex,
  modal,
}: FlipboardItemsType) => {
  const [hasFocus, setHasFocus] = useState(false);
  const { isEEActive } = useExperienceEditor();
  const isDesktop = useMediaQuery('lg');
  const computedLink = isDesktop ? link : linkMobile;
  const themeColors = [
    ['text-blue', 'lg:bg-blue', 'border-blue'],
    ['text-green-dark', 'lg:bg-green-dark', 'border-green-dark'],
    ['text-gray-dark', 'lg:bg-gray-dark', 'border-gray-dark'],
    ['text-red', 'lg:bg-red', 'border-red'],
  ];
  const [textColor, bgColor, borderColor] = themeColors[index % 4];
  const { isIntersecting, ref } = useIntersection<HTMLDivElement>({ threshold: 0.6 }, false);

  useEffect(() => {
    isIntersecting && setActiveIndex(index);
  }, [isIntersecting]);

  return (
    <li
      onFocus={() => {
        if (isDesktop) setHasFocus(true);
      }}
      onBlur={() => {
        if (isDesktop) setHasFocus(false);
      }}
      className={`snap-center flex-shrink-0 sm:p-8 md:p-12 xl:p-16 w-11/12 sm:w-1/2 lg:w-1/3 max-w-xs lg:max-w-none ${
        isWrapped ? 'md:ml-px' : ''
      }`}
    >
      <div
        ref={ref}
        className={`${borderColor} ${isZoomed ? 'scale-100 shadow-lg' : 'scale-90 shadow'} ${
          isEEActive ? 'scShowHoverState' : ''
        } group relative p-24 md:p-32 lg:py-48 flex flex-col h-full overflow-hidden rounded-md bg-white border-t-6 border-opacity-80 sm:shadow transition-transform duration-250 transform sm:scale-100`}
      >
        {image?.value?.src && (
          <LazyImage
            className="mx-auto mb-16 md:mb-24 flex-shrink-0 object-cover aspect-1/1 icon-122 lg:icon-190 rounded-full bg-gray-light"
            srcSet={[{ w: 194 }, { w: 388 }, { w: 304 }, { w: 608 }]}
            sizes="(min-width: 1024px) 304px, 194px"
            {...image}
            alt=""
          />
        )}
        <div className="flex flex-col flex-grow text-center">
          <TitleLinkBlock link={computedLink} {...{ title, textColor, analytics, modal }} />
          <Overlay link={computedLink} {...{ text, bgColor, hasFocus }} />
        </div>
      </div>
    </li>
  );
};

const TitleLinkBlock = ({
  analytics,
  link,
  modal,
  title,
  textColor,
}: { textColor: string } & Pick<FlipboardItemsType, 'analytics' | 'modal' | 'link' | 'title'>) => {
  const { dispatch } = useBodyContext();
  const handleClick = (event: React.MouseEvent) => {
    if (modal?.id) {
      event.preventDefault();
      dispatch({ type: 'modalOpen', payload: { id: modal.id } });
    }
    track.component(analytics);
  };

  // If there is a href: render Link
  if (link.value?.href) {
    return (
      <h3 className={`text-xl ${textColor}`}>
        <Link {...link} className="link-block" onClick={handleClick}>
          <Text field={title} />
        </Link>
      </h3>
    );
  }

  // If there is no href, but there is a modal: render button
  if (modal?.id) {
    return (
      <h3 className={`text-xl ${textColor}`}>
        <button className="link-block" onClick={handleClick}>
          <Text field={title} />
        </button>
      </h3>
    );
  }

  // Else if there is no href or modal: render a span
  return (
    <h3 className={`text-xl ${textColor}`}>
      <span className="link-block" data-testid="span">
        <Text field={title} />
      </span>
    </h3>
  );
};

const Overlay = ({
  link,
  text,
  bgColor,
  hasFocus,
}: OverlayType & Pick<FlipboardItemsType, 'link' | 'text'>) => (
  <div
    className={`${bgColor} lg:absolute lg:inset-0 flex flex-col flex-grow lg:px-32 lg:py-48 lg:bg-opacity-80 lg:backdrop-filter lg:backdrop-blur-sm lg:opacity-0 lg:group-hover:opacity-100 lg:transition-opacity lg:duration-300 lg:ease-out ${
      hasFocus ? 'lg:opacity-100' : ''
    }`}
  >
    <div className="mt-12 lg:mt-0 flex flex-grow lg:flex-grow-0 lg:items-center lg:mb-24 lg:h-190">
      <RichText className="line-clamp-6 lg:text-lg lg:text-white" tag="p" field={text} />
    </div>
    {link?.value?.href && (
      <div className="mt-12 lg:mt-0 lg:flex lg:flex-grow lg:items-center">
        <span
          className="btn btn-full lg:btn-sm btn-secondary lg:btn-primary-reversed lg:mx-auto"
          aria-hidden={true}
        >
          {link.value.text || 'Learn More'}
        </span>
      </div>
    )}
  </div>
);

const Flipboard = ({ items, ...rest }: FlipboardComponentType) => (
  <CallToAction {...rest}>
    <FlipboardComponent items={items} />
  </CallToAction>
);

const FlipboardComponent = ({ items }: FlipboardType) => {
  const shouldZoom = !useMediaQuery('sm');
  const [activeIndex, setActiveIndex] = useState(0);
  const autoScrollRef = useRef<HTMLUListElement | null>(null);

  return (
    <div className="container-4xl">
      <ul
        ref={autoScrollRef}
        className="snap sm:snap-none scrollbar-none flex sm:flex-wrap sm:justify-center px-16 py-24 sm:p-0 -mx-16 -my-24 sm:-m-8 md:-m-12 xl:-m-16 overflow-x-scroll sm:overflow-x-visible"
      >
        {/* Empty presentational list items at beginning and end for spacing on small devices */}
        <li className="flex-shrink-0 w-11/12 sm:hidden" role="presentation"></li>
        {items?.map((props, index) => (
          <FlipboardItem
            key={index}
            {...props}
            index={index}
            isWrapped={index === 0 && items?.length === 4}
            setActiveIndex={setActiveIndex}
            isZoomed={shouldZoom && activeIndex === index}
          />
        ))}
        <li className="flex-shrink-0 w-11/12 sm:hidden" role="presentation"></li>
      </ul>
      {shouldZoom && <IndicatorDots total={items?.length} {...{ activeIndex, autoScrollRef }} />}
    </div>
  );
};

export { FlipboardComponent, FlipboardItem };
export default Flipboard;
