import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgIndiana = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m36.828 72.267 2.323-5.152a.237.237 0 0 1 .156-.131.242.242 0 0 1 .191.035l4.435 3.242c.153.114.375.091.506-.046l7.101-7.526a.38.38 0 0 0 .103-.211l.517-3.722a.206.206 0 0 1 .107-.156l7.389-4.015c.1-.056.172-.151.195-.263l1.09-5.358a.386.386 0 0 0 .004-.107l-3.8-47.269L57.197.4a.392.392 0 0 0-.112-.29.403.403 0 0 0-.288-.11L27.172 1.123a.376.376 0 0 0-.162.042l-3.868 1.998a.234.234 0 0 1-.22.004L20.204 1.8a.405.405 0 0 0-.38.02.391.391 0 0 0-.177.34l1.803 52.619-3.184 9.38-3.252 11.351a.386.386 0 0 0 .355.491h.018l2.461-.014a.388.388 0 0 0 .363-.266l.715-2.155a.234.234 0 0 1 .167-.148l4.504-.941a.26.26 0 0 1 .127.01l5.092 1.634c.094.03.203.021.293-.025l2.53-1.287 4.861-.315a.39.39 0 0 0 .327-.226"
    />
  </svg>
);

export default SvgIndiana;
