export const OrDivider = () => {
  return (
    <div className="w-full relative my-36 md:my-48" role="presentation">
      <div className="border-t border-gray w-full" />
      <div className="absolute bg-white px-6 text-gray left-1/2 -translate-x-1/2 -translate-y-1/2">
        OR
      </div>
    </div>
  );
};
