import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import CallToAction from 'src/components/Common/@electron/CallToAction';
import RichText from 'src/components/Common/RichText_Old';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';
import Transition from 'src/lib/Transition';
import { AccordionGroupProps, AccordionProps } from './types';

const themeMap = {
  dark: {
    button: 'bg-teal-darker text-white',
    border: 'divide-gray-lighter',
    icon: 'text-white',
  },
  footer: {
    button: 'bg-blue text-white',
    border: 'divide-teal-darker',
    icon: 'text-gray',
  },
  default: {
    button: 'bg-transparent hover:bg-teal-lighter text-teal-darker',
    border: 'divide-gray-light',
    icon: 'text-teal-darker',
  },
} as const;

const AccordionGroup = ({
  analytics,
  buttonClass = '',
  buttonTitle,
  children,
  ctaHasTitle = true,
  handler = () => {},
  hasHash,
  id,
  index = -1,
  isActiveItem = false,
  theme = 'default',
}: React.PropsWithChildren<AccordionGroupProps>) => {
  const [isOpen, setIsOpen] = useState(isActiveItem);
  const colorStyles = themeMap[theme];
  const scrollTarget = useRef<HTMLButtonElement>(null);
  const HeaderTag = ctaHasTitle ? 'h3' : 'h2';

  useEffect(() => {
    if (hasHash && scrollTarget.current) {
      window.scrollTo(0, scrollTarget.current.offsetTop);
      setIsOpen(true);
    }
  }, [hasHash]);

  useEffect(() => {
    if (!isActiveItem && isOpen) setIsOpen(false);
  }, [isActiveItem]);

  // fire the parent handler to update activeIndex
  // and toggle isOpen state
  const handleClick = () => {
    handler(index);
    if (!isOpen && analytics) {
      // send analytics when component opens, but NOT when it closes
      track.component(analytics);
    }
    return setIsOpen(!isOpen);
  };

  return (
    <li>
      <HeaderTag>
        <button
          aria-expanded={isOpen}
          id={id}
          ref={scrollTarget}
          className={`js-accordion-button flex items-center justify-between w-full p-16 md:py-24 text-xl text-left transition-colors duration-200 ${colorStyles.button}`}
          onClick={handleClick}
          type="button"
        >
          {buttonTitle && <Text className={buttonClass} field={buttonTitle} tag="span" />}
          <SvgLoader
            className={`fill-current icon-20 flex-shrink-0 ml-24 md:ml-32 transform duration-200 ${
              isOpen ? 'scale-y-flip' : ''
            }`}
            color={colorStyles.icon}
            name="ArrowDown"
            sizeConstraints={false}
          />
        </button>
      </HeaderTag>
      <Transition.Height data-testid={`transition-${index}`} active={isOpen}>
        {children}
      </Transition.Height>
    </li>
  );
};

const AccordionWrapper = ({
  children,
  theme = 'default',
}: {
  children: React.ReactNode;
  theme?: AccordionGroupProps['theme'];
}) => {
  const colorStyles = themeMap[theme];
  return (
    <section className="accordion container-4xl -mx-16 sm:mx-auto">
      <ul className={`divide-y ${colorStyles.border}`}>{children}</ul>
    </section>
  );
};

const AccordionComponent = ({
  closeOthers = false,
  theme,
  items = [],
  ctaHasTitle,
}: AccordionProps) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const { hash } = useLocation();
  const hasHash = (id?: string) => `#${id}` === hash;

  return (
    <AccordionWrapper theme={theme}>
      {items.map(
        ({ id, text, title, analytics }, index) =>
          text && (
            <AccordionGroup
              analytics={analytics}
              buttonClass="text-xl pr-24 lg:pr-0"
              buttonTitle={title}
              handler={setActiveIndex}
              hasHash={hasHash(id)}
              id={id}
              index={index}
              isActiveItem={closeOthers && index === activeIndex}
              key={index}
              theme={theme}
              ctaHasTitle={ctaHasTitle}
            >
              <div className="px-16 pb-16 lg:pb-24">
                <RichText tag="div" field={text} />
              </div>
            </AccordionGroup>
          )
      )}
    </AccordionWrapper>
  );
};

const Accordion = ({
  closeOthers = false,
  items,
  theme,
  ...rest
}: Pick<AccordionProps, 'closeOthers' | 'items' | 'theme'> &
  Parameters<typeof CallToAction>[0]) => {
  const ctaHasTitle = !!rest.title?.value;
  return (
    <CallToAction {...rest}>
      <AccordionComponent
        closeOthers={closeOthers}
        theme={theme}
        items={items}
        ctaHasTitle={ctaHasTitle}
      />
    </CallToAction>
  );
};

export { Accordion, AccordionComponent, AccordionGroup, AccordionWrapper, themeMap };
