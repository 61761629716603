import Composition from 'src/lib/HOC/Composition';
import { data } from './data';
import IFrame from './index';

const { component, compositionFunction } = Composition(IFrame)<typeof data>(
  ({ rendering: { fields, params } }) => {
    const link = fields?.['Desktop Url'];
    const mobileLink = fields?.['Mobile Url'];

    return {
      height: params?.['Default Height'],
      link,
      mobileLink: mobileLink.value?.href ? mobileLink : link,
    };
  }
);

export { compositionFunction };
export default component;
