import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPicknicking = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width="70" height="70" rx="6" ry="6" fill="currentColor" />
    <path
      d="M57.4,34.08H47.84l-4.4-10.89H49a2,2,0,0,0,2-2V19.8a2,2,0,0,0-2-2H21a2,2,0,0,0-2,2v1.38a2,2,0,0,0,2,2h5.56l-4.4,10.89H12.6a2,2,0,0,0-2,2v1.38a2,2,0,0,0,2,2H20L15.65,50.2c-.45,1.1.09,2,1.2,2h1.79a3.35,3.35,0,0,0,2.82-2l4.33-10.72H44.22L48.55,50.2a3.35,3.35,0,0,0,2.82,2h1.79c1.1,0,1.64-.9,1.2-2L50,39.47H57.4a2,2,0,0,0,2-2V36.09A2,2,0,0,0,57.4,34.08ZM28,34.08l4.4-10.89h5.28L42,34.08Z"
      fill="#fff"
    />
  </svg>
);

export default SvgPicknicking;
