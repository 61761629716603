import track from 'src/lib/Analytics';
import { useSignInContent } from '..';

const needHelpSigningInText = 'Need help signing in?';
const ForgotUsernamePasswordLinks = () => {
  const { content } = useSignInContent();
  const {
    forgotPasswordLink,
    forgotUserNameLink,
    forgotPassword,
    forgotUsername,
    analyticsVariant,
    subheadline,
  } = content;

  const handleUsernameClick = () => {
    track({
      event: 'send-VPV',
      'vpv-name': `/vpv/de/page/sign-in/clickthru/sign-in/${subheadline}/na/${analyticsVariant}/need-help-signing-in`,
    });
  };

  const handlePasswordClick = () => {
    let eventName = 'forgot-password';
    // converting to lower case to make a case-insensitive comparision
    if (forgotPassword.toLowerCase() === needHelpSigningInText.toLowerCase()) {
      eventName = 'need-help-signing-in';
    }
    track({
      event: 'send-VPV',
      'vpv-name': `/vpv/de/page/sign-in/clickthru/sign-in/${subheadline}/na/${analyticsVariant}/${eventName}`,
    });
  };

  // One of these will be used for the 'Need Help Signing In' flow
  const hasForgotUsername = forgotUsername && forgotUserNameLink;
  const hasForgotPassword = forgotPassword && forgotPasswordLink;

  return (
    <div className="w-full max-w-2xs pt-4 mx-auto">
      <div className="flex flex-row gap-32 justify-center">
        {hasForgotUsername && (
          <a
            href={forgotUserNameLink}
            className="text-teal-darker btn btn-tertiary btn-underline btn-flat"
            onClick={handleUsernameClick}
          >
            {forgotUsername}
          </a>
        )}

        {hasForgotPassword && (
          <a
            href={forgotPasswordLink}
            className="text-teal-darker btn btn-tertiary btn-underline btn-flat"
            onClick={handlePasswordClick}
          >
            {forgotPassword}
          </a>
        )}
      </div>
    </div>
  );
};

export default ForgotUsernamePasswordLinks;
