import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgParkingRoadway = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width="70" height="70" rx="6" ry="6" fill="currentColor" />
    <path
      d="M49.08,31.59h0a11.22,11.22,0,0,0,1.13-4.9A11.61,11.61,0,0,0,48,19.94a12.32,12.32,0,0,0-5.78-4.46,23.91,23.91,0,0,0-8.64-1.31H22.46a2.62,2.62,0,0,0-2.62,2.62V53.21a2.62,2.62,0,0,0,2.62,2.62H26a2.62,2.62,0,0,0,2.62-2.62V39.37h4.92a23.91,23.91,0,0,0,8.64-1.31,12.32,12.32,0,0,0,5.78-4.46c.21-.31.36-.64.54-1s.27-.46.39-.69S49,31.72,49.08,31.59Zm-20.47-9H35c2.18,0,3.75.41,4.66,1.23A3.52,3.52,0,0,1,41,26.64a3.82,3.82,0,0,1-.63,2.23,3.3,3.3,0,0,1-1.78,1.27,15,15,0,0,1-4.79.8H28.61Z"
      fill="#fff"
    />
  </svg>
);

export default SvgParkingRoadway;
