import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgQuestionSolid = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M70 35A35 35 0 1 1 35 0a35 35 0 0 1 35 35" fill="currentColor" />
    <path
      d="M37.07 45.2H32.7a1.2 1.2 0 0 1-1.2-1.2v-1.4a14 14 0 0 1 .84-5.06 11.81 11.81 0 0 1 2-3.29A34.72 34.72 0 0 1 37.71 31a13.92 13.92 0 0 0 2.82-3 4.14 4.14 0 0 0 .55-2.11A5.12 5.12 0 0 0 39.37 22a5.81 5.81 0 0 0-4.25-1.67 5.63 5.63 0 0 0-4 1.5 8.5 8.5 0 0 0-2.19 5 1.23 1.23 0 0 1-1.33 1l-4.68-.55A1.21 1.21 0 0 1 21.85 26 13.3 13.3 0 0 1 26 17.28a13.24 13.24 0 0 1 9-3.06 13.57 13.57 0 0 1 9.48 3.27 10.55 10.55 0 0 1 3.63 8 10.3 10.3 0 0 1-1.29 5.06 22.08 22.08 0 0 1-4.7 5.29 20.56 20.56 0 0 0-2.73 2.72 5.29 5.29 0 0 0-.82 1.75 16.35 16.35 0 0 0-.3 3.69 1.2 1.2 0 0 1-1.2 1.18M38.79 54.57a1.2 1.2 0 0 1-1.2 1.2h-5.18a1.2 1.2 0 0 1-1.2-1.2v-5.18a1.2 1.2 0 0 1 1.2-1.2h5.18a1.2 1.2 0 0 1 1.2 1.2"
      fill="#fff"
    />
  </svg>
);

export default SvgQuestionSolid;
