import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCheck = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M74.998 3.518a4.264 4.264 0 0 0-5.81-.672c-.86.644-20.772 15.698-45.932 49.953L6.836 40.367a4.261 4.261 0 0 0-5.666.465 4.26 4.26 0 0 0-.164 5.681l21.878 25.92a4.26 4.26 0 0 0 7.242-1.244c.116-.307 11.895-30.886 44.543-61.835A4.26 4.26 0 0 0 75 3.518"
    />
  </svg>
);

export default SvgCheck;
