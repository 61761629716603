import RichText from 'src/components/Common/RichText_Old';
import { unmaskId } from 'src/lib/Analytics/tealeaf/unmaskId';
import { useSignInContent, useSignInMachine } from '..';
import { InlineBanner } from '../../../Common/@electron/InlineBanner';
import { Input } from '../../../Common/@electron/Input';
import { SmallContainer } from '../../_components/layout';
import { FormHooks } from '../Main';

interface SignInInputsProps {
  formHooks: FormHooks;
  id: string;
}

export const SignInInputs = ({ formHooks, id }: SignInInputsProps) => {
  const { content, getError } = useSignInContent();
  const { state } = useSignInMachine();

  const { register, formState, clearErrors } = formHooks;
  const hasUsernameError = !!formState.errors.usernameInput;
  const hasPasswordError = !!formState.errors.passwordInput;

  const error = getError(state.context.errorCode);
  const inlineErrorMessage = error?.Vehicle?.value === 'Inline';

  const usernameId = unmaskId(`${id}-signInUsername`);
  const passwordId = `${id}-signInPassword`;

  const {
    usernamePlaceholder,
    errorMessageUsername,
    errorMessageUsernameLength,
    errorMessagePassword,
    passwordPlaceholder,
    showText,
    hideText,
  } = content;

  return (
    <div className="flex flex-col flex-grow gap-16">
      {inlineErrorMessage && (
        <SmallContainer>
          <InlineBanner variant="error">
            <RichText field={error.Message?.value} />
          </InlineBanner>
        </SmallContainer>
      )}

      <Input
        description={<span className="sr-only">Please enter your username.</span>}
        sizing="md"
        label={usernamePlaceholder}
        type="user"
        autoComplete="username email"
        id={usernameId}
        {...register('usernameInput', {
          required: errorMessageUsername,
          minLength: {
            value: 5,
            message: errorMessageUsernameLength,
          },
        })}
        errorMessage={formState.errors.usernameInput?.message || ''}
        status={hasUsernameError ? 'error' : undefined}
        onKeyDown={e => {
          // seems to work without checking for tab/enter, but just in case...
          if (hasUsernameError && e.code !== 'Tab' && e.code !== 'Enter') {
            clearErrors('usernameInput');
          }
        }}
      />
      <Input
        description={<span className="sr-only">Please enter your password.</span>}
        sizing="md"
        label={passwordPlaceholder}
        showText={showText}
        hideText={hideText}
        type="password"
        autoComplete="current-password"
        id={passwordId}
        {...register('passwordInput', {
          required: errorMessagePassword,
        })}
        errorMessage={formState.errors.passwordInput?.message || ''}
        status={hasPasswordError ? 'error' : undefined}
        onKeyDown={e => {
          if (hasPasswordError && e.code !== 'Tab' && e.code !== 'Enter') {
            clearErrors('passwordInput');
          }
        }}
      />
    </div>
  );
};
