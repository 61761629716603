import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgRestrooms = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" aria-labelledby={titleId} {...props}>
    <defs>
      <clipPath id="a">
        <rect x="-110" width="70" height="70" rx="3" ry="3" fill="none" />
      </clipPath>
    </defs>
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width="70" height="70" rx="6" ry="6" fill="currentColor" />
    <path
      d="M55.4,37.23A1.57,1.57,0,0,0,57,38.76h-.2a1.72,1.72,0,0,0,1.74-1.53V26.59s0-4.37-4.4-4.37H46.3c-4.4,0-4.4,4.46-4.4,4.46V37.23a1.53,1.53,0,0,0,1.53,1.53h.24c.85,0,1.29-.69,1.29-1.53V27.64a.41.41,0,0,1,.81,0V53.86a2,2,0,0,0,2,2h.06a1.93,1.93,0,0,0,1.92-2V38.68a.41.41,0,1,1,.81,0V53.86a2,2,0,0,0,2,2h0a2.09,2.09,0,0,0,2.1-2V27.78a.41.41,0,0,1,.41-.41.38.38,0,0,1,.41.38Z"
      fill="#fff"
    />
    <path d="M53.69,17.7a3.54,3.54,0,1,1-3.54-3.54,3.54,3.54,0,0,1,3.54,3.54" fill="#fff" />
    <path d="M23.83,55.83h0Z" fill="#fff" />
    <circle cx="21.48" cy="17.7" r="3.54" fill="#fff" />
    <path d="M30.34,37.12l-.1,0-.1,0Z" fill="#fff" />
    <path
      d="M31.42,35.24,28.31,24.67A3.59,3.59,0,0,0,25,22.22H18a3.59,3.59,0,0,0-3.34,2.45L11.54,35.24a1.7,1.7,0,0,0,1.18,1.9,1.56,1.56,0,0,0,1.82-1.08l2.32-8.64a.38.38,0,0,1,.49-.26.41.41,0,0,1,.29.5l-4,14.75h3.44V53.86a2,2,0,0,0,2,2h.06a1.93,1.93,0,0,0,1.92-2V42.41h.81V53.86a2,2,0,0,0,2,2,2.09,2.09,0,0,0,2.08-2V42.41h3.44l-4-14.75a.41.41,0,0,1,.29-.5.38.38,0,0,1,.49.26l2.32,8.64a1.56,1.56,0,0,0,1.82,1.08A1.7,1.7,0,0,0,31.42,35.24Z"
      fill="#fff"
    />
    <path d="M12.81,37.17l-.1,0-.1,0Z" fill="#fff" />
    <rect x="35.67" y="14.17" width="1.61" height="41.67" fill="#fff" />
    <g clipPath="url(#a)">
      <path
        d="M11.27,47.11C11,46.94,9.49,46.3,8,45.68a8.42,8.42,0,0,0-2.12-.16H5.66a8.09,8.09,0,0,0-2.21.17C1.92,46.31.56,46.89.42,47S-.79,47.5-2,48H-4.44c-1.16-.46-2.23-.9-2.38-1s-1.52-.67-3-1.29A8.42,8.42,0,0,0-12,45.53h-.18a8.09,8.09,0,0,0-2.21.17c-1.53.62-2.85,1.16-2.92,1.21a2.57,2.57,0,0,1-.38.17l-.11.06a6.83,6.83,0,0,1-3.3.82,6.84,6.84,0,0,1-3.3-.82l-.11-.07a2.61,2.61,0,0,1-.39-.17c-.08,0-1.4-.6-2.93-1.22a8.42,8.42,0,0,0-2.12-.16h-.18a8.09,8.09,0,0,0-2.21.17c-1.53.62-2.89,1.19-3,1.28s-1.21.53-2.38,1h-2.48c-1.16-.46-2.23-.9-2.38-1s-1.52-.67-3-1.29a8.42,8.42,0,0,0-2.12-.16H-48a8.09,8.09,0,0,0-2.21.17c-1.53.62-2.89,1.19-3,1.28l-.38.22a1.33,1.33,0,0,0,0,1.1c.2.49,1.1,1.76,1.37,1.55l.8-.59A18,18,0,0,1-49,48.4h2.48l2.21,1.4.29.19.05,0a10.08,10.08,0,0,0,5,1.26h0A10.08,10.08,0,0,0-34,50l.05,0,.29-.19c.11-.06,1.15-.49,2.32-1h2.48c1.16.46,2.23.9,2.37,1s1.52.66,3,1.28a8,8,0,0,0,2.27.17h.1a7.93,7.93,0,0,0,2.28-.17c1.53-.62,2.9-1.19,3-1.27s1.21-.53,2.37-1h2.48c1.16.46,2.2.88,2.31.95l.29.19.05,0a10.08,10.08,0,0,0,5,1.26h0a10.08,10.08,0,0,0,5-1.26l.05,0,.29-.19c.11-.06,1.15-.49,2.32-1H7a27.55,27.55,0,0,1,2.82,1.26c.39.24.92,0,1.18-.61S11.56,47.27,11.27,47.11Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgRestrooms;
