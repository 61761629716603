import { returnCode } from './returnCodes';
export const defaultURL = '/facade/api/Authentication/SignIn';

export type Success = {
  Status: 'Success';
  MessageText: string;
  ReturnCode: number;
  Experiences: string[];
  Token: string;
  PendingDelegation: boolean;
};

type ReturnCode = { Status: 'Return Code'; MessageText: string; ReturnCode: number };

type FacadeResponse = Success | ReturnCode;

export const validateSignInSuccess = {
  Experiences: ['SC_RESIDENTIAL'],
  MessageText: 'Success',
  PendingDelegation: false,
  ReturnCode: returnCode.SUCCESS,
  Status: 'Success',
  Token: '',
} satisfies Success;

export const validateSignInError = {
  MessageText: 'No match Username',
  ReturnCode: returnCode.NO_MATCH_USERNAME,
  Status: 'Return Code',
} satisfies ReturnCode;

export const validateSignInErrorPage = {
  MessageText: 'Reset password',
  ReturnCode: returnCode.RESET_PASSWORD,
  Status: 'Return Code',
} satisfies ReturnCode;

export type ValidateSignInType = FacadeResponse;

interface Body {
  loginIdentity: string;
  password: string;
}

interface SignInRequest {
  url: Parameters<typeof fetch>[0];
  body: Body;
}

export const signIn = async ({ url, body }: SignInRequest) => {
  const endpoint = url || defaultURL;
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      accept: 'text/plain',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const { ok } = response;

  const data = await response.json();

  if (!ok || data?.Status === 'Error') {
    // "E" is a global default returnCode
    throw new Error('E');
  }

  return data;
};
