import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgFilter = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}

    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M43.966149,63.3667608 L31.958149,63.3667608 C29.5827765,63.3667608 27.6380706,61.4220549 27.6380706,59.0466824 C27.6380706,56.6698196 29.5827765,54.7251137 31.958149,54.7251137 L43.966149,54.7251137 C46.3430118,54.7251137 48.2862275,56.6698196 48.2862275,59.0466824 C48.2862275,61.4220549 46.3430118,63.3667608 43.966149,63.3667608 Z M59.0084863,42.7500471 L16.9164078,42.7500471 C14.5395451,42.7500471 12.5948392,40.8053412 12.5948392,38.4299686 C12.5948392,36.0531059 14.5395451,34.1084 16.9164078,34.1084 L59.0084863,34.1084 C61.385349,34.1084 63.3285647,36.0531059 63.3285647,38.4299686 C63.3285647,40.8053412 61.385349,42.7500471 59.0084863,42.7500471 Z M71.6039216,22.1316941 L4.32156863,22.1316941 C1.94470588,22.1316941 -1.42108547e-14,20.1869882 -1.42108547e-14,17.8116157 C-1.42108547e-14,15.4347529 1.94470588,13.4900471 4.32156863,13.4900471 L71.6039216,13.4900471 C73.9807843,13.4900471 75.924,15.4347529 75.924,17.8116157 C75.924,20.1869882 73.9807843,22.1316941 71.6039216,22.1316941 Z"
    />
  </svg>
);

export default SvgFilter;
