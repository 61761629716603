import { compositionFunction as CallToActionComposition } from 'src/components/Common/@electron/CallToAction/composition';
import Composition from 'src/lib/HOC/Composition';
import Data from './data';
import JSSPushDownPanel from './index';
import { styles } from './util';

const { compositionFunction, component } = Composition(JSSPushDownPanel)<typeof Data>(props => {
  const { items } = props.fields;
  const itemsLength = items.length;
  const type = itemsLength === 4 ? 'fourPanels' : 'otherPanels';

  return {
    ...CallToActionComposition(props),
    items: items?.map(({ fields }, index) => ({
      id: index,
      title: fields['Item Title']?.value,
      icon: fields['Item Icon'],
      classes: {
        buttonClass: styles[type].button[index],
        containerClass: styles[type].items[index],
        contentClass: styles[type].content[index],
      },
      components: fields.items?.map(({ fields }, index) => ({
        id: index,
        type: fields['Component Type']?.value,
        component: fields?.items,
      })),
    })),
  };
});

export { compositionFunction, component as default };
