// We need to check the fields object and account for different casings,
// and different compound words that mean the same thing.
// ie: [Icon or IconImage], [Headline or HeadLine], [CallToAction or CallToActionText] etc...
// instead of manually checking each variation as we come across them
const determine = (x: string, searchFor: string, fields: { [key: string]: any }) =>
  x.toLowerCase().split(' ').join('').includes(searchFor) && fields?.[x]?.value;

// Look for a variation of { fields.CallToAction }
const getCallToAction = (fields: { [key: string]: any }) => {
  return Object.keys(fields).find(elm => determine(elm, 'calltoaction', fields));
};

// Look for a variation of { fields.Headline }
const getHeadline = (fields: { [key: string]: any }) =>
  Object.keys(fields).find(elm => elm.toLowerCase() === 'headline');

// Look for a variation of { fields.Icon }
const getImage = (fields: { [key: string]: any }) => {
  const getIcon = Object.keys(fields).find(elm => determine(elm, 'icon', fields));
  const image = getIcon && fields[getIcon];
  return image?.value?.src ? image : null;
};

// 1. Does the field have a CTA and 2. Does it have link?
const getLink = (fields: { [key: string]: any }) => {
  const fieldIndex = getCallToAction(fields);
  const link = fieldIndex ? fields[fieldIndex] : null;
  return link?.value?.href ? link : null;
};

export { getCallToAction, getHeadline, getImage, getLink };
