import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgEmail = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M73.232 18.43s2.75-3.004 2.768 1.184V59.67a6.464 6.464 0 0 1-6.464 6.464H6.573A6.464 6.464 0 0 1 .11 59.67L0 19.322c-.018-3.532 2.33-1.82 2.33-1.82L31.464 41.9a11.126 11.126 0 0 0 13.948 0l27.821-23.47ZM34.504 37.203 4.661 12.349S2.35 10 6.482 10h63.619c4.88.018.91 3.114.91 3.114L42.334 37.203a6.41 6.41 0 0 1-7.83 0Z" />
  </svg>
);

export default SvgEmail;
