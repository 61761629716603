import { useSitecoreContext } from 'src/lib/Hooks/SitecoreContextFactory';
import useMediaQuery from 'src/lib/Hooks/useMediaQuery';
import { blockCookieCategoryClass } from '../SiteSettings';

interface IFrameType {
  height: string;
  link: JSS.LinkField;
  mobileLink: JSS.LinkField;
}

const IFrame = ({ height, link, mobileLink }: IFrameType) => {
  const isMobile = !useMediaQuery('md');
  const computedLink = isMobile ? mobileLink : link;
  const hasLink = computedLink?.value?.href;

  const { getComponent } = useSitecoreContext();
  const [siteSettings] = getComponent('Site Settings');
  const isCookieScriptOnPage = siteSettings?.fields?.['Cookie Consent Enable']?.value;

  if (!hasLink) return null;

  return (
    <iframe
      className={`w-full ${isCookieScriptOnPage ? blockCookieCategoryClass : ''}`}
      data-testid="iframe-component"
      src={isCookieScriptOnPage ? undefined : computedLink.value.href}
      data-src={isCookieScriptOnPage ? computedLink.value.href : undefined}
      title={computedLink.value.text || 'external content'}
      {...{ height }}
    />
  );
};

export default IFrame;
