import Link from 'src/components/Common/Link';
import { ButtonGroup } from '../../../Common/@electron/ButtonGroup';
import { FormsMessage } from '../../../Common/@electron/FormsMessage';
import { MediumContainer } from '../../_components/layout';

interface ErrorFields {
  error: {
    'Hide CTA in Drawer': {
      value: boolean;
    };
    Variant: {
      value: string;
    };
    Title: {
      value: string;
    };
    Vehicle: {
      value: string;
    };
    'Primary CTA Label': {
      value: string;
    };
    'Primary CTA Link': {
      value: {
        href: string;
      };
    };
    'Secondary CTA Link': {
      value: {
        href: string;
      };
    };
    Message: {
      value: string;
    };
    'Secondary CTA Label': {
      value: string;
    };
  };
  variant: string;
}

export const Error = ({ error, variant }: ErrorFields) => {
  const primaryBtn = error?.['Primary CTA Link']?.value;
  const secondaryBtn = error?.['Secondary CTA Link']?.value;
  const hideCTA = error?.['Hide CTA in Drawer']?.value && variant === 'Drawer';

  return (
    <MediumContainer>
      <div role="alert" aria-live="polite" aria-atomic="true">
        <FormsMessage
          variant={error?.Variant?.value}
          title={error?.Title?.value}
          description={error?.Message?.value}
        >
          {hideCTA ? null : (
            <ButtonGroup
              primary={
                primaryBtn.href ? (
                  <Link
                    value={{ ...primaryBtn, text: error?.['Primary CTA Label']?.value }}
                    className="btn btn-primary btn-md btn-auto"
                  />
                ) : undefined
              }
              secondary={
                secondaryBtn.href ? (
                  <Link
                    value={{ ...secondaryBtn, text: error?.['Secondary CTA Label']?.value }}
                    className="btn btn-secondary btn-md btn-auto"
                  />
                ) : undefined
              }
            />
          )}
        </FormsMessage>
      </div>
    </MediumContainer>
  );
};
