import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCourtesyDock = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" aria-labelledby={titleId} {...props}>
    <defs>
      <clipPath id="a">
        <rect width="70" height="70" rx="3" ry="3" fill="currentColor" />
      </clipPath>
    </defs>
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width="70" height="70" rx="6" ry="6" fill="currentColor" />
    <path
      d="M32.35,27.69c.19-1.41.37-2.68.4-2.82l.09-.09.09-.09c.14,0,1.61-.06,3.26-.07h2.69c1.65,0,3,.41,3,.93s.25.85.55.74l.54-.21h0l.55.2c.3.11.54-.22.54-.74s1.35-1,3-1h2.69c1.65,0,3.14,0,3.32.07l.09.13.05.08c0,.11.2,1.35.41,2.75s.89,2.75,1.53,3,1-.91.73-2.54l0-.15a20.83,20.83,0,0,0-1-4.2c-.31-.68-1.93-1.22-2-1.22H47.07s-.06-.45-.14-1a6.74,6.74,0,0,0-.31-1.34c-.1-.21-.6-.38-.6-.38H39.87l-.39.17c-.21.1-.44,1-.51,1.56s-.13,1-.13,1-1.3,0-2.9,0H33l-1.27.56c-.68.31-1.4,3.26-1.62,4.89l0,.15c-.22,1.64.12,2.78.75,2.54S32.16,29.1,32.35,27.69Z"
      fill="#fff"
    />
    <path
      d="M66.7,45.11c-.93.32-1.81.65-2,.73s-1.16.51-2.26,1H60.11C59,46.37,58,46,57.89,45.9l-.28-.18-.05,0a9.64,9.64,0,0,0-4.79-1.18h-.23c-.21,0,.22-1.17,1-2.64l3.12-5.43a15.75,15.75,0,0,0,1-3.47c0-.37-1.94-1.37-3.49-1.95l-8.38-3.11-2.81-1h0L40.18,28l-8.35,3.19a16.23,16.23,0,0,0-3.28,1.54c-.26.26.26,2.36.87,3.89l3.24,5.49c.77,1.46.87,2.78.24,2.93a17,17,0,0,0-2.38,1c-.14.08-1.16.51-2.26,1H25.88c-1.12-.43-2.12-.84-2.22-.9l-.28-.18-.05,0-.27-.16c-.32-.14-.59-1.6-.6-3.25l0-4.2c0-1.65.49-3,1.1-3A5.17,5.17,0,0,0,25.1,35c.25-.11.45-1,.45-1.44a3.56,3.56,0,0,0-.21-1.19c-.11-.25-2.05-.44-3.7-.43L3,32c-1.65,0-3,.75-3,1.65s1.35,1.64,3,1.63l13.13-.06a3,3,0,0,1,3,3l0,3.45c0,1.65-.17,3-.41,3h-.24a9.64,9.64,0,0,0-4.78,1.23l0,0-.28.19c-.11.06-1.1.48-2.2.92H8.83c-1.12-.43-2.14-.85-2.27-.93a28,28,0,0,0-3.22-.52S3,44.36,1.92,44.65A2.5,2.5,0,0,0,0,46.73,1.2,1.2,0,0,0,1.52,48c.83-.18,3.31.83,3.44.91s1.5.64,3,1.26a8.84,8.84,0,0,0,2,.1h.17a9.89,9.89,0,0,0,1.91-.11c1.53-.63,2.89-1.21,3-1.29s1.16-.51,2.27-1h2.38c1.12.43,2.14.85,2.28.93s1.5.64,3,1.26a8.84,8.84,0,0,0,2,.1h.17A9.89,9.89,0,0,0,29.11,50c1.53-.63,2.84-1.18,2.92-1.23a2.49,2.49,0,0,1,.37-.16l.11-.06a6.74,6.74,0,0,1,6.31,0l.11.06a2.46,2.46,0,0,1,.37.16c.08,0,1.39.58,2.92,1.19a8.84,8.84,0,0,0,2,.1h.17A9.89,9.89,0,0,0,46.29,50c1.53-.63,2.89-1.21,3-1.29s1.16-.51,2.27-1H54c1.12.43,2.14.85,2.28.93s1.5.64,3,1.26a8.84,8.84,0,0,0,2,.1h.17a9.89,9.89,0,0,0,1.91-.11c1.53-.63,2.89-1.21,3-1.29A14.79,14.79,0,0,1,68.31,48c.93-.22,1.69-.86,1.68-1.42S67.63,44.79,66.7,45.11Z"
      fill="#fff"
    />
  </svg>
);

export default SvgCourtesyDock;
