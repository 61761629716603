import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgTwitter = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#1da1f2"
      d="M56 64H8a8 8 0 0 1-8-8V8a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v48a8 8 0 0 1-8 8z"
    />
    <path
      fill="#fff"
      d="M24.576 48.256c15.088 0 23.344-12.512 23.344-23.344 0-.352 0-.7-.016-1.056A16.715 16.715 0 0 0 52 19.6a16.628 16.628 0 0 1-4.72 1.3 8.255 8.255 0 0 0 3.62-4.548 16.32 16.32 0 0 1-5.216 1.984 8.207 8.207 0 0 0-14.192 5.616 9.068 9.068 0 0 0 .208 1.872 23.3 23.3 0 0 1-16.912-8.576 8.238 8.238 0 0 0 2.544 10.96 8.3 8.3 0 0 1-3.712-1.024v.116a8.222 8.222 0 0 0 6.576 8.048 8.252 8.252 0 0 1-3.7.144 8.2 8.2 0 0 0 7.664 5.7A16.456 16.456 0 0 1 13.968 44.7a15.789 15.789 0 0 1-1.952-.112 23.338 23.338 0 0 0 12.56 3.664"
    />
  </svg>
);

export default SvgTwitter;
