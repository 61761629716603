import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Minus = ({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect x="17.87" y="32.62" width="34.25" height="4.76" rx="1.89" ry="1.89" fill="currentColor" />
  </svg>
);

export default Minus;
