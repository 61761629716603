import React, { createContext, useCallback, useContext, useReducer } from 'react';
import { Action, Dispatch, ExtractParameters, NavContextType, ReducerType } from './types';

const NavContext = createContext<NavContextType>({
  state: {},
  dispatch: () => {},
});

const useNavContext = () => {
  return useContext(NavContext);
};

const navReducer: ReducerType = (state, action) => {
  const { panel, prev, currentItems, parent, component } = state;

  switch (action.type) {
    case 'open':
      return { direction: 0, ...state, ...action.payload };
    case 'close':
      return { prev, currentItems, parent, component };
    case 'next':
      return {
        direction: 100,
        panel,
        prev: { prev, currentItems, parent, component },
        component,
        ...action.payload,
      };
    case 'prev':
      return { direction: -100, panel, ...prev };
    case 'setSlideIndex':
      return {
        slideData: action.payload?.slideData,
        slideIndex: action.payload?.slideIndex,
        slideDirection: action.payload?.slideDirection,
      };
    default:
      throw new Error();
  }
};

const NavContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatchFunction] = useReducer(navReducer, {});
  const dispatch: Dispatch = useCallback(
    <T extends Action['type']>(type: T, payload?: ExtractParameters<Action, T>) =>
      dispatchFunction({ type, payload }),
    []
  );

  return <NavContext.Provider value={{ state, dispatch }}>{children}</NavContext.Provider>;
};

export { NavContextProvider, useNavContext };
