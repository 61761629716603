import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgGasFired = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M40.475,27.61c-2.46-2.64-4.59-4.92-4.4-8.53,.28-5.12,3.11-7.23,3.22-7.32,.294-.216,.357-.629,.142-.923-.161-.219-.439-.317-.702-.247-1.657,.518-3.22,1.298-4.63,2.31-2.905,1.906-4.668,5.135-4.7,8.61v.45c-1.516-1.637-3.427-2.856-5.55-3.54-.352-.094-.714,.116-.807,.468-.07,.263,.028,.541,.247,.702,0,0,2.31,1.69,2.53,5.77,.15,2.84-1.54,4.65-3.49,6.75-2.22,2.4-4.71,5.11-4.71,9.89-.169,4.132,1.928,8.027,5.47,10.16,.093,.047,.196,.071,.3,.07h0c.359,.011,.659-.271,.67-.63,0-.01,0-.02,0-.03,0-.108-.028-.215-.08-.31-2.17-7.16,.37-10.3,3.06-13.61,1.87-2.31,3.79-4.69,4.29-8.41,1.03,1.794,2.228,3.485,3.58,5.05,3.4,4.19,6.62,8.15,3.8,17.27-.106,.349,.092,.717,.44,.823,.163,.049,.338,.034,.49-.043,.27-.14,6.72-3.57,6.74-12.52-.01-5.89-3.14-9.25-5.91-12.21Zm-9.28,11.68c-.209,.116-.312,.359-.25,.59,.47,1.78-.81,4-2,5.57-1.94,2.63-1.69,4.61-1.68,4.62-.112,2.472,1.801,4.566,4.273,4.678,.119,.005,.238,.006,.357,.002h.11c2.687-.203,4.73-2.498,4.62-5.19,0-.19,.56-4.63-4.8-10.19-.167-.167-.426-.2-.63-.08Z"
    />
  </svg>
);

export default SvgGasFired;
