import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgArrow = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 32"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.445 15.666.465 28.741a1.993 1.993 0 0 0 .226 2.789 1.944 1.944 0 0 0 2.76-.228l12.084-14.389a2 2 0 0 0-.034-2.6L3.418.66A1.943 1.943 0 0 0 .653.5 1.993 1.993 0 0 0 .5 3.3z" />
  </svg>
);

export default SvgArrow;
