import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgLink = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M69.325 5.825c7.993 7.993 7.728 21.264-.59 29.583-.063.061-.124.12-.189.178l-11.51 10.371c-3.983 3.936-9.191 6.18-14.684 6.325-.189.004-.376.005-.563.005-5.424 0-10.46-2.059-14.236-5.835a4.995 4.995 0 0 1 0-7.06 4.995 4.995 0 0 1 7.058.001c1.95 1.95 4.59 2.985 7.48 2.909 2.97-.078 5.805-1.32 7.984-3.5a4.29 4.29 0 0 1 .189-.178l11.494-10.36c4.347-4.428 4.582-11.305.508-15.378-4.1-4.102-11.038-3.835-15.465.59a5.61 5.61 0 0 1-.187.177l-4.102 3.697a4.992 4.992 0 0 1-6.682-7.416l4.01-3.614c8.325-8.224 21.523-8.454 29.485-.495ZM32.639 57.073a4.99 4.99 0 1 1 6.68 7.414l-4.009 3.615c-4.256 4.204-9.783 6.32-15.237 6.32-5.212 0-10.356-1.934-14.247-5.825-7.993-7.993-7.728-21.263.59-29.583.06-.06.124-.12.188-.178l11.51-10.373c3.983-3.935 9.192-6.178 14.684-6.323 5.663-.133 10.893 1.925 14.8 5.83a4.988 4.988 0 0 1-.002 7.059 4.988 4.988 0 0 1-7.057 0c-1.952-1.95-4.615-3.007-7.48-2.908-2.969.077-5.805 1.32-7.985 3.5-.06.059-.122.12-.187.177l-11.496 10.36c-4.345 4.429-4.58 11.304-.506 15.38 4.1 4.102 11.038 3.835 15.465-.591.059-.061.122-.12.187-.178l4.102-3.696Z"
    />
  </svg>
);

export default SvgLink;
