import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useRef, useState } from 'react';
import refreshSession from 'src/api/duke/session/refreshSession';
import Button from 'src/components/Common/@electron/Button';
import { Modal } from 'src/components/Common/@electron/Modal';
import track from 'src/lib/Analytics';
import useVisibilityChange from 'src/lib/Hooks/useVisibilityChange';
import getCookies from 'src/lib/Utils/Cookies';
import { useCountdown } from './countdownTimer';

const EXPIRE_SESSION_TIME_IN_MILLISECONDS = 18 * 60 * 1000;
export const COOKIE_SESSION_TIMESTAMP = 'de.timestamp';

const ModalController = ({
  isAuthenticated,
  ...rest
}: {
  btnText: JSS.TextField;
  id: string;
  isAuthenticated: boolean;
  serverTimestamp: string;
  redirectUrl: string;
  textPhraseOne: JSS.TextField;
  textPhraseTwo: JSS.TextField;
  title: JSS.TextField;
}) => {
  if (isAuthenticated) {
    return <SessionTimer reset={function (): void {}} {...rest} />;
  }

  return null;
};

const SessionTimer = (
  props: React.PropsWithChildren<Parameters<typeof ModalSessionExpiration>[0]>
) => {
  const [showModal, setShowModal] = useState(false);
  const sessionClock = useRef<any>();
  let initTimeDiff = 0;

  if (props.serverTimestamp) {
    // Get initial client timestamp
    const initTimestamp = Date.now();
    // Get initial server timestamp from props
    const initServerTimestamp = Date.parse(props.serverTimestamp);
    // Get diff between client and server timestamp - used to adjust for misconfigured client time/timezone settings
    // Math is checking if the diff is greater than 5 minutes
    initTimeDiff =
      Math.ceil(Math.abs(initTimestamp - initServerTimestamp) / 60000) > 5
        ? initTimestamp - initServerTimestamp
        : 0;
  }

  useEffect(() => {
    start();
    return () => {
      clearTimeout(sessionClock.current);
    };
  }, []);

  const pageVisible = useVisibilityChange();

  useEffect(() => {
    if (pageVisible) {
      start();
    } else {
      clearTimeout(sessionClock.current);
    }
  }, [pageVisible]);

  const sessionTimeRemaining = (): number => {
    const cookies = getCookies();
    const timestamp = Date.parse(cookies?.[COOKIE_SESSION_TIMESTAMP]) || Date.now() - initTimeDiff;

    // Calculate milliseconds since last request using stored cookie time
    const lastSessionTime = Date.now() - initTimeDiff - timestamp;
    return EXPIRE_SESSION_TIME_IN_MILLISECONDS - lastSessionTime;
  };

  const trackSessionExpiration = () => {
    track({
      event: 'send-VPV',
      'vpv-name': '/vpv/de/load/timeout-error/error/system-timeout/na/na',
    });
    track.component({
      category: 'timeout-error',
      action: 'na-|-system-timeout-|-na',
      label: window?.location?.pathname || '',
      event: 'event-click',
    });
  };

  const start = () => {
    const sessionLength = sessionTimeRemaining();

    // Session has been inactive longer than 20 minutes, force redirect with no modal
    if (sessionLength <= 0) {
      window.location.href = props.redirectUrl;
      return;
    }

    clearTimeout(sessionClock.current);

    // Show modal 60 seconds before session expires
    sessionClock.current = setTimeout(() => {
      // Check that session hasn't been refreshed after starting timer
      if (sessionTimeRemaining() - 60 * 1000 <= 0) {
        trackSessionExpiration();
        setShowModal(true);
      } else {
        start();
      }
    }, sessionLength - 60 * 1000);
  };

  const reset = () => {
    refreshSession().then(() => {
      setShowModal(false);
      start();
    });
  };

  if (!showModal) return null;

  return <ModalSessionExpiration {...props} reset={reset} />;
};

const ModalSessionExpiration = ({
  btnText,
  id,
  redirectUrl,
  reset,
  textPhraseOne,
  textPhraseTwo,
  title,
}: {
  btnText: JSS.TextField;
  id: string;
  serverTimestamp: string;
  redirectUrl: string;
  reset: () => void;
  textPhraseOne: JSS.TextField;
  textPhraseTwo: JSS.TextField;
  title: JSS.TextField;
}) => {
  const { time } = useCountdown(60);

  useEffect(() => {
    if (time === 0) {
      window.location.href = redirectUrl;
    }
  }, [time]);

  return (
    <Modal
      id={id}
      isOpen={true}
      onClose={reset}
      titleVariant="text-center"
      title={<span className="text-red">{title.value}</span>}
      description={
        <div>
          <Text field={textPhraseOne} tag="span" />
          <Text field={textPhraseTwo} tag="span" />
        </div>
      }
    >
      <div className="text-center pt-32">
        <Button {...btnText} onClick={reset} />
      </div>
    </Modal>
  );
};

export default ModalController;
