import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgFishingPlatform = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width="70" height="70" rx="6" ry="6" fill="currentColor" />
    <path
      d="M37.85,27.63a51,51,0,0,0,15.43-2.81,1.5,1.5,0,0,1,1,0l4.74,1.89a.59.59,0,0,0,.23,0,.65.65,0,0,0,.61-.6.59.59,0,0,0-.23-.47L57.5,23.07a1,1,0,0,1-.18-1.13l2.22-3.45a.6.6,0,0,0-.74-.87l-4.54,2a1.42,1.42,0,0,1-1.06,0,39.51,39.51,0,0,0-13.7-2.74c-8.13,0-12,3.4-12.13,3.55a.6.6,0,0,0,.12,1l2.17,1.14c.34.18,0,.35,0,.35-.74.24-1.64.56-2.43.84a.6.6,0,0,0-.21,1C27.15,24.83,30.12,27.63,37.85,27.63Zm-4.88-8a1,1,0,1,1-1,1A1,1,0,0,1,33,19.65Z"
      fill="#fff"
    />
    <path
      d="M60.74,51.23l-2.17-2.65a1,1,0,0,1-.18-1.13L60.61,44a.6.6,0,0,0-.74-.87l-4.54,2a1.42,1.42,0,0,1-1.06,0,39.53,39.53,0,0,0-13.7-2.74c-8.13,0-12,3.4-12.13,3.55a.6.6,0,0,0,.12,1L30.73,48c.34.18,0,.35,0,.35-.74.24-1.64.56-2.43.84a.6.6,0,0,0-.21,1c.12.12,3.1,2.92,10.82,2.92a51,51,0,0,0,15.43-2.81,1.5,1.5,0,0,1,1,0l4.74,1.89a.59.59,0,0,0,.23,0,.65.65,0,0,0,.61-.6A.59.59,0,0,0,60.74,51.23ZM34,47.1a1,1,0,1,1,1-1A1,1,0,0,1,34,47.1Z"
      fill="#fff"
    />
    <path
      d="M36.5,37.61l4.74,1.89a.59.59,0,0,0,.23,0,.65.65,0,0,0,.61-.6.59.59,0,0,0-.23-.47l-2.17-2.65a1,1,0,0,1-.18-1.13l2.22-3.45a.6.6,0,0,0-.74-.87l-4.54,2a1.42,1.42,0,0,1-1.06,0,39.51,39.51,0,0,0-13.7-2.74c-8.13,0-12,3.4-12.13,3.55a.6.6,0,0,0,.12,1l2.17,1.14c.34.18,0,.35,0,.35-.74.24-1.64.56-2.43.84a.6.6,0,0,0-.21,1c.12.12,3.1,2.92,10.82,2.92a51,51,0,0,0,15.43-2.81A1.5,1.5,0,0,1,36.5,37.61ZM15.16,34.35a1,1,0,1,1,1-1A1,1,0,0,1,15.16,34.35Z"
      fill="#fff"
    />
  </svg>
);

export default SvgFishingPlatform;
