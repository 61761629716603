import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgX = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="m38 30.702 29.19-29.19a5.16 5.16 0 1 1 7.299 7.297L45.298 38l29.19 29.19a5.16 5.16 0 0 1-7.297 7.299L38 45.298 8.81 74.488a5.16 5.16 0 0 1-7.299-7.297L30.702 38 1.512 8.81A5.16 5.16 0 1 1 8.808 1.51L38 30.702Z"
    />
  </svg>
);

export default SvgX;
