import track from 'src/lib/Analytics';
import { TrackLabel, SetupAnalytics } from './types';

// Download
// event-category: 'news_resource',
// event-action: '<<pdf headline>>-|-<<url>>'
// event-label: '<<component headline>>-|-download'
// guid: <<guid>
// event: 'event-click'

// Expansion
// event-category: 'news_resource',
// event-action: '<<cta link text>>' (show-more | show-less)
// event-label: '<<component headline>>-|-expansion'
// guid: <<guid>
// event: 'event-click'

// Filter
// event-category: 'news_resource',
// event-action: '<<selected menu option>>'
// event-label: '<<component headline>>-|-filter'
// guid: <<guid>
// event: 'event-click'

const makeLabel = (headline?: string) => {
  const headlineValue = headline || '(not-set)';
  return (labelType: TrackLabel) => `${headlineValue} | ${labelType}`;
};

const setupAnalytics: SetupAnalytics =
  ({ headline, guid = '' }) =>
  ({ label, action }) => {
    const event = {
      action,
      category: 'news_resource',
      label: makeLabel(headline)(label),
      guid,
    };

    return track.component(event);
  };

export { setupAnalytics };
