import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PUBLIC_SITE_NAME } from 'src/RouteHandler';
import {
  getAccountText,
  getProfileLinks,
} from 'src/components/Common/@electron/PrimaryNav/composition';
import Jurisdiction from 'src/components/Common/Jurisdiction/composition';
import withErrorBoundary from 'src/lib/HOC/ErrorBoundary/withErrorBoundary';
import { useSitecoreContext } from 'src/lib/Hooks/SitecoreContextFactory';
import SignInMAM from '../../Auth/SignInMAM';
import { Drawer } from '../@electron/Drawer';
import SignOut from '../SignOut';
import NavTree from './NavTree/composition';
import { useNavContext } from './context';

const useComponentProps = () => {
  const { context, getComponent } = useSitecoreContext();
  return useMemo(() => {
    const isMicroSite = context?.site?.name !== PUBLIC_SITE_NAME;
    const [navProps] = getComponent('NavItems');
    const [signInProps] = getComponent('Sign In');
    const [navWrapperProps] = getComponent('NavWrapper');
    // No signoutProps for microsite, so using an empty value
    let headerProps = { fields: {}, uid: '' };
    // getting PrimaryNav props only if it's not a microsite
    if (!isMicroSite) {
      [headerProps] = getComponent('PrimaryNav');
    }
    const signOutProps = {
      profileLinks: getProfileLinks(headerProps.fields),
      title: getAccountText(headerProps.fields),
      guid: headerProps.uid,
    };

    type Item = { name: string };

    const jurisProps = navWrapperProps?.fields?.items?.find(
      (item: Item) => item?.name === 'Jurisdiction Selector'
    );

    const drawerProps = navWrapperProps?.fields?.items?.find(
      (item: Item) => item?.name === 'Drawer Navigation'
    );

    return { navProps, signInProps, signOutProps, jurisProps, drawerProps };
  }, [context?.route?.placeholders]);
};

const NavWrapper = () => {
  const { state, dispatch } = useNavContext();
  const location = useLocation();
  const { navProps, jurisProps, drawerProps, signOutProps } = useComponentProps();

  const closePanel = () => dispatch('close');

  useEffect(() => {
    closePanel();
  }, [location.pathname, location.search]);

  const headers = {
    SignIn: drawerProps?.fields?.['SignIn Title']?.value,
    SignOut: drawerProps?.fields?.['SignOut Title']?.value,
    Jurisdiction: drawerProps?.fields?.['Jurisdiction Title']?.value,
    NavTree: drawerProps?.fields?.['NavTree Title']?.value,
  };

  const header = state.component ? headers[state.component] : headers.NavTree;

  return (
    <>
      <Drawer
        variant="left"
        isOpen={state.panel === 'left'}
        onClose={closePanel}
        name={header}
        header={header}
      >
        {state.component === 'Jurisdiction' ? (
          <Jurisdiction {...jurisProps} initialDirection="100" />
        ) : (
          <NavTree {...navProps} />
        )}
      </Drawer>
      <Drawer
        variant="right"
        isOpen={state.panel === 'right'}
        onClose={closePanel}
        name={header}
        header={header}
      >
        {state.component &&
          {
            SignIn: <SignInMAM key={state.panel} />,
            SignOut: <SignOut {...signOutProps} />,
            Jurisdiction: jurisProps && <Jurisdiction key={state.panel} {...jurisProps} />,
            NavTree: null,
          }[state.component]}
      </Drawer>
    </>
  );
};

export default withErrorBoundary(NavWrapper);
