import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgGlobe = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M55.525 19.779a60.416 60.416 0 0 0-4.923-9.143c-2.689-4.08-5.316-7.058-6.924-8.736a37.017 37.017 0 0 1 22.386 12.352 58.922 58.922 0 0 1-10.54 5.527ZM39.672 53.864c4.296.154 8.556.94 12.762 2.358-2.76 6.226-7.02 12.327-12.762 18.257V53.864Zm4.01 21.341c5.3-5.766 9.302-11.7 11.97-17.77a57.172 57.172 0 0 1 10.269 5.578 37.004 37.004 0 0 1-22.24 12.192Zm12.51-34.773c-.168 4.31-1.025 8.575-2.56 12.787a50.322 50.322 0 0 0-13.96-2.322V40.388l16.52.044Zm.686 13.914c1.656-4.567 2.576-9.209 2.743-13.902l15.447.042a36.889 36.889 0 0 1-6.933 19.75c-3.798-2.52-7.564-4.459-11.257-5.89Zm2.733-17.332c-.217-5.173-1.342-9.933-2.867-14.153a67.991 67.991 0 0 0 11.449-5.913 36.873 36.873 0 0 1 6.897 20.108l-15.48-.042Zm-43.24 3.309c.158 4.79 1.098 9.524 2.808 14.185a60 60 0 0 0-11.276 5.668c-4.066-5.653-6.56-12.487-6.901-19.895l15.369.042Zm7.188 15.882c2.928-.965 6.091-1.74 9.473-2.128 1.074-.125 2.143-.17 3.213-.214V74.39c-5.704-5.907-9.939-11.983-12.686-18.185Zm-1.132-2.816c-1.586-4.302-2.464-8.658-2.626-13.057l16.443.046V50.93c-4.982.22-9.63 1.152-13.817 2.459ZM53.53 24.046c1.399 3.878 2.428 8.239 2.645 12.958l-16.503-.045v-10.26c4.907-.207 9.595-1.224 13.858-2.653ZM19.833 36.905c.226-4.697 1.252-9.037 2.649-12.895 4.824 1.653 9.467 2.498 13.763 2.669v10.27l-16.412-.044ZM52.3 20.945c-2.89.943-6.006 1.701-9.336 2.087-1.099.126-2.195.175-3.291.22V2.813c1.467 1.425 5.037 5.09 8.13 9.804a56.91 56.91 0 0 1 4.497 8.328ZM36.19 2.891v-.007l.054-.187v20.543c-4.22-.16-8.405-.93-12.538-2.31a56.544 56.544 0 0 1 4.492-8.315c3.986-6.076 7.951-9.688 7.991-9.724ZM19.266 22.824c-1.52 4.197-2.64 8.93-2.869 14.07L1.001 36.85a36.845 36.845 0 0 1 7.024-20.084c3.76 2.51 7.535 4.533 11.242 6.057Zm-9.28 39.998a58.81 58.81 0 0 1 10.339-5.438c2.666 6.082 6.669 12.03 11.978 17.807A37.018 37.018 0 0 1 9.986 62.822Zm15.409-52.185a60.25 60.25 0 0 0-4.902 9.097 57.017 57.017 0 0 1-10.365-5.609 37.012 37.012 0 0 1 22.177-12.21c-1.61 1.679-4.23 4.654-6.91 8.722Z"
    />
  </svg>
);

export default SvgGlobe;
