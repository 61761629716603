import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPlay = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.956 55.768a2.38 2.38 0 0 1-.712-1.678V22.493a2.384 2.384 0 0 1 3.715-1.996l25.226 15.785a2.38 2.38 0 0 1 .031 3.926L31.992 56.022a2.376 2.376 0 0 1-3.036-.254M37.998 8.59C21.785 8.59 8.59 21.785 8.59 38s13.195 29.41 29.408 29.41c16.215 0 29.41-13.195 29.41-29.41S54.213 8.59 37.998 8.59m0 67.41C17.044 76 0 58.956 0 38S17.044 0 37.998 0c20.956 0 38 17.044 38 38s-17.044 38-38 38"
    />
  </svg>
);

export default SvgPlay;
