export const returnCode = {
  SUCCESS: 0,
  EMAIL_VERIFICATION_SUCCESS: 1,
  PASSWORD_FAILED: 370027013,
  PASSWORD_VALIDATION: 370027012,
  REGISTRATION_LINK_EXPIRED: 370000009,
  VERIFICATION_EMAIL_RETRY: 370001818,
  ISU_OR_SEW_FAILED: 370001819,
  SEW_TIMEOUT: 370001820,
  ADD_BUSINESS_EXP: 370012082,
  NO_MATCH_GENERIC: 370027000,
  RESET_PASSWORD: 370027004,
  TERMS_CONDITIONS: 370027006,
  VALIDATION: 370027008,
  INVALID_VERIFICATION_CODE: 370027013,
  MAXIMUM_LOGIN_ATTEMPTS_EXCEEDED: 370027014,
  CONTACT_CUSTOMER_SERVICE: 370027015,
  VERIFICATION_CODE_EXPIRED: 370027016,
  VALIDATE_EMAIL: 370027017,
  NO_MATCH_USERNAME: 370027018,
  NO_MATCH_PASSWORD: 370027019,
} as const;
