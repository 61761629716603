import ZipForm from 'src/components/Common/Jurisdiction/Form';
import { useStep } from 'src/components/Common/Jurisdiction/context';
import { StepProps } from 'src/components/Common/Jurisdiction/types';
import { BackButton, Header } from './index';

const Zip = ({ headline, id, backToStatesText, ...rest }: StepProps) => {
  const { set } = useStep();

  return (
    <>
      <BackButton onClick={() => set('List')}>{backToStatesText}</BackButton>
      <div className="container-xl flex flex-col gap-24 text-left md:text-center">
        <Header {...{ headline, ...rest }} />
        <ZipForm headline={headline} id={id} />
      </div>
    </>
  );
};

export default Zip;
