import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSortLeft = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m31.171 3.321.953.953a2.57 2.57 0 0 1 0 3.632L11.303 28.791l50.13-.053a2.567 2.567 0 0 1 2.568 2.566v1.349a2.566 2.566 0 0 1-2.563 2.568h-.005l-50.07.051 20.761 20.822a2.57 2.57 0 0 1 0 3.632l-.953.953a2.57 2.57 0 0 1-3.632 0L1.769 34.83c-.021-.019-.046-.035-.067-.056l-.95-.952a2.587 2.587 0 0 1 0-3.646l.953-.956c.021-.019.046-.035.067-.056L27.537 3.321a2.57 2.57 0 0 1 3.632 0"
    />
  </svg>
);

export default SvgSortLeft;
