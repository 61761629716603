import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Parenthesis = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 99 99"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#005984"
      d="M16.6992188,97.9101562 C10.9960938,89.8242188 6.78710938,81.7871094 4.07226562,73.7988281 C1.35742188,65.8105469 0,57.5195312 0,48.9257812 C0,40.3320312 1.35742188,32.0410156 4.07226562,24.0527344 C6.78710938,16.0644531 10.9960938,8.046875 16.6992188,0 L25.8984375,0 C20,8.0859375 15.6640625,16.0742188 12.890625,23.9648438 C10.1171875,31.8554688 8.73046875,40.1757812 8.73046875,48.9257812 C8.73046875,57.6757812 10.1171875,65.9960938 12.890625,73.8867188 C15.6640625,81.7773438 20,89.7851562 25.8984375,97.9101562 L16.6992188,97.9101562 Z"
    ></path>
  </svg>
);

export default Parenthesis;

// SVG sent by Rachel:

// <?xml version="1.0" encoding="UTF-8"?>
// <svg width="27px" height="99px" viewBox="0 0 27 99" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
//     <title>(</title>
//     <g id="R6---4/8---EV-Calculator" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//         <g id="Misc" transform="translate(-513.000000, -2769.000000)" fill="#005984" fill-rule="nonzero">
//             <g id="(" transform="translate(513.636719, 2769.816406)">
//                 <path d="M16.6992188,97.9101562 C10.9960938,89.8242188 6.78710938,81.7871094 4.07226562,73.7988281 C1.35742188,65.8105469 0,57.5195312 0,48.9257812 C0,40.3320312 1.35742188,32.0410156 4.07226562,24.0527344 C6.78710938,16.0644531 10.9960938,8.046875 16.6992188,0 L25.8984375,0 C20,8.0859375 15.6640625,16.0742188 12.890625,23.9648438 C10.1171875,31.8554688 8.73046875,40.1757812 8.73046875,48.9257812 C8.73046875,57.6757812 10.1171875,65.9960938 12.890625,73.8867188 C15.6640625,81.7773438 20,89.7851562 25.8984375,97.9101562 L16.6992188,97.9101562 Z" id="Path"></path>
//             </g>
//         </g>
//     </g>
// </svg>
