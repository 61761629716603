const styleOptions = {
  featuredAlone: {
    imgClasses: 'sm:w-1/2 sm:rounded-t-none sm:rounded-l-md',
    imgSrcSet: [{ w: 400 }, { w: 800 }, { w: 488 }, { w: 976 }, { w: 600 }, { w: 1200 }],
    imgSizes: '(min-width: 1280px) 600px, (min-width: 1024px) 488px, 400px',
    textContainer: 'justify-center md:p-32 sm:w-1/2 sm:border-t sm:border-gray-lighter',
    title: 'sm:flex-none lg:mt-12 sm:text-2xl',
    body: 'sm:line-clamp-3',
    button: 'btn-primary sm:btn-auto',
  },
  featured: {
    imgClasses: 'md:w-1/2 md:rounded-t-none md:rounded-l-md',
    imgSrcSet: [{ w: 316 }, { w: 632 }, { w: 488 }, { w: 976 }, { w: 600 }, { w: 1200 }],
    imgSizes: '(min-width: 1280px) 600px, (min-width: 1024px) 488px, 316px',
    textContainer: 'justify-center md:p-32 md:w-1/2 md:border-t md:border-gray-lighter',
    title: 'md:flex-none lg:mt-12 md:text-2xl',
    body: 'hidden md:initial md:line-clamp-3',
    button: 'btn-primary md:btn-auto',
  },
  regular: {
    imgClasses: '',
    imgSrcSet: [{ w: 316 }, { w: 632 }, { w: 230 }, { w: 460 }, { w: 384 }, { w: 768 }],
    imgSizes:
      '(min-width: 1280px) 384px, (min-width: 1024px) 316px, (min-width: 768px) 230px, 316px',
    textContainer: 'md:p-16 lg:p-24',
    title: '',
    body: 'md:line-clamp-3',
    button: 'btn-secondary',
  },
} as const;

export default styleOptions;
