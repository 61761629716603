enum Jurisdictions {
  IN01 = 'IN01',
  NC01 = 'NC01',
  NC02 = 'NC02',
  SC01 = 'SC01',
  SC02 = 'SC02',
  FL01 = 'FL01',
  KY01 = 'KY01',
  OH01 = 'OH01',
}

const progressJurisdictions = [Jurisdictions.NC02, Jurisdictions.SC02];

export { Jurisdictions, progressJurisdictions };
