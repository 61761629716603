import React from 'react';
import CallToAction from 'src/components/Common/@electron/CallToAction';
import LazyImage from 'src/components/Common/LazyImage';
import Link from 'src/components/Common/Link';
import RichText from 'src/components/Common/RichText_Old';
import track from 'src/lib/Analytics';
import { LatestNewsTypes } from './types';

const Time = ({ date, ...rest }: { date: string } & React.HTMLProps<HTMLTimeElement>) => {
  const mmDDYYYY = new Date(date).toLocaleDateString();
  return <time {...rest}>{mmDDYYYY}</time>;
};

const LatestNewsComponent = ({ items }: LatestNewsTypes) => (
  <section className="w-full px-12">
    <ul className="max-w-md mx-auto space-y-32 lg:space-y-0 lg:flex lg:max-w-lg xl:max-w-xl">
      {items.map(({ analytics, category, date, image, link, title }, index) => (
        <li className="w-full xl:w-4/12 lg:ml-16 first:mt-0 first:ml-0 group" key={index}>
          <Link className="flex lg:block" href={link} onClick={() => track.component(analytics)}>
            <LazyImage
              className="h-76 w-76 md:w-1/4 md:h-auto mr-24 object-cover ease-in transition-opacity duration-200 lg:mr-0 lg:mb-24 lg:w-full group-hover:opacity-80"
              srcSet={[{ mw: 1280 }, { mw: 768 }, { mw: 450 }]}
              {...image}
            />
            <div>
              {category && <h2 className="js-category block w-full uppercase">{category}</h2>}
              <Time date={date} className="block w-full mb-4 lg:mb-0 md:text-sm" />
              {title && (
                <RichText
                  className="inline text-lg group-hover:bg-teal-light transition-all ease-in duration-200 text-blue lg:mt-12"
                  field={title}
                  tag="h3"
                />
              )}
            </div>
          </Link>
        </li>
      ))}
    </ul>
  </section>
);

const LatestNews = ({ items, ...rest }: LatestNewsTypes) => (
  <CallToAction {...rest}>
    <LatestNewsComponent {...{ items, ...rest }} />
  </CallToAction>
);

export { LatestNewsComponent, Time };
export default LatestNews;
