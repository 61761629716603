import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import Button from 'src/components/Common/@electron/Button';
import LazyImage from 'src/components/Common/LazyImage';
import track from 'src/lib/Analytics';
import { NewsBannerProps } from './types';

const NewsBanner = ({ analytics, bgColor, body, image, link, title }: NewsBannerProps) => {
  const textColor = bgColor === 'bg-blue' ? 'text-white' : 'text-gray-darker';
  const richTextColor = bgColor === 'bg-blue' ? 'text-white' : 'text-gray-dark';
  const btnVariant = bgColor === 'bg-blue' ? 'primary-reversed' : 'secondary';

  // News Banner will set to the light Yellow Background with yellow line (at the top).
  const newsBannnerBgColor =
    bgColor === 'bg-yellow-dark/10'
      ? 'bg-yellow-dark/10 before:absolute before:left-0 before:top-0 before:right-0 before:h-6 before:bg-yellow-dark'
      : bgColor;
  const imgStyles = image?.value?.src?.includes('.svg')
    ? 'md:mb-16 lg:mb-0 icon-48 sm:icon-76'
    : 'md:-my-12 object-cover aspect-1/1 icon-76 sm:icon-122 rounded-full';

  return (
    <section className="relative">
      <div
        className={`relative overflow-hidden px-16 sm:px-24 py-24 ${newsBannnerBgColor} container-4xl rounded-md`}
      >
        <div className="container-3xl flex flex-col md:flex-row items-center lg:justify-center">
          <div className="flex-shrink-0 mx-auto md:mx-0 mb-12 md:mb-0">
            <LazyImage
              alt=""
              {...image}
              className={`flex-shrink-0 mx-auto md:mx-0 mb-12 ${imgStyles}`}
            />
          </div>

          <div className="max-w-sm md:max-w-lg lg:max-w-xl md:ml-24 lg:ml-32 text-center md:text-left">
            <Text
              id="news-banner-title"
              className={`text-xl font-bold ${textColor}`}
              field={title}
              tag="h2"
            />
            {body?.value && <RichText className={`mt-4 ${richTextColor}`} field={body} tag="p" />}
          </div>

          {link && (
            <div className="flex-shrink-0 mt-12 md:mt-0 md:ml-32 lg:ml-48">
              <Button
                id="news-banner-button"
                aria-labelledby="news-banner-button news-banner-title"
                variant={btnVariant}
                {...link}
                onClick={() => track.component(analytics)}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default NewsBanner;
