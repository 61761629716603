/* eslint-disable default-param-last */
import track from 'src/lib/Analytics';
import Composition from 'src/lib/HOC/Composition';
import data from './data';
import Footer from './index';
import { FooterProps, GetLinkAndImage, ImageObj, LinkObj } from './types';

const { component, compositionFunction } = Composition(Footer)<{
  fields: typeof data.fields;
  hideTagLine: boolean;
}>(({ fields, hideTagLine }) => {
  const getLinkAndImage: GetLinkAndImage = (whichField = [], image) =>
    whichField.reduce((acc: (LinkObj & Partial<ImageObj>)[], curr: any) => {
      const linkText =
        curr.fields?.['Link Text Override']?.value ||
        curr.fields?.['Link URL']?.value?.text ||
        curr.displayName;

      const link = {
        value: {
          ...curr.fields?.['Link URL']?.value,
          text: linkText,
        },
      };

      const analytics = {
        category: 'footer_navigation',
        label: linkText,
        action: curr.fields?.['Link URL']?.value?.href,
        guid: curr.id || '',
        event: 'event-click',
      };

      const sendAnalytics = () => track.component(analytics);

      let img;
      if (image) {
        if (image === 'Linked Image') {
          img = {
            image: curr.fields?.[image],
          };
        } else if (curr.fields?.[image]) {
          img = { image: curr.fields?.[image] };
        }
      }

      return [
        ...acc,
        {
          link,
          track: sendAnalytics,
          ...img,
        },
        ...getLinkAndImage(curr.fields?.items),
      ];
    }, []);

  const columns = fields?.datasource?.items?.map(({ fields }) => getLinkAndImage(fields.items));

  // This last col doesn't collapse to an accordion
  const flatColumn = columns?.pop() || [];

  return {
    badges: getLinkAndImage(fields?.datasource?.['App Store Badges Links'], 'Badge'),
    bottomLinks: getLinkAndImage(fields?.datasource?.['Bottom Links']),
    copyright: fields?.datasource?.Copyright,
    columns,
    flatColumn,
    socialLinks: getLinkAndImage(fields?.datasource?.['Social Media Links'], 'Linked Image'),
    hideTagLine,
    // If variant comes through as an empty string (it often does!) then we need default to 'FatFooter'
    variant: (fields?.datasource?.variant as FooterProps['variant']) || 'FatFooter',
  };
});

export { compositionFunction };
export default component;
