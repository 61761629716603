/* eslint-disable complexity */
import { sendCAL } from 'src/components/Auth/AccountSelector/analytics';
import Link from 'src/components/Common/Link';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';
import { DropdownDirection, NavItemType, SecondaryNavItems } from '../types';

const DropDownMenu = ({
  isActive,
  name,
  route,
  subpages,
  dropdownDirection,
}: Pick<SecondaryNavItems, 'name' | 'route' | 'subpages'> &
  DropdownDirection &
  Pick<NavItemType, 'isActive'>) => {
  const hasSubpages = subpages?.[0]?.subpages?.length > 0;

  const getWidth = (cols: number) => {
    if (cols === 2) {
      return { containerWidth: 'max-w-lg', colWidth: 'w-1/2' };
    }
    if (cols === 3) {
      return { containerWidth: 'max-w-lg', colWidth: 'w-1/3' };
    }
    if (cols === 4) {
      return { containerWidth: 'max-w-2xl', colWidth: 'w-1/4' };
    }
    return { containerWidth: 'max-w-4xl', colWidth: 'w-1/5' };
  };

  const activeClasses = isActive
    ? 'visible opacity-100 translate-y-auto scale-100'
    : 'invisible opacity-0 -translate-y-4 scale-95';

  if (hasSubpages) {
    return (
      <div
        className={`absolute top-full w-screen bg-white text-teal-darker rounded-b-md shadow transform origin-top transition-all duration-200 z-0 px-16 py-24 ${
          getWidth(dropdownDirection.cols).containerWidth
        } ${dropdownDirection.side} ${activeClasses}`}
      >
        <div>
          <nav className="flex flex-wrap -mx-16" aria-label={`${name} Submenu`}>
            {subpages.map((elm, index) => (
              <div key={index} className={`${getWidth(dropdownDirection.cols).colWidth} px-16`}>
                <h4 className="font-bold">
                  <Link
                    onClick={() => track.component(elm.analytics)}
                    className="py-8 no-underline hover:underline font-bold"
                    href={elm.route}
                  >
                    <span>{elm.name}</span>
                  </Link>
                </h4>
                <ul className="mt-12 space-y-16">
                  <List key={index} {...elm} />
                </ul>
              </div>
            ))}
            <div className="flex justify-center w-full mt-16 -mb-16 p-16 border-t border-gray">
              <Link
                href={route}
                className="text-teal-darker hover:underline"
                onClick={() =>
                  sendCAL(
                    'secondary_navigation',
                    route,
                    'products-services-|-view-all-products-services'
                  )
                }
              >
                View All {name}
                <SvgLoader className="ml-6 inline" name="Arrow" size={12} />
              </Link>
            </div>
          </nav>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`absolute top-full w-screen bg-white text-teal-darker rounded-b-md shadow transform origin-top transition-all duration-200 z-0 p-16 max-w-2xs ${dropdownDirection.side} ${activeClasses}`}
    >
      <nav aria-label={`${name} Submenu`}>
        <ul className="space-y-16">
          {subpages.map((elm, index) => (
            <List key={index} {...elm} />
          ))}
        </ul>
      </nav>
    </div>
  );
};

const List = ({ name, route, subpages, analytics }: SecondaryNavItems) => {
  const hasSubpages = subpages?.length > 0;
  if (hasSubpages) {
    return (
      <>
        {subpages.map(({ name: innerName, route: innerRoute }, index) => (
          <li key={index}>
            <Link
              onClick={() => track.component(subpages[index].analytics)}
              className="py-8 no-underline hover:underline"
              href={innerRoute}
            >
              {innerName}
            </Link>
          </li>
        ))}
      </>
    );
  }
  return (
    <li>
      <Link
        onClick={() => track.component(analytics)}
        className={`py-8 no-underline hover:underline ${hasSubpages ? 'font-bold' : ''}`}
        href={route}
      >
        <span>{name}</span>
      </Link>
    </li>
  );
};

export { DropDownMenu };
