import React, { createContext, useReducer, useContext } from 'react';
import { Action, BodyContextType, State } from './types';

enum Actions {
  modalClose = 'closes the modal',
  modalOpen = 'opens the modal',
}

const initialState = {
  modal: {
    activeId: '',
    isOpen: false,
  },
};

const BodyContext = createContext<BodyContextType>({
  dispatch: () => {},
  state: initialState,
});

const bodyReducer = (state: State, action: Action) => {
  const { payload, type } = action;

  switch (type) {
    case 'modalClose':
      return { ...state, modal: { ...state.modal, isOpen: false } };
    case 'modalOpen':
      return { ...state, modal: { ...state.modal, activeId: payload!.id, isOpen: true } };
    default:
      throw new Error();
  }
};

const BodyContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(bodyReducer, initialState);
  return <BodyContext.Provider value={{ state, dispatch }}>{children}</BodyContext.Provider>;
};

const useBodyContext = () => {
  return useContext(BodyContext);
};

export { Actions, BodyContextProvider, initialState, useBodyContext };
