import { Image as JssImage } from '@sitecore-jss/sitecore-jss-react';
import { useMemo } from 'react';
import useIntersectionHook from 'src/lib/Hooks/useIntersection';
import URL from 'url-parse';
import {
  ImageProps,
  LazyImageWrapperProps,
  LazyImageWrapperSignature,
  getSrcSetType,
  size,
} from './types';

// as = allow stretch, will let img fill either width or height of frame if w/h params don't match aspect ratio
// bc = backgroundColor, if as=0 and there is extra space
const defaultParams = { as: 1, bc: 'ffffff' };

/** Either transform the srcset Array prop into a string with correct query params or return out an already formatted srcset attribute. */
const getSrcSet = ({ src, srcSet }: getSrcSetType) => {
  const parsedUrl = URL(src, {}, true);

  // If srcset prop is an Array we need to pull out the parameters and generate a string of the form,
  // "https://imageUrl.png?w={widthParameter}&h={heightParameter} {widthParameter}w, https://imageUrl.png?w={widthParameter}&h={heightParameter} {widthParameter}w".
  // Also reference https://github.com/Sitecore/jss/blob/e8a8368ea63d8c55ec03b0c118a903b00c0e9017/packages/sitecore-jss/src/mediaApi.ts#L72
  if (Array.isArray(srcSet)) {
    // Remove h (height), w (width), mw (maxwidth)
    const removeSizeParams = filterQueryParams(['h', 'w', 'mw']);

    const generated = srcSet
      .map(params => {
        // Remove all sizing query params, but keep any other params. While setting a new query param will update that specific key/value pair,
        // we need to ensure that there are not conflicting sizing params in our final generated value bc this will give us a chunky black border
        // on the image returned from sitecore.
        const stripSizeQuery = removeSizeParams(parsedUrl.query);
        const newQueryParams = { ...defaultParams, ...stripSizeQuery, ...params };
        const newUrl = parsedUrl.set('query', newQueryParams).toString();
        return `${newUrl} ${params.mw || params.w}w`;
      })
      .filter(value => value)
      .join(', ');

    return { srcSet: generated };
  }

  // The srcset attribute was set to a string in the native style so just pass it through unchanged.
  // Or if its undefined thats fine too bc we will spread it into calculatedProps() and react
  // will not add an attr with a falsey value.
  return { srcSet };
};

/** Remove query params from object */
const filterQueryParams = (paramsToRemove: string[]) => (query: object) =>
  Object.entries(query).reduce(
    (acc, [key, value]) =>
      paramsToRemove.some(item => key === item) ? acc : { [key]: value, ...acc },
    {}
  );

// Preserve aspect ratio with svg src viewBox using width and height props.
// Default to 16/9 aspect ratio.
// https://css-tricks.com/preventing-content-reflow-from-lazy-loaded-images/
const placeholderSrc = ({ width = 16, height = 9 }: { width?: size; height?: size }) =>
  `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;

// class for loading animation
const defaultLoadingClass = 'animate-pulse flex bg-gray-light';

const LazyImageComponent = ({
  alt = '',
  src,
  srcSet,
  loadingClass = defaultLoadingClass,
  // useIntersectionHook should be the default in most usage, except storybook where we want to manually toggle isIntersecting.
  useIntersection = useIntersectionHook,
  ...rest
}: ImageProps) => {
  const { isIntersecting, ref } = useIntersection<HTMLImageElement>();
  const calculatedProps = useMemo(() => ({ src, ...getSrcSet({ src, srcSet }) }), [src, srcSet]);
  const props = isIntersecting
    ? calculatedProps
    : {
        className: `${rest.className} ${loadingClass}`,
        height: rest.height || 9,
        src: placeholderSrc(rest),
        width: rest.width || 16,
      };

  return <img ref={ref} alt={alt} {...rest} {...props} />;
};

/** separate props.value and spread its contents in with the rest of the props to pass thru to LazyImage */
const transformProps = (props: LazyImageWrapperProps) => {
  if ('value' in props) {
    const { value, ...rest } = props;
    return { ...value, ...rest };
  }
  return props;
};

// Ex:
// <LazyImage {...editableData} sizes="(min-width: 1280px) 180px, 122px" srcSet={[{ mw: 180 }, { mw: 122 }]} />
// <LazyImage src="hello.jpg" sizes="(min-width: 1280px) 180px, 122px" srcSet={[{ mw: 180 }, { mw: 122 }]} />

const LazyImage: LazyImageWrapperSignature = (props: LazyImageWrapperProps) => {
  // If we are in experience editor use the JssImage
  if ('editable' in props) {
    const { value, editable } = props;
    return <JssImage field={{ value, editable }} />;
  }

  const lazyImageProps = transformProps(props);
  // If the sitecore response has no src, return null
  return 'src' in lazyImageProps ? <LazyImageComponent {...lazyImageProps} /> : null;
};

export {
  LazyImageComponent,
  LazyImage as default,
  getSrcSet,
  defaultLoadingClass as loadingClass,
  placeholderSrc,
};
