import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgAlertSolid = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M70 35A35 35 0 1 1 35 0a35 35 0 0 1 35 35" fill="currentColor" />
    <path
      d="M35 45.73a4.94 4.94 0 0 0-5.11 5v.12a5.11 5.11 0 0 0 10.23 0v-.12a4.94 4.94 0 0 0-5.12-5ZM37.87 14.17h-5.75c-1.59 0-2.53 1.3-2.35 3l2.53 22.09a2.71 2.71 0 0 0 5.41 0l2.53-22.09c.16-1.7-.77-3-2.37-3Z"
      fill="#fff"
    />
  </svg>
);

export default SvgAlertSolid;
