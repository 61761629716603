// This file was initially generated from scripts/generate-component-factory.js.
// Now it is manually created so that we can have granular control over which components in our project
// need to interface with sitecore.
import loadable from '@loadable/component';

// Common (included in main bundle)
import CallToAction from './components/Common/@electron/CallToAction/composition';
import Flipboard from './components/Common/@electron/Flipboard/composition';
import Footer from './components/Common/@electron/Footer/composition';
import FormsMessage from './components/Common/@electron/FormsMessage';
import GlobalAlerts from './components/Common/@electron/GlobalAlerts/composition';
import JSSHero from './components/Common/@electron/JSSHero/composition';
import JSSPushDownPanel from './components/Common/@electron/JSSPushDownPanel/composition';
import JSSTabs from './components/Common/@electron/JSSTabs/composition';
import NavCards from './components/Common/@electron/NavCards/composition';
import NewsBanner from './components/Common/@electron/NewsBanner/composition';
import PageHeader from './components/Common/@electron/PageHeader';
import PrimaryNav from './components/Common/@electron/PrimaryNav/composition';
import ProductCards from './components/Common/@electron/ProductCards/composition';
import QuickLinks from './components/Common/@electron/QuickLinks/composition';
import RelatedLinks from './components/Common/@electron/RelatedLinks/composition';
import SecondaryNav from './components/Common/@electron/SecondaryNav/composition';
import TopTasks from './components/Common/@electron/TopTasks/composition';
import AppBanner from './components/Common/AppBanner/composition';
import Hero from './components/Common/Hero/composition';
import HeroSmall from './components/Common/HeroSmall/composition';
import IFrame from './components/Common/IFrame/composition';
import Jurisdiction from './components/Common/Jurisdiction/composition';
import ModalSessionExpiration from './components/Common/ModalSessionExpiration/composition';
import PageLoad from './components/Common/PageLoad';
import RichText from './components/Common/RichText_Old/composition';
import SiteSettings from './components/Common/SiteSettings';
import SkipRenderingComponent from './components/Common/SkipRenderingComponent';
import LatestNews from './components/Public/LatestNews/composition';
import NewsAndResources from './components/Public/NewsAndResources/composition';

// Microsite
const FindContractor = loadable(() => import('./components/Microsite/FindItDuke/FindContractor'));
const OffersAndRebates = loadable(() =>
  import('./components/Microsite/FindItDuke/OffersAndRebates')
);
const ProjectEstimator = loadable(() =>
  import('./components/Microsite/FindItDuke/ProjectEstimator')
);
const MicrositeHeader = loadable(() => import('./components/Microsite/components/MicrositeHeader'));

// Authenticated (code-split)
const AccountSelector = loadable(() => import('./components/Auth/AccountSelector'));
const Disambiguation = loadable(() => import('./components/Auth/Disambiguation'));
const ForgotUsername = loadable(() => import('./components/Auth/ForgotUsername'));
const ImpersonationBanner = loadable(() => import('./components/Auth/ImpersonationBanner'));
const SignInMAM = loadable(() => import('./components/Auth/SignInMAM'));
const SignInPageComponent = loadable(() => import('./components/Auth/SignInPageComponent'));
const StopService = loadable(() => import('./components/Auth/StopService'));
const NeedHelp = loadable(() => import('./components/Auth/NeedHelp'));
const PayAsGuest = loadable(() => import('./components/Auth/PayAsGuest'));
const DisabledComponentFormsMessage = loadable(() =>
  import('./components/Common/DisabledFormsMessage')
);

// Public (code-split)
const Accordion = loadable(() => import('./components/Common/@electron/Accordion/composition'));
const AdditionalResources = loadable(() =>
  import('./components/Common/AdditionalResources/composition')
);
const AddressLookup = loadable(() => import('./components/Public/AddressLookup/composition'));
const ApplicationComponent = loadable(() =>
  import('./components/Common/ApplicationComponent/composition')
);
const CEICalculator = loadable(() => import('./components/Public/CEICalculator/composition'));
const ChargerCalculator = loadable(() =>
  import('./components/Public/ChargerCalculator/composition')
);
const DesignLight = loadable(() => import('./components/Public/DesignLight/composition'));
const EVCalculator = loadable(() => import('./components/Public/EVCalculator/composition'));
const GoogleMap = loadable(() => import('./components/Public/GoogleMap'));
const IconList = loadable(() => import('./components/Common/@electron/IconList/composition'));
const LightAuth = loadable(() => import('./components/Public/LightAuth/composition'));
const Modal = loadable(() => import('./components/Common/Modal/composition'));
const ModalSlideshow = loadable(() => import('./components/Public/ModalSlideshow/composition'));
const ModalVideo = loadable(() => import('./components/Common/ModalVideo/composition'));
const ModalX = loadable(() => import('./components/Common/ModalX/composition'));
const MoreInfoWidget = loadable(() =>
  import('./components/Common/@electron/MoreInfoWidget/composition')
);
const MultiColumn = loadable(() => import('./components/Public/MultiColumn/composition'));
const MultiStepForm = loadable(() => import('./components/Public/Form/MultiStepForm/composition'));
const MultiVideo = loadable(() => import('./components/Common/MultiVideo/composition'));
const PhotoWithCaption = loadable(() => import('./components/Public/PhotoWithCaption/composition'));
const PushDownPanelItem = loadable(() =>
  import('./components/Common/@electron/PushDownPanel/PushDownPanelItem/composition')
);
const PushDownPanel = loadable(() =>
  import('./components/Common/@electron/PushDownPanel/composition')
);
const SearchResults = loadable(() => import('./components/Public/SearchResults/composition'));
const SimpleCalculator = loadable(() => import('./components/Public/SimpleCalculator/composition'));
const SingleStepForm = loadable(() =>
  import('./components/Public/Form/SingleStepForm/composition')
);
const SmartSaverTable = loadable(() => import('./components/Public/SmartSaverTable/composition'));
const TabItem = loadable(() => import('./components/Common/@electron/Tab/TabItem'));
const Tab = loadable(() => import('./components/Common/@electron/Tab/composition'));
const Tooltip = loadable(() => import('./components/Common/Tooltip'));
const Twitter = loadable(() => import('./components/Public/Twitter/composition'));
const VampireCalculator = loadable(() =>
  import('./components/Public/VampireCalculator/composition')
);
const Video = loadable(() => import('./components/Common/Video/composition'));

const components = new Map();
components.set('Accordion', Accordion);
components.set('JSS Accordion', Accordion);
components.set('AdditionalResources', AdditionalResources);
components.set('Additional Resources', AdditionalResources);
components.set('AddressLookup', AddressLookup);
components.set('Address Lookup', AddressLookup);
components.set('AppBanner', AppBanner);
components.set('ApplicationComponent', ApplicationComponent);
components.set('Application Component', ApplicationComponent);
components.set('CEICalculator', CEICalculator);
components.set('CEI Calculator', CEICalculator);
components.set('CallToAction', CallToAction);
components.set('Call To Action', CallToAction);
components.set('ChargerCalculator', ChargerCalculator);
components.set('Charger Calculator', ChargerCalculator);
components.set('Design a Light New', DesignLight);
components.set('Design a Light', DesignLight);
components.set('EVCalculator', EVCalculator);
components.set('Disabled Forms', DisabledComponentFormsMessage);
components.set('DisabledForms', DisabledComponentFormsMessage);
components.set('Flipboard', Flipboard);
components.set('Footer', Footer);
components.set('FormsMessage', FormsMessage);
components.set('GlobalAlerts', GlobalAlerts);
components.set('Global Alerts', GlobalAlerts);
components.set('Global Toggle', SkipRenderingComponent);
components.set('GlobalToggle', SkipRenderingComponent);
components.set('Map', GoogleMap);
components.set('Hero', Hero);
components.set('HeroSmall', HeroSmall);
components.set('IFrame', IFrame);
components.set('IconList', IconList);
components.set('Bulleted Overview', IconList);
components.set('JSSHero', JSSHero);
components.set('JSS Hero', JSSHero);
components.set('JSSPushDownPanel', JSSPushDownPanel);
components.set('JSS Push Down Panel', JSSPushDownPanel);
components.set('FID Project Estimator', ProjectEstimator);
components.set('FindAContractor', FindContractor);
components.set('OffersAndRebates', OffersAndRebates);
components.set('FID Header', MicrositeHeader);
components.set('JSSTabs', JSSTabs);
components.set('JSS Tabs', JSSTabs);
components.set('Jurisdiction', Jurisdiction);
components.set('LatestNews', LatestNews);
components.set('Latest News', LatestNews);
components.set('LightAuth', LightAuth);
components.set('Light Authentication', LightAuth);
components.set('AccountSelector', AccountSelector);
components.set('Account Selector', AccountSelector);
components.set('Disambiguation', Disambiguation);
components.set('JSS Disambiguation', Disambiguation);
components.set('ForgotUsername', ForgotUsername);
components.set('Forgot Username', ForgotUsername);
components.set('ImpersonationBanner', ImpersonationBanner);
components.set('JSS Impersonation', ImpersonationBanner);
components.set('Sign In MAM', SignInMAM);
components.set('SignInMAM', SignInMAM);
components.set('SignInPageComponent', SignInPageComponent);
components.set('Sign In Page Component', SignInPageComponent);
components.set('StopService', StopService);
components.set('Stop Service', StopService);
components.set('Modal', Modal);
components.set('ModalSessionExpiration', ModalSessionExpiration);
components.set('Session Expiration Modal', ModalSessionExpiration);
components.set('ModalSlideshow', ModalSlideshow);
components.set('Modal Slideshow', ModalSlideshow);
components.set('ModalVideo', ModalVideo);
components.set('Modal Video', ModalVideo);
components.set('ModalX', ModalX);
components.set('MoreInfoWidget', MoreInfoWidget);
components.set('More Info Widget', MoreInfoWidget);
components.set('MultiColumn', MultiColumn);
components.set('Multi Column', MultiColumn);
components.set('MultiStepForm', MultiStepForm);
components.set('Multi Step Form', MultiStepForm);
components.set('MultiVideo', MultiVideo);
components.set('NavCards', NavCards);
components.set('NewsAndResources', NewsAndResources);
components.set('News and Resources', NewsAndResources);
components.set('News And Resources', NewsAndResources);
components.set('NewsBanner', NewsBanner);
components.set('News Banner', NewsBanner);
components.set('PageHeader', PageHeader);
components.set('PageLoad', PageLoad);
components.set('PayAsGuest', PayAsGuest);
components.set('Pay As Guest', PayAsGuest);
components.set('Jss Page Load', PageLoad);
components.set('PhotoWithCaption', PhotoWithCaption);
components.set('PrimaryNav', PrimaryNav);
components.set('ProductCards', ProductCards);
components.set('Product Cards', ProductCards);
components.set('PushDownPanel', PushDownPanel);
components.set('Push Down Panel', PushDownPanel);
components.set('PushDownPanelItem', PushDownPanelItem);
components.set('Push Down Panel Item', PushDownPanelItem);
components.set('QuickLinks', QuickLinks);
components.set('Quick Links', QuickLinks);
components.set('RelatedLinks', RelatedLinks);
components.set('Related Links', RelatedLinks);
components.set('RichText', RichText);
components.set('Rich Text', RichText);
components.set('Search Stax Results', SearchResults);
components.set('SecondaryNav', SecondaryNav);
components.set('NavItems', SecondaryNav);
components.set('SimpleCalculator', SimpleCalculator);
components.set('Simple Calculator', SimpleCalculator);
components.set('SingleStepForm', SingleStepForm);
components.set('Single Step Form', SingleStepForm);
components.set('SiteSettings', SiteSettings);
components.set('Site Settings', SiteSettings);
components.set('SkipRenderingComponent', SkipRenderingComponent);
components.set('Dessa', SkipRenderingComponent);
components.set('Hero Stock Ticker', SkipRenderingComponent);
components.set('Progressive Disclosure Form', SkipRenderingComponent);
components.set('Sign In', SkipRenderingComponent);
components.set('Page Access', SkipRenderingComponent);
// NavWrapper is set manually in src/Layout.tsx so we can skip it here
components.set('NavWrapper', SkipRenderingComponent);
components.set('SmartSaverTable', SmartSaverTable);
components.set('SS Rebate Table', SmartSaverTable);
components.set('Tab', Tab);
components.set('TabItem', TabItem);
components.set('Tab Item', TabItem);
components.set('Tooltip', Tooltip);
components.set('TopTasks', TopTasks);
components.set('Top Tasks', TopTasks);
components.set('Twitter', Twitter);
components.set('VampireCalculator', VampireCalculator);
components.set('Energy Vampire Calculator', VampireCalculator);
components.set('Video', Video);
components.set('Jss Need Help', NeedHelp);

export default function componentFactory(componentName) {
  return components.get(componentName);
}
