import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgHome = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M74.542 34.902 60.18 20.499V7.497a2.543 2.543 0 0 0-2.534-2.551h-4.172a2.543 2.543 0 0 0-2.534 2.55v3.88L40.663 1.102A3.719 3.719 0 0 0 38.023 0c-.99 0-1.94.396-2.64 1.102L1.47 34.902a2.315 2.315 0 0 0-.329 2.194c.272.74.9 1.29 1.666 1.455h7.427v34.827A2.614 2.614 0 0 0 12.786 76h17.16a2.404 2.404 0 0 0 1.776-.778 2.437 2.437 0 0 0 .634-1.844V57.187a2.543 2.543 0 0 1 2.535-2.551h6.37c1.4 0 2.535 1.142 2.535 2.55v16.192a2.543 2.543 0 0 0 2.534 2.551h16.843a2.526 2.526 0 0 0 1.803-.74 2.56 2.56 0 0 0 .749-1.81V38.532h7.48a2.293 2.293 0 0 0 1.652-1.448 2.315 2.315 0 0 0-.315-2.183ZM27.446 47.019a2.666 2.666 0 0 1-2.657 2.674H18.03a2.666 2.666 0 0 1-2.658-2.674v-6.803c0-.71.28-1.39.778-1.891a2.649 2.649 0 0 1 1.88-.784h6.705a2.648 2.648 0 0 1 1.913.764c.51.504.797 1.192.797 1.911v6.803Zm32.242 0a2.666 2.666 0 0 1-2.657 2.674h-6.706a2.666 2.666 0 0 1-2.657-2.674v-6.803a2.666 2.666 0 0 1 2.657-2.675h6.706a2.666 2.666 0 0 1 2.657 2.675v6.803Z"
    />
  </svg>
);

export default SvgHome;
