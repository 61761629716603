import Composition from 'src/lib/HOC/Composition';
import { stripCurlyBraces } from 'src/lib/Utils/helpers';
import { data } from './data';
import ModalSessionExpiration from './index';

const { compositionFunction, component } = Composition(ModalSessionExpiration)<
  { fields: typeof data.fields } & { rendering: any }
>(({ fields, rendering }) => ({
  btnText: fields.dataSource['Ok Button Text'],
  id: stripCurlyBraces(rendering.dataSource).toLowerCase(),
  isAuthenticated: fields.isAuthenticated,
  serverTimestamp: fields.timestamp,
  redirectUrl: fields.dataSource['Redirect Url'].value.href,
  textPhraseOne: fields.dataSource['Session Expiration Text'],
  textPhraseTwo: fields.dataSource['Continue Session Text'],
  title: fields.dataSource.Headline,
}));

export { compositionFunction };
export default component;
