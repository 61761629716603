const getCookies = () => {
  const cookies = () => {
    return document.cookie
      .split(';')
      .map(cookie => cookie.split('='))
      .reduce(
        (accumulator, [key, value]) => ({
          ...accumulator,
          [key.trim()]: decodeURIComponent(value),
        }),
        {}
      );
  };
  if (typeof window !== 'undefined') {
    return cookies() as { [key: string]: any };
  } else {
    return null;
  }
};

// function to pull key value from csv formatted cookie (de.identity)
const getCookiesMap = function (cookiesString: string, key: string) {
  const cookies = cookiesString
    .split(',')
    .map(function (cookieString) {
      return cookieString.trim().split('=');
    })
    .reduce(function (acc: any, curr) {
      acc[curr[0]] = curr[1];
      return acc;
    }, {});

  return cookies[key] || '';
};

export { getCookies as default, getCookiesMap };
