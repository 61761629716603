import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgKentucky = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.25 54.638a.164.164 0 0 1-.023-.168.17.17 0 0 1 .144-.099l48.1-3.288a.266.266 0 0 0 .16-.07l5.659-5.041a.266.266 0 0 0 .086-.143l.352-1.553a.138.138 0 0 1 .047-.074l7.631-6.714a.266.266 0 0 0 .09-.159l.5-2.999a.276.276 0 0 0-.074-.233l-3.015-3.107a.168.168 0 0 1-.051-.11l-.188-3.244c0-.052.007-.075.013-.094l.891-2.14a.272.272 0 0 0-.02-.25.28.28 0 0 0-.216-.129l-2.766-.152a.142.142 0 0 1-.076-.032l-3.717-2.737a.277.277 0 0 0-.272-.03l-2.117.925c-.016.008-.039.008-.07.008l-5.12-.558c-.052-.01-.078-.023-.081-.023l-2.276-1.28a.285.285 0 0 0-.076-.029l-9.69-2.107a.26.26 0 0 0-.212.04.268.268 0 0 0-.115.181l-.584 3.645a.159.159 0 0 1-.076.11l-5.1 3.052a.284.284 0 0 0-.131.209l-.236 2.554a.157.157 0 0 1-.038.089l-5.166 5.95c-.048.059-.136.068-.205.021l-3.124-2.08a.267.267 0 0 0-.231-.032.274.274 0 0 0-.173.157l-1.38 3.47a.163.163 0 0 1-.134.101l-3.605.385a.317.317 0 0 0-.107.034l-1.762 1.008a.198.198 0 0 1-.137.016l-3.717-1.024a.289.289 0 0 0-.14-.001l-2.812.708a.273.273 0 0 0-.196.188l-.46 1.603a.167.167 0 0 1-.154.118l-2.328.12a.27.27 0 0 0-.25.2l-1.625 5.766c-.006.025-.024.057-.03.07l-2.373 2.766c-.042.045-.104.073-.157.058L5.6 47.75a.265.265 0 0 0-.28.135L3.717 50.74a.268.268 0 0 0-.01.238l.971 2.313c.026.056.016.113-.026.163L3.2 55.17a.19.19 0 0 1-.117.063l-2.669.112a.27.27 0 0 0-.258.24l-.154 1.348a.269.269 0 0 0 .27.303c.007 0 .015 0 .021-.002l13.592-1.08c.1-.008.188-.07.228-.162a.27.27 0 0 0-.032-.277l-.832-1.077Z"
    />
  </svg>
);

export default SvgKentucky;
