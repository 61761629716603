import { Text } from '@sitecore-jss/sitecore-jss-react';
import Link from 'src/components/Common/Link';
import SvgLoader from 'src/components/Common/SvgLoader';
import useMediaQuery from 'src/lib/Hooks/useMediaQuery';
import { TitleProps, TitleTextProps } from '../types';

const Arrow = () => {
  const isMobileViewport = !useMediaQuery('md');

  return (
    <SvgLoader
      aria-hidden="true"
      className="inline-flex fill-current link-chevron"
      focusable="false"
      name="Arrow"
      size={isMobileViewport ? 12 : 14}
      sizeConstraints={false}
    />
  );
};

const TitleText = ({ computedLink, handleClick, title, variant }: TitleTextProps) => {
  switch (variant) {
    case 'link':
      return (
        <Link
          {...computedLink}
          className="block text-teal-darker no-underline hover:underline"
          onClick={handleClick}
        >
          <Text field={title} />
          <span className="whitespace-nowrap">
            &zwnj;
            <Arrow />
          </span>
        </Link>
      );
    case 'textWithModal':
      return (
        <button
          className="no-underline text-teal-darker hover:underline"
          onClick={handleClick}
          style={{ textAlign: 'inherit' }}
        >
          <Text field={title} />
          <span className="whitespace-nowrap">
            &zwnj;
            <Arrow />
          </span>
        </button>
      );
    default:
      return <Text className="text-gray-dark" field={title} />;
  }
};

const Title = ({
  index = 0,
  link,
  handleClick,
  mobileLink,
  modal,
  ordered,
  title,
  HeaderTag,
}: TitleProps & { HeaderTag: 'h2' | 'h3' | 'p' }) => {
  const isMobileViewport = !useMediaQuery('md');
  const computedLink = isMobileViewport ? mobileLink : link;
  const variant = computedLink ? 'link' : modal?.id ? 'textWithModal' : 'text';

  return (
    <HeaderTag className="text-xl">
      {ordered && (
        <span className="flex justify-center items-center h-30 w-30 mb-6 text-lg font-bold text-center rounded-full bg-current">
          <span className="text-white">{index + 1}</span>
        </span>
      )}
      {title && <TitleText {...{ computedLink, handleClick, title, variant }} />}
    </HeaderTag>
  );
};

export { Title };
