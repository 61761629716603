import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCaretRight = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    aria-hidden="true"
    fill="currentColor"
    viewBox="0 0 24 12"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.29189 8.12508L5.17446 3.22224C4.91205 2.90978 4.94997 2.4415 5.25916 2.17631C5.56835 1.91112 6.03173 1.94944 6.29414 2.26191L10.8256 7.65769C11.0628 7.94022 11.0575 8.35635 10.813 8.63256L6.28161 13.7526C6.01126 14.0581 5.54705 14.0843 5.24478 13.811C4.94251 13.5378 4.91664 13.0687 5.18699 12.7632L9.29189 8.12508Z"
      fill="#00789E"
    />
  </svg>
);

export default SvgCaretRight;
