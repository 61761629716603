import { useEffect } from 'react';
import { ButtonGroup } from 'src/components/Common/@electron/ButtonGroup';
import { FormsMessage } from 'src/components/Common/@electron/FormsMessage';
import { RichText } from 'src/components/Common/@electron/RichText';
import Link from 'src/components/Common/Link';
import track from 'src/lib/Analytics';
import withErrorBoundary from 'src/lib/HOC/ErrorBoundary/withErrorBoundary';
import { DisabledComponentsType } from 'src/lib/HOC/withGlobalToggle';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import { MediumContainer, PageSpacing } from '../../Auth/_components/layout';

const DisabledComponent = ({ componentName }: { componentName: DisabledComponentsType }) => {
  const {
    status: { itemPath, route },
  } = useAppContext();

  const values = route?.placeholders['jss-public-header'].find(
    x => x.componentName === 'Global Toggle'
  )?.fields?.[componentName];

  const disabledComponentName =
    componentName ||
    route?.displayName?.replace(/(^\w|-\w)/g, text => text.replace(/-/, '').toUpperCase());
  // ^^ converts kabab-case string into PascalCase

  const isSignInDrawerVariant =
    disabledComponentName === 'signIn' && !itemPath.includes('my-account');

  const Title = values?.title;
  const Description = values?.description;
  const CTAButtonText = values?.buttonText;
  const CTAButtonLink = values?.buttonLink?.url;

  const analyticNameMap = {
    signIn: 'login',
    payAsGuest: 'pay-as-a-guest',
    stopService: 'stop-service',
    startService: 'start-service',
    moveService: 'move-service',
    shoppingCart: 'shopping-cart',
  };

  useEffect(() => {
    track({
      event: 'send-VPV',
      'vpv-name': `/vpv/de/load/feature-unavailable/error/${analyticNameMap[disabledComponentName]}/we-are-sorry-this-feature-is-unavailable`,
    });
    track({
      'event-category': 'feature-unavailable-error',
      'event-action': 'na-|- we-are-sorry-this-feature-is-unavailable-|-na',
      'event-label': `${analyticNameMap[disabledComponentName]}-|-${itemPath}`,
      event: 'event-click',
    });
  }, []);

  return (
    <PageSpacing>
      <MediumContainer>
        <FormsMessage
          variant="critical"
          title={Title}
          description={<RichText value={Description} />}
        >
          {!isSignInDrawerVariant && (
            <ButtonGroup
              secondary={
                <Link href={CTAButtonLink} className="btn btn-secondary btn-md btn-full">
                  {CTAButtonText}
                </Link>
              }
            />
          )}
        </FormsMessage>
      </MediumContainer>
    </PageSpacing>
  );
};

const DisabledPage = () => {
  const {
    status: { itemPath, route },
  } = useAppContext();

  const values = route?.placeholders['jss-public-header'].find(
    x => x.componentName === 'Global Toggle'
  )?.fields.togglePage;

  const disabledPageName = route?.displayName?.split(' ').join('');

  const Title = values?.title;
  const Description = values?.description;
  const CTAButtonText = values?.buttonText;
  const CTAButtonLink = values?.buttonLink?.url;

  useEffect(() => {
    track({
      event: 'send-VPV',
      'vpv-name': `/vpv/de/load/global-toggle-page-disabled/error/${disabledPageName}/we-are-sorry-this-feature-is-unavailable`,
    });
    track({
      'event-category': 'global-toggle-page-disabled',
      'event-action': 'na-|-we-are-sorry-this-feature-is-unavailable-|-na',
      'event-label': `${itemPath}`,
      event: 'event-click',
    });
  }, []);

  return (
    <PageSpacing>
      <MediumContainer>
        <FormsMessage
          variant="critical"
          title={Title}
          description={<RichText value={Description} />}
        >
          <ButtonGroup
            secondary={
              <Link href={CTAButtonLink} className="btn btn-secondary btn-md btn-full">
                {CTAButtonText}
              </Link>
            }
          />
        </FormsMessage>
      </MediumContainer>
    </PageSpacing>
  );
};

export default withErrorBoundary(DisabledComponent);

const DisabledPageFormsMessage = withErrorBoundary(DisabledPage);

export { DisabledPageFormsMessage };
