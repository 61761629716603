import { useEffect, useRef, useState } from 'react';

const timer = (
  duration: number,
  options: {
    onTick?: (currentTime: number) => void;
    onEnd?: () => void;
  }
) => {
  const { onTick, onEnd } = options;
  let currentTime = duration;
  let instance: any;
  const clear = () => clearInterval(instance);
  const tick = () => {
    if (currentTime > 0) {
      --currentTime;
    } else {
      clear();
      onEnd?.();
    }
    onTick?.(currentTime);
  };
  const start = () => {
    instance = setInterval(tick, 1000);
  };
  const set = (newTime: number) => {
    currentTime = newTime;
  };
  const restart = () => {
    clear();
    set(duration);
    start();
  };

  return {
    clear,
    set,
    start,
    restart,
  };
};

const useCountdown = (duration: number | null, onEnd?: () => void) => {
  const clock = useRef<ReturnType<typeof timer>>();
  const [time, setTime] = useState(duration);

  useEffect(() => {
    if (duration) {
      clock.current = timer(duration, { onTick: currentTime => setTime(currentTime), onEnd });
      clock.current.start();
    }
    return () => {
      clock.current?.clear();
    };
  }, [duration]);

  return {
    time,
    restart: () => {
      clock.current?.restart();
    },
  };
};

export { useCountdown, timer };
