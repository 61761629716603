import { NavTreeProps, TreeItemProps } from './types';

const displayBehavior = (item: NavTreeProps['items'][number], route: string) => {
  const hasSubpages = () => item.subpages?.length > 0;
  const isActiveRoute = () => route === item.page?.value?.href;
  const isTopLevel = () => item.depth < 1;
  const hasActiveChildRoute = (obj: typeof item) => obj.childRoutes.includes(route);
  const isTopLevelWithSubpages = () => isTopLevel() && hasSubpages();
  const isTopLevelParentWithActiveChild = () =>
    hasActiveChildRoute(item) && !isActiveRoute() && isTopLevelWithSubpages();

  const shouldBeLink = () =>
    (!hasSubpages() && !isActiveRoute()) || isTopLevelParentWithActiveChild();

  const shouldBeDiv = () => isActiveRoute() && (isTopLevel() || !isTopLevelWithSubpages());

  const isSomeLevelActive = () =>
    isActiveRoute() || hasActiveChildRoute(item) || item.subpages.some(hasActiveChildRoute);

  const matchesUrlStructureButIsNotInNavItems = () => {
    if (!isSomeLevelActive() && item.page.value?.href) {
      return route.includes(item.page.value.href);
    }
    return false;
  };

  const expandedSubtree = () =>
    isTopLevelWithSubpages() && (isSomeLevelActive() || matchesUrlStructureButIsNotInNavItems());
  const shouldHaveArrow = () => hasSubpages() && !expandedSubtree();
  const hasMarker = () =>
    isActiveRoute() ||
    (!isTopLevel() && hasActiveChildRoute(item)) ||
    (!isTopLevelWithSubpages() && matchesUrlStructureButIsNotInNavItems());

  const type: TreeItemProps['type'] = (() => {
    if (shouldBeLink()) return 'link';
    if (shouldBeDiv()) return 'div';
    return 'button';
  })();

  return {
    expandedSubtree: expandedSubtree(),
    hasMarker: hasMarker(),
    shouldHaveArrow: shouldHaveArrow(),
    type,
  };
};

export { displayBehavior };
