import Logger from 'src/lib/Utils/Logger';

const refreshSession = async () => {
  try {
    const results = await fetch('/form/SessionExpiration/RefreshSession', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      body: 'false',
    });
    return results;
  } catch (error) {
    return Logger(error, { message: 'Error in refreshSession' });
  }
};

export default refreshSession;
