import React from 'react';

const ErrorMessage = ({ children, className = '', ...rest }: React.HTMLProps<HTMLDivElement>) => (
  <div
    className={`absolute top-full transform translate-y-4 text-xs text-red ${className}`}
    {...rest}
  >
    {children}
  </div>
);

export default ErrorMessage;
