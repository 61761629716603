/* eslint-disable complexity */
/* eslint-disable max-lines */
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { ErrorIcon, LoadingIcon, SuccessIcon, WarningIcon } from '../../../Auth/_components/Icons';

interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  /** id: Unique identifier */
  id: string;
  /** label: Visual label. */
  label: string;
  /** status: Controls color theme and icon. Default/normal state is "undefined". */
  status?: keyof typeof statusOptions;
  /** sizing: Controls how big input is. Default is "md". */
  sizing?: keyof typeof sizeOptions;
  /** leadingIcon: Icon element placed on the left side of the input, before the typeable text area. */
  leadingIcon?: JSX.Element;
  /** errorMessage: Validation failure message to display/announce. */
  errorMessage?: string;
  /** description: Optional helper text. */
  description?: JSX.Element | string;
  // TODO add better type
  /** register: react-hook-form method to add input to the form. */
  register?: UseFormRegisterReturn;
  // showText and hideText for password button
  showText?: string;
  hideText?: string;
}

export const statusOptions = {
  error: 'form-status-error',
  success: 'form-status-success',
  warning: 'form-status-warning',
  loading: 'form-status-loading',
};

const sizeOptions = {
  sm: 'form-size-sm',
  md: 'form-size-md',
  lg: 'form-size-lg',
};

interface PaswordButtonProps {
  isShown: boolean;
  onClick: () => void;
  inputId: string;
  showText?: string;
  hideText?: string;
}

export const StatusIcon = ({ status }: Pick<InputProps, 'status'>) => {
  return (
    <span className="form-input-trailing-icon" aria-hidden="true">
      {status === 'error' && <ErrorIcon />}
      {status === 'success' && <SuccessIcon />}
      {status === 'warning' && <WarningIcon />}
      {status === 'loading' && <LoadingIcon />}
    </span>
  );
};

const PasswordButton = ({
  isShown,
  onClick,
  inputId,
  showText = 'Show',
  hideText = 'Hide',
}: PaswordButtonProps) => {
  return (
    <div className="form-input-group-append">
      <div className="relative flex items-center pr-6 h-full">
        <button
          className="link-block btn btn-tertiary btn-2xs btn-pill w-56 h-32 text-teal-darker hover:text-white hover:bg-teal-darker focus:text-white focus:bg-teal-darker"
          type="button"
          aria-label={isShown ? 'Hide password' : 'Show password'}
          aria-controls={inputId}
          onClick={onClick}
        >
          {isShown ? hideText : showText}
        </button>
        <div className="sr-only" aria-live="polite">
          {isShown ? 'Your password is shown' : 'Your password is hidden'}
        </div>
      </div>
    </div>
  );
};

export const useHeightTransition = (isOpen: boolean) => {
  const [maxHeight, setMaxHeight] = React.useState(0);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (contentRef?.current?.firstChild instanceof Element) {
      setMaxHeight(contentRef.current.firstChild.clientHeight);
    }
  }, [isOpen, contentRef]);

  const props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> = {
    ref: contentRef,
    'aria-hidden': !isOpen,
    className: `overflow-hidden transform-gpu transition-all ease-in-out ${
      isOpen ? 'opacity-100' : 'opacity-0'
    }`,
    style: {
      maxHeight: isOpen ? `${maxHeight}px` : 0,
      visibility: isOpen ? 'visible' : 'hidden',
    },
  };

  return props;
};

const Input = React.forwardRef(function InputComponent(
  props: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const {
    status,
    sizing = 'md',
    leadingIcon,
    errorMessage,
    description,
    id,
    label,
    register,
    hideText,
    showText,
    ...inputAttributes
  } = props;

  const showError = status === 'error' && typeof errorMessage !== 'undefined';
  // hooks
  const [isShown, toggle] = React.useReducer(shown => !shown, false);
  const openAnimation = useHeightTransition(showError);

  // derived from props
  // ... id's must be unique, so append the element type (-error, -helper) to the input's unique id
  const errorId = `${id}-error`;
  const descriptionId = `${id}-helper`;
  const isPassword = inputAttributes.type === 'password';

  // ... toggle input type
  let inputType = inputAttributes.type;

  if (isPassword) {
    inputType = isShown ? 'text' : 'password';
  }

  // ... assosciate error and helper text with the <input> [a11y support]
  const inputAriaDescribedBy: string[] = [];

  if (typeof description !== 'undefined') {
    inputAriaDescribedBy.push(descriptionId);
  }

  if (showError) {
    inputAriaDescribedBy.push(errorId);
  }

  return (
    <div
      className={`form-input-wrapper ${sizeOptions[sizing]} ${
        status ? statusOptions[status] : ''
      } ${leadingIcon ? 'form-icon-leading' : ''}`}
    >
      <div className="form-input-group">
        <div className="form-input-group-content">
          {leadingIcon && (
            <span className="form-input-leading-icon" aria-hidden="true">
              {leadingIcon}
            </span>
          )}
          <input
            ref={ref}
            {...inputAttributes}
            {...register}
            className="form-input-field"
            // placeholder must be empty string to power label animation on focus
            placeholder=" "
            // combine multiple aria-describedby attrs into 1 space separated string
            aria-describedby={inputAriaDescribedBy.join(' ')}
            aria-invalid={showError}
            aria-required={inputAttributes.required}
            type={inputType}
            id={id}
          />
          <label htmlFor={id} className={`form-input-label ${showError ? '!text-red' : ''}`}>
            {label}
          </label>
          {status && <StatusIcon status={status} />}
        </div>
        {isPassword && (
          <PasswordButton
            isShown={isShown}
            onClick={toggle}
            inputId={id}
            hideText={hideText}
            showText={showText}
          />
        )}
      </div>
      <div className="form-input-support">
        <div className="form-field-hints">
          {description && (
            <div className="form-field-helper" id={descriptionId}>
              {description}
            </div>
          )}
          <div {...openAnimation}>
            {showError && (
              <div
                className="form-field-error text-red"
                id={errorId}
                role="region"
                aria-live="polite"
                aria-atomic="true"
              >
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export { Input };
