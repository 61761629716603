import { compositionFunction as CallToActionComposition } from 'src/components/Common/@electron/CallToAction/composition';
import Composition from 'src/lib/HOC/Composition';
import { getModal } from 'src/lib/Utils/helpers';
import Flipboard from './index';
import { FlipboardType } from './types';

const { compositionFunction, component } = Composition(Flipboard)(({ fields }) => {
  const CTA = CallToActionComposition({ fields });

  const reduceItems = (items: typeof fields): FlipboardType['items'] => {
    return items?.reduce((acc: FlipboardType['items'], curr: any) => {
      const ctaLink = curr.fields?.['Call to Action'];
      const ctaLinkMobile = curr.fields?.['Mobile Call to Action'];
      const title = curr.fields?.['Tile Title'];
      const modal = getModal(curr.fields);

      return [
        ...acc,
        {
          analytics: {
            category: 'flipboard',
            label: fields?.Headline?.value,
            action: [title?.value, ctaLink.value?.href].filter(Boolean).join(' | '),
            guid: curr.id || '',
            event: 'event-click',
          },
          image: curr.fields?.['Tile Image'],
          link: ctaLink,
          linkMobile: ctaLinkMobile?.value?.href ? ctaLinkMobile : ctaLink,
          modal,
          text: curr.fields?.['Overlay Text'],
          title,
        },
      ];
    }, []);
  };

  return {
    ...CTA,
    items: reduceItems(fields?.items),
  };
});

export { compositionFunction, component as default };
