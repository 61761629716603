import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgArrowDown = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    aria-hidden="true"
    fill="currentColor"
    viewBox="0 0 24 12"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.25 8.584 2.444.349A1.494 1.494 0 0 0 .353.518a1.457 1.457 0 0 0 .171 2.07l10.791 9.063a1.5 1.5 0 0 0 1.95-.025l10.24-9.063A1.457 1.457 0 0 0 23.622.49a1.5 1.5 0 0 0-2.1-.116Z" />
  </svg>
);

export default SvgArrowDown;
