import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPencil = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#00789E"
      d="M10,75.56718 L13.5834,57.44498 L21.6071,59.36778 L26.0056,66.34458 L10,75.56718 Z M47.10016,9.78158 L49.63096,6.25138 L62.89296,15.75138 L60.36026,19.28158 L47.10016,9.78158 Z M51.27123,15.9752 L21.99603,56.8328 L14.64873,55.0734 L45.58263,11.8997 L51.27123,15.9752 Z M24.04594,58.44685 L53.38764,17.49235 L58.84254,21.40065 L27.91054,64.57625 L24.04594,58.44685 Z M63.54884,6.85919 C65.63504,8.35259 66.11384,11.25579 64.61664,13.34199 L64.40954,13.63269 L51.14944,4.13269 L51.35844,3.84009 C52.85184,1.75389 55.75314,1.27509 57.84314,2.77039 L63.54884,6.85919 Z"
    />
  </svg>
);

export default SvgPencil;
