import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPdf = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#f5f5f5"
      d="M14.9 75.048a6.853 6.853 0 0 1-6.8-6.89V7.941a6.853 6.853 0 0 1 6.8-6.889h34.6l.233-.005a8.393 8.393 0 0 1 2.905.537 10.931 10.931 0 0 1 2.095 1.41l11.26 11.247a6.633 6.633 0 0 1 1.4 1.909 6.058 6.058 0 0 1 .41 2.413v49.6a6.854 6.854 0 0 1-6.8 6.89z"
    />
    <path
      fill="#ff2116"
      d="M49.734 2.048a7.5 7.5 0 0 1 2.457.436 10.27 10.27 0 0 1 1.843 1.228l11.253 11.237.038.037.029.027a5.638 5.638 0 0 1 1.119 1.532 5.114 5.114 0 0 1 .331 1.949V68.16a5.852 5.852 0 0 1-5.8 5.89H14.9a5.924 5.924 0 0 1-5.8-5.89V7.942a5.852 5.852 0 0 1 5.8-5.89h34.834m0-2H14.9a7.827 7.827 0 0 0-7.8 7.89v60.216a7.891 7.891 0 0 0 7.8 7.89H61a7.827 7.827 0 0 0 7.8-7.89V18.627a7.073 7.073 0 0 0-.5-2.9 7.571 7.571 0 0 0-1.6-2.2L55.4 2.249A12.022 12.022 0 0 0 53 .652a9.4 9.4 0 0 0-3.266-.6z"
    />
    <path
      fill="#ff2116"
      d="M22.5 45.2c-1.8-1.8.1-4.2 5.4-6.8l3.3-1.6 1.3-2.8a54.826 54.826 0 0 0 2.3-5.6l1.1-2.8-.7-2.1c-.9-2.5-1.2-6.3-.6-7.7.8-1.9 3.3-1.7 4.3.3.8 1.5.7 4.4-.2 7.9l-1 3 .7 1.1c.4.6 1.4 2.1 2.4 3.3l1.8 2.2 2.2-.3c7-.9 9.4.6 9.4 2.9 0 2.8-5.5 3-10.1-.2a14.067 14.067 0 0 1-1.7-1.4s-2.9.6-4.3 1a34.6 34.6 0 0 0-4.3 1.4 17.265 17.265 0 0 0-1.2 1.9 19.9 19.9 0 0 1-5.5 6.3c-1.9.9-3.7.9-4.6 0zm2.8-.9c1-.6 3-3 4.4-5.2l.6-.9-2.6 1.3c-4 2-5.8 3.9-4.9 5 .6.6 1.2.5 2.5-.2zM51.2 37a1.478 1.478 0 0 0-.3-2.6c-.9-.4-1.6-.5-3.8-.5a35.274 35.274 0 0 0-3.9.5s1.2.8 1.7 1.1a27.133 27.133 0 0 0 3.7 1.6c1.3.3 2 .3 2.6-.1zm-10.4-4.3a18.236 18.236 0 0 1-2.2-2.9c-.8-1.1-1.3-1.9-1.3-1.9a32.287 32.287 0 0 1-1.1 3.1l-1.6 3.9-.5.9s2.4-.8 3.6-1.1l3.9-.9zm-3.4-13.5a5.416 5.416 0 0 0-.2-3.2c-1.1-1.2-2.5-.2-2.3 2.7a23.759 23.759 0 0 0 .6 3.7l.6 1.9.4-1.4a14.283 14.283 0 0 0 .9-3.7z"
    />
    <path
      fill="#2c2c2c"
      d="M25.5 53.5h2.9a6.463 6.463 0 0 1 2.2.3A2.365 2.365 0 0 1 32 55a3.7 3.7 0 0 1 .6 2.1 4.228 4.228 0 0 1-.5 2 2.54 2.54 0 0 1-1.3 1.2 7.166 7.166 0 0 1-2.5.4h-1v4.5h-1.9zm2 1.5v4h1a2.763 2.763 0 0 0 1.8-.5 2.173 2.173 0 0 0 .5-1.6 2.5 2.5 0 0 0-.3-1.3 1.874 1.874 0 0 0-.7-.6h-2.3zm6.7-1.5h2.6a5.129 5.129 0 0 1 3 .7 3.767 3.767 0 0 1 1.7 2 7.173 7.173 0 0 1 .6 2.9 8.963 8.963 0 0 1-.5 3 4.248 4.248 0 0 1-1.6 2.1 5.225 5.225 0 0 1-3.1.8h-2.8V53.5zm1.9 1.5v8.5h.8a2.619 2.619 0 0 0 2.4-1.2 5.686 5.686 0 0 0 .8-3.1c0-2.9-1.1-4.3-3.2-4.3zm7.9-1.5h6.5V55H46v3.5h3.6V60H46v5.1h-2z"
    />
  </svg>
);

export default SvgPdf;
