import { useEffect, useState } from 'react';
const isBrowser = typeof window !== 'undefined';

export default function useVisibilityChange() {
  const [visibilityStatus, setVisibilityStatus] = useState(isBrowser ? !document.hidden : true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisibilityStatus(!document.hidden);
    };

    isBrowser && document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      isBrowser && document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return visibilityStatus;
}
