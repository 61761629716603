import React, { createContext, useContext, useReducer } from 'react';
import { JurisdictionProps, ProviderProps, StepNames } from './types';

type Step = keyof typeof StepNames;
type State = JurisdictionProps['states'][0];
type Value = { whichStep: Step; whichState?: State; direction?: `${number}` };
type Set = (whichStep: Step, whichState?: State) => void;
type Reducer = (s: Value, a: Value) => Value;
type Context = Value & { set: Set };

const StepContext = createContext<Context>({
  whichStep: 'List',
  set: () => {},
});

const useStep = () => useContext(StepContext);

const StepProvider = ({ initialStep, ...props }: React.PropsWithChildren<ProviderProps>) => {
  const [value, dispatch] = useReducer<Reducer>(
    (state, action) => ({
      ...state,
      ...action,
      // Compare against position in StepNames enum
      direction: StepNames[action.whichStep] > StepNames[state.whichStep] ? '100' : '-100',
    }),
    {
      whichStep: initialStep || 'List',
    }
  );

  const set: Set = (whichStep, whichState) =>
    whichState ? dispatch({ whichStep, whichState }) : dispatch({ whichStep });

  return <StepContext.Provider value={{ ...value, set }} {...props} />;
};

export { StepProvider, useStep };
