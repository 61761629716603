import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSolar = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M29.257,20.736h5.486c.106,0,.208-.043,.283-.119,.03-.029,.055-.063,.073-.101,.05-.107,.05-.231,0-.338l-2.77-7.378c-.128-.182-.379-.226-.56-.098-.038,.027-.071,.06-.098,.098l-2.816,7.397c-.076,.209,.032,.44,.241,.515,.043,.016,.088,.024,.134,.024h.027Zm2.743,1.28c-5.504-.015-9.978,4.434-9.993,9.938-.015,5.504,4.434,9.978,9.938,9.993,5.504,.015,9.978-4.434,9.993-9.938,0-.003,0-.006,0-.009,.01-5.5-4.438-9.969-9.938-9.984Zm4.672,9.143l-6.171,8.411c-.21,.272-.6,.322-.872,.112-.212-.164-.295-.444-.207-.697l2.112-5.696h-3.657c-.343,0-.622-.278-.622-.622,0-.131,.042-.26,.119-.366l5.833-7.945c.21-.272,.6-.322,.872-.112,.212,.164,.295,.444,.207,.697l-1.984,5.23h3.831c.343-.005,.626,.269,.631,.612,.002,.135-.04,.266-.119,.375h.027Zm-1.929,12.105h-5.486c-.106,0-.208,.043-.283,.119-.03,.029-.055,.063-.073,.101-.05,.107-.05,.231,0,.338l2.77,7.378c.128,.182,.379,.226,.56,.098,.038-.027,.071-.06,.098-.098l2.816-7.397c.076-.209-.032-.44-.241-.515-.043-.016-.088-.024-.134-.024h-.027Zm-14.537-14.446c.142,.171,.395,.195,.566,.054,.037-.03,.068-.067,.092-.109l2.743-4.8c.04-.097,.04-.205,0-.302-.009-.039-.025-.076-.046-.11-.063-.1-.167-.166-.283-.183l-7.735-1.253c-.221,.021-.384,.217-.363,.438,.004,.045,.016,.088,.034,.129l4.992,6.135Zm23.589,6.363c-.142-.171-.395-.195-.566-.054-.037,.03-.068,.067-.092,.109l-2.743,4.8c-.039,.097-.039,.205,0,.302,.009,.039,.025,.076,.046,.11,.063,.1,.167,.166,.283,.183l7.735,1.253c.221-.021,.384-.217,.363-.438-.004-.045-.016-.088-.034-.129l-4.992-6.135Zm-22.93,.046c-.054-.092-.143-.158-.247-.183h-.119c-.119,.005-.229,.062-.302,.155l-4.992,6.126c-.092,.202-.002,.441,.2,.533,.041,.019,.084,.03,.129,.034l7.817-1.262c.219-.037,.367-.245,.33-.464-.008-.046-.023-.09-.046-.131l-2.77-4.809Zm22.272-6.455c.055,.091,.144,.157,.247,.183h.119c.119-.005,.229-.062,.302-.155l4.992-6.126c.092-.202,.002-.441-.2-.533-.041-.019-.084-.03-.129-.034l-7.817,1.262c-.219,.037-.367,.245-.33,.464,.008,.046,.023,.09,.046,.131l2.77,4.809Z"
    />
  </svg>
);

export default SvgSolar;
