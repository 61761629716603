import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgLoadingCircle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g>
      <circle cx={14} cy={64} r={14} />
      <circle cx={13} cy={64} r={13} fillOpacity={0.8} transform="rotate(315 64 64)" />
      <circle cx={12} cy={64} r={12} fillOpacity={0.6} transform="rotate(270 64 64)" />
      <circle cx={11} cy={64} r={11} fillOpacity={0.5} transform="rotate(225 64 64)" />
      <circle cx={10} cy={64} r={10} fillOpacity={0.4} transform="rotate(180 64 64)" />
      <circle cx={9} cy={64} r={9} fillOpacity={0.3} transform="rotate(135 64 64)" />
      <circle cx={8} cy={64} r={8} fillOpacity={0.2} transform="rotate(90 64 64)" />
      <circle cx={7} cy={64} r={7} fillOpacity={0.1} transform="rotate(45 64 64)" />
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="45 64 64;90 64 64;135 64 64;180 64 64;225 64 64;270 64 64;315 64 64;0 64 64"
        calcMode="discrete"
        dur="960ms"
        repeatCount="indefinite"
      />
    </g>
  </svg>
);

export default SvgLoadingCircle;
