import { useActor, useInterpret } from '@xstate/react';
import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import track from 'src/lib/Analytics';
import withErrorBoundary from 'src/lib/HOC/ErrorBoundary/withErrorBoundary';
import { withGlobalToggle } from 'src/lib/HOC/withGlobalToggle';
import { useSitecoreContext } from 'src/lib/Hooks/SitecoreContextFactory';
import Logger from 'src/lib/Utils/Logger';
import { toKebabCase } from 'src/lib/Utils/helpers';
import { AutocompleteString } from 'src/types/util';
import { InterpreterFrom } from 'xstate';
import { PageLevelConfig } from '../SignInPageComponent';
import { PageSpacing } from '../_components/layout';
import { createContent } from '../_lib/ContentResolver';
import { SignInRenderer, ToggleContainer } from './Main';
import { HeaderContent } from './_api/content';
import { Success, ValidateSignInType, signIn } from './_api/signIn';
import { returnCode } from './_api/signIn/returnCodes';
import { verifyEmail } from './_api/verifyEmail';
import { Context, signInMachine } from './machine';

interface EvaluateResponseParams {
  response: ValidateSignInType;
  contentExperiences: {
    fields: {
      ExperienceName: {
        value: string;
      };
      ButtonURL: {
        value: {
          href: string;
        };
      };
    };
  }[];
  disambiguationLink: string;
  delegationLink: string;
  returnCodeList: HeaderContent['fields']['datasource']['Return Codes'];
}

type Status =
  | { type: 'redirect'; to: string; experienceName: string }
  | { type: 'error'; code: string };

export type { EvaluateResponseParams, Status };

/** Determines where to redirect the user */
export const evaluateResponse = ({
  response,
  contentExperiences,
  disambiguationLink,
  delegationLink,
  returnCodeList,
}: EvaluateResponseParams): Status => {
  // if response Status is Success
  if (response?.ReturnCode === returnCode.SUCCESS) {
    const successResponse = response as Success;

    const isPendingDelegation = successResponse?.PendingDelegation;
    if (isPendingDelegation) {
      return { type: 'redirect', to: delegationLink, experienceName: 'delegation' };
    }

    const hasExperienceResponse = successResponse?.Experiences?.length > 0;
    const hasExperienceContent = contentExperiences?.length > 0;

    const matchesAnExperienceFromResponse = (experience: (typeof contentExperiences)[number]) =>
      successResponse.Experiences.some(
        responseExperience => experience?.fields?.ExperienceName?.value === responseExperience
      );

    if (hasExperienceResponse && hasExperienceContent) {
      const matchingExperiencesContent = contentExperiences.filter(matchesAnExperienceFromResponse);

      const noMatchingExperienceFound = !matchingExperiencesContent?.length;
      if (noMatchingExperienceFound) {
        // redirect to Disambiguation if experience not found
        Logger('No matching Experience', { componentName: 'Sign In' });
        return { type: 'redirect', to: disambiguationLink, experienceName: 'disambiguation' };
      }

      // create an array of the href values we need to test
      const matchingExperienceLinks = matchingExperiencesContent
        .map(exp => exp?.fields?.ButtonURL?.value?.href)
        .filter(Boolean);

      if (!matchingExperienceLinks) {
        Logger('No ButtonURL in Sitecore content', { componentName: 'Sign In' });
      }

      // We can use a Set to remove any duplicate entries
      const uniqueLinks = new Set(matchingExperienceLinks);

      // check if we only have one place to redirect to
      const hasSingleLink = uniqueLinks.size === 1;
      if (hasSingleLink) {
        const token = successResponse?.Token ? `&access_token=${successResponse.Token}` : '';
        const link = matchingExperienceLinks[0];

        return {
          type: 'redirect',
          to: `${link}${token}`,
          experienceName: matchingExperiencesContent[0].fields.ExperienceName.value,
        };
      }

      // if different, redirect to disembiguationUrl
      return {
        type: 'redirect',
        to: disambiguationLink,
        experienceName: 'disambiguation',
      };
    }

    // if no experiences in either Response or Content
    if (!hasExperienceResponse) {
      Logger('No Experiences returned from Facade', { componentName: 'Sign In' });
    }

    if (!hasExperienceContent) {
      Logger('No Experiences within Sitecore content', { componentName: 'Sign In' });
    }
    return { type: 'error', code: 'E' };
  }
  // if response from EmailVerification is Success
  if (response?.ReturnCode === returnCode.EMAIL_VERIFICATION_SUCCESS) {
    return { type: 'error', code: response.ReturnCode.toString() };
  }

  // if response Status is Return Code
  const responseReturnCode = response?.ReturnCode.toString();
  const returnCodeRedirect = returnCodeList.find(x => x.name === responseReturnCode);
  const redirectLink = returnCodeRedirect?.fields?.Redirect?.value?.href;

  if (redirectLink) {
    return {
      type: 'redirect',
      to: redirectLink,
      // TODO: ExperienceName for a Return Code Redirect?
      experienceName: '',
    };
  }

  return { type: 'error', code: responseReturnCode };
};

// Create a context to share an XSTATE machine within the component subtree
const SignInMachineContext = createContext({
  machine: {} as InterpreterFrom<typeof signInMachine>,
});

/**
 * ```tsx
 * const {state, send} = useSignInMachine();
 * ```
 */
export const useSignInMachine = () => {
  const context = useContext(SignInMachineContext);
  const [state] = useActor(context.machine);
  const { send } = context.machine;

  return { state, send };
};

const MachineInit = ({ onSuccess }: SignInProps) => {
  const { content, getError } = useSignInContent();
  const { search } = useLocation();
  const rcQuery = new URLSearchParams(search).get('rc');

  const {
    subheadline,
    analyticsVariant,
    delegationLink,
    disambiguationLink,
    endpoint,
    guid,
    returnCodes: returnCodeList,
  } = content;

  const contentExperiences = content.onlineExperiences;

  const machine = useInterpret(signInMachine, {
    guards: {
      isSuccess: context => {
        return context.successfulResponse?.type === 'redirect';
      },
      isInlineError: context => {
        const error = getError(context.errorCode);
        const hasInlineError = error?.Vehicle?.value === 'Inline';

        return hasInlineError;
      },
      hasReturnCodeQueryParam: context => {
        return Boolean(context.errorCode);
      },
      hasReturnCodeEndpoint: context => {
        const error = getError(context.errorCode);
        const hasEndpoint = error?.Endpoint?.fields?.Phrase?.value;

        return Boolean(hasEndpoint);
      },
    },
    context: {
      guid,
      errorCode: rcQuery || '',
    },
    services: {
      submitForm: async context => {
        const payload = {
          loginIdentity: context.userCredentials.loginIdentity,
          password: context.userCredentials.password,
        };

        // TODO refactor to allow us to pass through additonal fetch params...
        const data = await signIn({ url: endpoint, body: payload });

        const result = evaluateResponse({
          response: data,
          contentExperiences,
          delegationLink,
          disambiguationLink,
          returnCodeList,
        });

        // vpv analytics
        if (result.type === 'redirect') {
          track({
            event: 'send-VPV',
            'vpv-name': `/vpv/de/page/sign-in/clickthru/sign-in/${subheadline}/na/${analyticsVariant}/${result.experienceName
              .split('_')
              .join('-')
              .toLowerCase()}`,
          });
        }

        // if we have a derived error based on our business logic
        if (result.type === 'error') {
          const error = new Error(result.code);
          throw error;
        }

        return result;
      },
      submitEmailVerification: async (context, event) => {
        const { endpoint } = event.data;
        const payload = {
          emailAddress: context.userCredentials.loginIdentity,
        };

        const data = await verifyEmail({ url: endpoint, body: payload });

        // response for verifyEmail should always return a Return Code status which should be mapped to an Error state, in order to keep same flow I decided to pass the result to the evaluateResponse anyways.

        const result = evaluateResponse({
          response: data,
          contentExperiences,
          delegationLink,
          disambiguationLink,
          returnCodeList,
        });

        if (result.type === 'error') {
          const error = new Error(result.code);
          throw error;
        }

        return result;
      },
    },
    actions: {
      onSuccess,
    },
  });

  return (
    <SignInMachineContext.Provider value={{ machine }}>
      <PageSpacing>
        <SignInRenderer />
      </PageSpacing>
    </SignInMachineContext.Provider>
  );
};

// Do a redirect by default i.e. in Drawer, Split Sign-In Page
// ...but maybe do something else when used on StopService?
const defaultOnSuccess: OnSuccess = context => {
  if (context.successfulResponse?.type === 'redirect' && context.successfulResponse.to) {
    window.location.href = context.successfulResponse.to;
  }
};

type ReturnCodeList = HeaderContent['fields']['datasource']['Return Codes'];

// Returns a function that can look up a given return code
export const getErrorFn = (returnCodeList: ReturnCodeList) => (code: string) =>
  returnCodeList?.find(x => x.name === code)?.fields;

type OnSuccess = (context: Context) => void;

interface SignInProps {
  variant?: AutocompleteString<'Drawer' | 'Single Sign In' | 'Split Sign In'>;
  onSuccess?: OnSuccess;
  pageLevelConfig?: PageLevelConfig;
  secondaryButton?: JSX.Element;
  tertiaryButton?: JSX.Element;
}

// This resolver function is try/caught by createContent so it is a safe place to throw an error when trying to access one of these sitecore fields
const resolver = (props: {
  signInProps: HeaderContent;
  pageLevelConfig: PageLevelConfig;
  variant: string;
  secondaryButton?: JSX.Element;
  tertiaryButton?: JSX.Element;
}) => {
  const { variant } = props;

  let subheadline = 'na';
  if (variant === 'Drawer') {
    subheadline = 'sign-in-register';
  } else if (variant === 'Single Sign In') {
    subheadline = 'sign-in-to-your-account';
  }

  const analyticsVariant = variant.includes('Split') ? 'split' : toKebabCase(variant);

  const item = props.signInProps.fields.datasource;

  let guid = props.signInProps.dataSource;
  let endpoint = item.Endpoint.fields.Phrase.value;
  let returnCodes = item['Return Codes'];

  // Access these properties with conditional chaining as they are not required and may be passed thru as undefined
  const previousButtonLink = props.pageLevelConfig?.previousButtonLink;
  const previousButtonText = props.pageLevelConfig?.previousButtonText;
  const cancelButtonLink = props.pageLevelConfig?.cancelButtonLink;
  const cancelButtonText = props.pageLevelConfig?.cancelButtonText;
  const headline = props.pageLevelConfig?.headline;
  const disableOnMaintenance = props.pageLevelConfig?.disableOnMaintenance;
  const pageLevelEndpoint = props.pageLevelConfig?.endpoint;
  const pageLevelReturnCodes = props.pageLevelConfig?.returnCodes;
  const pageLevelGuid = props.pageLevelConfig?.guid;

  if (pageLevelEndpoint) {
    endpoint = pageLevelEndpoint;
  }

  if (pageLevelGuid) {
    guid = pageLevelGuid;
  }

  if (pageLevelReturnCodes?.length) {
    returnCodes = pageLevelReturnCodes;
  }

  const content = {
    headline,
    previousButtonLink,
    previousButtonText,
    cancelButtonLink,
    cancelButtonText,
    disableOnMaintenance,
    variant,
    analyticsVariant,
    /** analytics subheadline */
    subheadline,
    guid,
    forgotPasswordLink: props.signInProps.fields.forgotPasswordLink,
    forgotUserNameLink: props.signInProps.fields.forgotUserNameLink,
    registrationLink: props.signInProps.fields.registrationLink,
    maintenanceIsDisplayable: props.signInProps.fields.maintenanceIsDisplayable,
    maintenanceWindowFallsWithinTimeSpan:
      props.signInProps.fields.maintenanceWindowFallsWithinTimeSpan,
    loginDownMessages: props.signInProps.fields.datasource.LoginDownMessages.fields,
    showText: item['Show Text']?.value || 'Show',
    hideText: item['Hide Text']?.value || 'Hide',
    pageErrorMsgOnlineSvcUnav: item['Page Error Msg Online Svc Unav'].fields.Phrase.value,
    accountUnavailable: item['Account Unavailable'].value,
    pageErrorMsgIncorrectInfo: item['Page Error Msg Incorrect Info'].fields.Phrase.value,
    errorMessageUsernameLength: item['Error Message Username Length'].fields.Phrase.value,
    javascriptDisabled: item['JavaScript Disabled'].value,
    errorMessageUsernameValid: item['Error Message Username Valid'].fields.Phrase.value,
    exclamationMarkIcon: item['Exclamation Mark Icon'].value,
    cookiesDisabled: item['Cookies Disabled'].value,
    errorMessageUsername: item['Error Message Username'].fields.Phrase.value,
    pageErrorMsgMaintenance: item['Page Error Msg Maintenance'],
    errorMessagePassword: item['Error Message Password'].fields.Phrase.value,
    disambiguationLink: item['Disambiguation Link'].value.href,
    delegationLink: item['Delegation Link'].value.href,
    onlineExperiences: item['Online Experiences'],
    rememberUsername: item['Remember Username'].fields.Phrase.value,
    forgotPassword: item['Forgot Password'].fields.Phrase.value,
    signInTitle: item['Sign In Title'].value,
    signInCallToAction: item['Sign In CallToAction'].fields.Phrase.value,
    needHelpCallToAction: item['Need Help CallToAction'].fields.Phrase.value,
    forgotUsername: item['Forgot Username'].fields.Phrase.value,
    usernamePlaceholder: item['Username Placeholder'].fields.Phrase.value,
    passwordPlaceholder: item['Password Placeholder'].fields.Phrase.value,
    registerAccountCallToAction: item['Register Account CallToAction'].fields.Phrase.value,
    registerDescription: item['Register Description'].fields.Phrase.value,
    returnCodes,
    endpoint,
  };

  return {
    content,
    getError: getErrorFn(returnCodes),
    // optional buttons
    secondaryButton: props?.secondaryButton,
    tertiaryButton: props?.tertiaryButton,
  };
};

const { ContentProvider, useContent } = createContent(resolver);

export const useSignInContent = useContent;

const SignIn = ({ variant = 'Drawer', onSuccess = defaultOnSuccess, ...rest }: SignInProps) => {
  const { getComponent } = useSitecoreContext();
  const [signInProps] = getComponent('Sign In');

  return (
    <ContentProvider
      content={{
        signInProps,
        variant,
        ...rest,
      }}
    >
      <ToggleContainer>
        <MachineInit onSuccess={onSuccess} />
      </ToggleContainer>
    </ContentProvider>
  );
};

export default withErrorBoundary(withGlobalToggle(SignIn, 'signIn'));
