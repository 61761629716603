import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as JssLink } from '@sitecore-jss/sitecore-jss-react';
import { LinkSignature, LinkProps } from './types';

/** is it an internal link? */
const isInternal = (href: unknown): href is `/${string}` => {
  return typeof href === 'string' && href.startsWith('/');
};

/** Flatten 'value.href', 'value.text',  'value.title' and 'value.target' out of JssProps */
const transformProps = (arg: LinkProps) => {
  if ('href' in arg) {
    return arg;
  }

  const { value, ...rest } = arg;
  let { href } = value;
  const { querystring, text, title, target } = value;

  // Handle querystring data
  if (querystring && href && !href?.includes('?')) {
    const hasQuestion = querystring.startsWith('/?') || querystring.startsWith('?');

    if (hasQuestion) {
      href = href + querystring;
    } else {
      href = href + '?' + querystring;
    }
  }

  const children = rest.children || text || href;
  // Set value.title as the html title attr, but allow it to be overwritten by the impl
  return { target, title, ...rest, href, children };
};

// Ex:
// <Link {...testData.data} />
// <Link {...testData.editableData} />
// <Link href="/home">home</Link>
// <Link href="https://external.url">external</Link>

const Link: LinkSignature = props => {
  // If we are in experience editor, use JssLink
  if ('editableFirstPart' in props) {
    const { value, editable, editableFirstPart, editableLastPart, ...rest } = props;
    return <JssLink field={{ value, editable, editableFirstPart, editableLastPart }} {...rest} />;
  }

  const { href, children, ...rest } = transformProps(props);

  // falsey href is invalid html, do not render a link
  if (!href) {
    return null;
  }

  // internal link, use react-router link
  if (isInternal(href)) {
    return (
      <RouterLink to={href} {...rest}>
        {children}
      </RouterLink>
    );
  }

  // otherwise use regular anchor tag
  return (
    <a href={href} {...rest} className={`js-external ${rest.className ? rest.className : ''}`}>
      {children}
    </a>
  );
};

export { isInternal };
export default Link;
