import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSearch = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.112 30.813c0-12.009 9.845-21.78 21.95-21.78 12.104 0 21.954 9.771 21.954 21.78 0 12.01-9.85 21.778-21.953 21.778-12.106 0-21.95-9.769-21.95-21.778M76 68.548c0-1.088-19.806-19.65-19.806-19.65 3.726-5.083 5.932-11.33 5.932-18.085C62.126 13.821 48.188 0 31.063 0 13.933 0 0 13.821 0 30.813c0 16.99 13.932 30.813 31.063 30.813 6.49 0 12.522-1.987 17.513-5.374 0 0 18.685 19.748 19.912 19.748C69.715 76 76 69.636 76 68.548"
    />
  </svg>
);

export default SvgSearch;
