import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgOhio = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m8.635 65.716 15.57 3.409a.448.448 0 0 1 .074.03l3.544 1.98a.47.47 0 0 0 .161.05l7.752.857a.427.427 0 0 0 .219-.033l3.519-1.544c.087-.038.18-.038.25.02l6.008 4.439c.069.051.148.08.232.085l4.414.245h.022c.223 0 .41-.17.43-.395l.24-2.855a.274.274 0 0 1 .064-.162l2.48-2.483a.436.436 0 0 0 .123-.257L54.3 63.9c.008-.104.105-.2.217-.21l4.052-.527a.425.425 0 0 0 .367-.348l1.248-6.866c.016-.097.097-.173.208-.189l3.328-.535a.423.423 0 0 0 .312-.228l6.154-11.88c.03-.054.08-.1.135-.126l3.029-1.262a.424.424 0 0 0 .258-.456L67.6.366a.428.428 0 0 0-.424-.367h-3.165c-.08 0-.16.023-.228.067L49.141 9.212a.282.282 0 0 1-.219.035l-2.855-.536a.445.445 0 0 0-.276.042L40.855 11.3l-5.248 4.084a.331.331 0 0 1-.177.041l-3.82-.44a.248.248 0 0 1-.223-.2.248.248 0 0 1 .12-.274l2.989-1.903a.428.428 0 0 0-.137-.781l-6.22-1.38a.45.45 0 0 0-.143-.007L2.38 13.482a.424.424 0 0 0-.287.161.426.426 0 0 0-.09.317l6.3 51.392c.02.178.155.326.332.364"
    />
  </svg>
);

export default SvgOhio;
