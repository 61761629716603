import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgBatteryStorage = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="m28.424,12.669v1.115h-2.184c-.966,0-1.749.783-1.749,1.749h0v35.797c0,.966.783,1.749,1.749,1.75h11.52c.966,0,1.75-.783,1.75-1.75h0V15.53c-.002-.964-.783-1.745-1.747-1.746h-2.184v-1.115c0-.966-.783-1.749-1.749-1.749h-3.657c-.965.001-1.748.784-1.748,1.749Zm9.298,18.157l-7.908,10.78c-.118.16-.304.254-.502.254-.103,0-.204-.025-.294-.074-.274-.147-.397-.473-.288-.764l2.797-7.542h-4.929c-.343,0-.622-.278-.622-.621,0-.132.042-.261.12-.368l7.475-10.191c.202-.278.591-.339.869-.136.226.165.314.46.216.722l-2.6,6.953h5.155c.235.002.45.13.563.336.108.209.088.461-.051.651Z"
    />
  </svg>
);

export default SvgBatteryStorage;
