import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgRetiredPlantSite = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="m45.483,22.071c.042-.95-.692-1.755-1.642-1.8h-3.545c-.95.045-1.684.85-1.642,1.8l-.774,22.807c0,.933-.504.998-.504.998-.42-.065-.504-.597-.504-.597l-1.147-25.269c.042-.95-.692-1.755-1.642-1.8h-3.545c-.95.045-1.684.85-1.642,1.8l-1.772,24.896s-.065.653-.532.606-.485-.644-.485-.644l-.644-28.067c.042-.95-.692-1.755-1.642-1.8h-3.545c-.95.045-1.684.85-1.642,1.8l-1.931,30.138c0,.989,0,1.866,1.679,2.061h27.228c1.679-.177,1.679-1.073,1.679-2.061l-1.81-24.868Z"
    />
  </svg>
);

export default SvgRetiredPlantSite;
