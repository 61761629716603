import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPrinter = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m26.42 43.113 22.797-.042a1.618 1.618 0 0 1 .006 3.234l-22.798.043a1.617 1.617 0 1 1-.006-3.235Zm22.82 11.86-22.798.043a1.617 1.617 0 1 1-.006-3.235l22.798-.043a1.618 1.618 0 0 1 .006 3.235Zm.014 8.668-22.796.042a1.618 1.618 0 1 1-.005-3.234l22.794-.043a1.617 1.617 0 1 1 .007 3.235Zm-27.617 9.84a2.085 2.085 0 0 1-2.084-2.087l-.06-33.022 36.631-.067.063 33.02a2.085 2.085 0 0 1-2.074 2.097l-32.476.06ZM61.33 4.262v11.677H14.367V4.26A2.27 2.27 0 0 1 16.63 2h42.44a2.268 2.268 0 0 1 2.26 2.261Zm7.16 23.02a2.433 2.433 0 0 1-2.435-2.434 2.433 2.433 0 1 1 4.868 0 2.433 2.433 0 0 1-2.434 2.434Zm-8.138 0a2.434 2.434 0 1 1 0-4.868 2.434 2.434 0 0 1 0 4.868Zm11.318-8.453H4.03A4.041 4.041 0 0 0 0 22.858v25.184a4.041 4.041 0 0 0 4.03 4.03h12.46V36.605c0-1.044.855-1.898 1.899-1.898h39.245c1.044 0 1.899.854 1.899 1.898v15.467h12.14a4.04 4.04 0 0 0 4.027-4.03V22.858a4.04 4.04 0 0 0-4.028-4.03"
    />
  </svg>
);

export default SvgPrinter;
