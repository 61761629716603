import { CallToActionProps } from 'src/components/Common/@electron/CallToAction/types';

enum SORT {
  ASCEND = 'ascending',
  DESCEND = 'descending',
  NONE = 'none',
}

const FILTER = ['title', 'type', 'date'] as const;

interface ReducerState {
  filterBy: string;
  sliceBy: number | null;
  sortBy: {
    property: typeof FILTER[number];
    direction: SORT;
  };
  isExpanded: boolean;
}

type Reducer = (
  state: ReducerState,
  action:
    | {
        type: 'sortBy';
        payload: {
          property: typeof FILTER[number];
        };
      }
    | { type: 'filterBy'; payload: { filterBy: string } }
    | { type: 'toggle' }
) => ReducerState;

type TrackLabel = 'filter' | 'download' | 'expansion';

type TrackFunction = (arg: { label: TrackLabel; action: string }) => void;

type SetupAnalytics = ({ headline, guid }: { headline?: string; guid?: string }) => TrackFunction;

interface ResourceType {
  id: string;
  type: string;
  title: string;
  date: string;
  file: JSS.LinkField;
  track: () => void;
}

interface NewsAndResourcesProps extends CallToActionProps {
  resources: ResourceType[];
  resourceTypes: { value: string }[];
  track: TrackFunction;
}
interface ResourcesProps {
  resources: ResourceType[];
  filterBy: string;
  sliceBy?: number | null;
  sortBy: { property: typeof FILTER[number]; direction: SORT };
}

type TableHeaderProps = React.PropsWithChildren<{
  isActive: boolean;
  rotateArrow: boolean;
  onClick: () => void;
  width: string;
}> &
  React.HTMLAttributes<HTMLElement>;

interface ToggleButtonProps {
  isExpanded: boolean;
  onClick: () => void;
  hideBorder?: boolean;
}

type TransformFunction = (arr: ResourceType[]) => ResourceType[];

export type {
  NewsAndResourcesProps,
  Reducer,
  ReducerState,
  ResourceType,
  ResourcesProps,
  SetupAnalytics,
  TableHeaderProps,
  ToggleButtonProps,
  TrackFunction,
  TrackLabel,
  TransformFunction,
};

export { FILTER, SORT };
