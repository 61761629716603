import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgSouthCarolina = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M75.848 29.21 56.002 13.695a.409.409 0 0 0-.303-.079l-14.97 2.252a.215.215 0 0 1-.139-.019l-8.921-4.796a.321.321 0 0 0-.22-.047l-19.517 1.694a.375.375 0 0 0-.143.038l-9.38 4.648a.399.399 0 0 0-.176.173l-2.19 4.215a.4.4 0 0 0 .068.46l31.755 32.296.03.036 4.876 6.907 3.275 4.033a.39.39 0 0 0 .308.146c.021 0 .042-.002.062-.004l7.455-1.186a.394.394 0 0 0 .321-.301.393.393 0 0 0-.154-.414l-3.88-2.754a.226.226 0 0 1-.098-.235.231.231 0 0 1 .173-.192l5.686-1.627a.26.26 0 0 1 .156.004l1.52.654c.14.062.309.032.425-.071l8.873-8.105a.395.395 0 0 0 .122-.228l.918-5.472a.214.214 0 0 1 .141-.175.231.231 0 0 1 .235.032l2.547 1.992c.092.068.205.1.318.077a.405.405 0 0 0 .267-.188l3.36-5.635a.365.365 0 0 0 .052-.141l.65-4.19a.386.386 0 0 1 .065-.142l6.337-7.575a.397.397 0 0 0 .092-.293.41.41 0 0 0-.15-.271"
    />
  </svg>
);

export default SvgSouthCarolina;
