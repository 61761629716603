import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgHydroelectric = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M43.257,28.5c-.215-.396-.628-.644-1.079-.649h-9.902l4.992-13.349c.224-.623-.1-1.308-.722-1.532-.494-.178-1.047-.012-1.362,.408l-14.345,19.575c-.389,.535-.27,1.284,.265,1.673,.202,.147,.445,.227,.695,.229h9.463l-5.367,14.482c-.311,.586-.087,1.312,.498,1.623,.586,.311,1.312,.087,1.623-.498h0l15.141-20.709c.27-.364,.309-.85,.101-1.253Z"
    />
  </svg>
);

export default SvgHydroelectric;
