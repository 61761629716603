import { Text } from '@sitecore-jss/sitecore-jss-react';
import Button from 'src/components/Common/@electron/Button';
import SvgLoader from 'src/components/Common/SvgLoader';

/** Button displayed on small screens, shared between signed-in / not-signed in */
const SmallButton = ({
  text,
  ...rest
}: {
  onClick: () => void;
  text: JSS.TextField;
  href?: string;
}) => {
  return (
    <li>
      <Button
        aria-haspopup={true}
        className="js-sign-in-btn flex flex-col items-center justify-center -my-4 py-4 px-12 text-xs sm:text-sm text-teal-darker whitespace-nowrap"
        custom={true}
        data-testid={'mobile-auth-button'}
        {...rest}
      >
        <SvgLoader
          className="icon-20"
          name="Individual"
          color="text-current"
          aria-hidden="true"
          focusable="false"
        />
        <span className="block mt-2 -mb-4">
          <Text field={text} />
        </span>
      </Button>
    </li>
  );
};

export { SmallButton };
