import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgLeaf = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 48 48"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M29.0692 45.243C16.9627 33.9473 19.9815 21.5738 21.8812 16.6553C21.8812 16.6553 22.0762 16.1933 22.3125 16.296C22.5487 16.3988 22.4047 16.8405 22.4047 16.8405C21.2077 24.6045 22.7355 32.5432 26.7277 39.3082C26.7277 39.3082 40.3642 29.7075 34.6035 19.5105C26.5222 5.22675 31.5232 0 31.5232 0C-5.55679 24.6443 21.5115 39.0203 21.5115 39.0203C23.0025 41.8995 24.9015 44.5485 27.1492 46.8862C27.1492 46.8862 28.4947 48.5805 29.8395 47.79C29.901 47.8005 31.3897 47.3993 29.0692 45.243Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLeaf;
