import dayjs from 'dayjs';
import { linkIcons } from 'src/components/Common/@electron/RelatedLinks';
import Link from 'src/components/Common/Link';
import SvgLoader from 'src/components/Common/SvgLoader';
import { ResourceType, TableHeaderProps, ToggleButtonProps } from '../types';

const TableHeader = ({ children, isActive, rotateArrow, width, ...rest }: TableHeaderProps) => {
  return (
    <th className={`p-0 align-bottom ${width}`} {...rest}>
      <button className="group text-blue-dark p-16 font-bold capitalize w-full flex items-baseline hover:opacity-100 hover:bg-teal-lighter hover:bg-opacity-60">
        {children}
        <SvgLoader
          aria-hidden={true}
          focusable={false}
          size={14}
          color="text-teal-darker"
          className={`${
            isActive ? '' : 'opacity-0 group-hover:opacity-100'
          } ml-4 transition-opacity ease-in duration-150`}
          name="ArrowSort"
          rotate={isActive && rotateArrow ? 180 : 0}
        />
      </button>
    </th>
  );
};

const ResourceItem = ({ date, file, title, track, type }: ResourceType) => {
  const linkType = file.value?.linktype as string;
  const icon = linkIcons[linkType] || linkIcons.media;

  return (
    <tr data-testid="item" className="border-b border-gray-light">
      <td className="flex align-top p-16">
        <Link
          {...file}
          download={file.value?.linktype === 'media'}
          className="flex text-teal-darker no-underline hover:underline"
          onClick={() => track()}
        >
          <SvgLoader
            aria-hidden="true"
            focusable="false"
            name={icon}
            className="flex-shrink-0 mr-6 mt-2"
          />
          <span className="flex-grow">{`${title} (PDF)`}</span>
        </Link>
      </td>
      <td className="align-top p-16">{type}</td>
      <td className="align-top p-16">{dayjs(date).format('MM/DD/YYYY')}</td>
    </tr>
  );
};

const ToggleButton = ({ isExpanded, hideBorder, ...rest }: ToggleButtonProps) => {
  return (
    <button
      aria-expanded={isExpanded}
      className="relative text-teal-darker flex items-center mx-auto mt-24"
      data-testid="toggle"
      {...rest}
    >
      <span className={`${hideBorder ? '' : 'border-b'} mr-4`}>
        {isExpanded ? 'Show Less' : 'Show More'}
      </span>
      <SvgLoader name="Triangle" size={12} rotate={isExpanded ? 0 : 180} />
    </button>
  );
};

export { ResourceItem, TableHeader, ToggleButton };
