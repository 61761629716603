// Common layout/wrapper components used within the MAM features

/** Small width container */
export const SmallContainer = (props: React.PropsWithChildren<{}>) => {
  return <div className="w-full container-sm" {...props} />;
};

/** Medium width container */
export const MediumContainer = (props: React.PropsWithChildren<{}>) => {
  return <div className="w-full container-md" {...props} />;
};

/** Wraps the main layout content blocks on the page and controls the spacing (gap) between them.
 *  Establishes the spacing around itself and how the component sits on the page.
 */
export const PageSpacing = (
  props: React.PropsWithChildren<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
  >
) => {
  return (
    <section
      className="flex flex-col flex-grow px-16 sm:px-24 pb-36 md:pb-48 pt-18 md:pt-36 gap-32 md:gap-48"
      {...props}
    />
  );
};
