import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgExternalLink = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="m48.857 45.193 6.785-6.802v33.795a3.394 3.394 0 0 1-3.393 3.393H3.392A3.394 3.394 0 0 1 0 72.186V23.33a3.392 3.392 0 0 1 3.393-3.393h36.426l-6.77 6.786H6.786v42.071h42.072v-23.6Zm20.442-15.687a2.904 2.904 0 0 1-4.889 1.59L58.5 25.288 36.526 47.303a2.9 2.9 0 0 1-4.082.03l-3.355-3.165a2.919 2.919 0 0 1-.88-2.06 2.922 2.922 0 0 1 .848-2.073L50.98 18.068l-5.838-5.735a2.901 2.901 0 0 1 1.457-4.93L69.882 2.77A2.902 2.902 0 0 1 73.31 6.11L69.3 29.506Z"
    />
  </svg>
);

export default SvgExternalLink;
