import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgDesktop = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M56.14025,58.7781333 L56.14025,31.6714667 L72.8444167,31.6714667 L72.8444167,58.7781333 L56.14025,58.7781333 Z M64.4923333,64.5272167 C63.2668333,64.5272167 62.2725,63.5344667 62.2725,62.3073833 C62.2725,61.0818833 63.2668333,60.0891333 64.4923333,60.0891333 C65.7178333,60.0891333 66.7105833,61.0818833 66.7105833,62.3073833 C66.7105833,63.5344667 65.7178333,64.5272167 64.4923333,64.5272167 Z M53.3599167,31.3738 L53.3599167,48.4563833 L4.921,48.4563833 L4.921,12.9089667 L57.5905833,12.9089667 L57.5905833,29.0969667 L55.6351667,29.0969667 C54.378,29.0969667 53.3599167,30.1166333 53.3599167,31.3738 L53.3599167,31.3738 Z M73.3479167,29.0969667 C74.6050833,29.0969667 75.62475,30.1166333 75.62475,31.3738 L75.62475,64.6792167 C75.62475,65.9363833 74.6050833,66.95605 73.3479167,66.95605 L55.6351667,66.95605 C54.378,66.95605 53.3599167,65.9363833 53.3599167,64.6792167 L53.3599167,53.3599667 L4.01058333,53.3599667 C1.7955,53.3599667 -4.76063633e-13,51.56605 -4.76063633e-13,49.3509667 L0.00316666667,12.0112167 C0.00316666667,9.79613333 1.79866667,8.00063333 4.01375,8.00063333 L58.5073333,8.00063333 C60.7224167,8.00063333 62.5179167,9.79613333 62.5179167,12.0112167 L62.5163333,29.0969667 L73.3479167,29.0969667 Z M43.5905917,61.5325 C46.2442583,61.5325 48.389675,63.708 48.389675,66.3553333 C48.389675,66.3901667 14.1295083,66.3901667 14.1295083,66.3553333 C14.1295083,63.708 16.274925,61.5325 18.930175,61.5325 L22.9613417,61.5325 L22.9613417,56.4246667 L39.5610083,56.4246667 L39.5610083,61.5325 L43.5905917,61.5325 Z"
    />
  </svg>
);

export default SvgDesktop;
