import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPadlock = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.967 20.381c0-2.978.993-12.682 13.638-12.682 12.644 0 13.637 9.722 13.637 12.682v13.88H23.967v-13.88Zm38.494 13.88h-3.522v-13.88C58.94 12.888 54.445.021 37.622 0c-16.86 0-21.316 12.813-21.316 20.381v13.88h-3.56A3.747 3.747 0 0 0 9 38.009v34.245A3.747 3.747 0 0 0 12.747 76H62.46a3.745 3.745 0 0 0 3.746-3.747v-34.15a3.745 3.745 0 0 0-3.65-3.84l-.096-.001Z"
    />
  </svg>
);

export default SvgPadlock;
