import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgInfo = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    aria-hidden="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M38 0c21 0 38 17 38 38S59 76 38 76 0 59 0 38 17 0 38 0zm0 3C18.7 3 3 18.7 3 38s15.7 35 35 35 35-15.7 35-35S57.3 3 38 3zm2 27c2.6 0 4.7 2.1 4.7 4.7v23.7c0 2.6-2.1 4.7-4.7 4.7h-2.7c-2.6 0-4.7-2.1-4.7-4.7V34.7c0-2.6 2.1-4.7 4.7-4.7H40zm-1.3-16c3.3 0 6 2.7 6 6v.1c0 3.3-2.7 6-6 6s-6-2.7-6-6V20c0-3.3 2.7-6 6-6z"
    />
  </svg>
);

export default SvgInfo;
